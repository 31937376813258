import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary"
import { ErrorBoundary_index } from "./Components/ErrorBoundary/Settings/ErrorBoundary_Index"
import "./GlobalStyles/Global.css"
const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <ErrorBoundary errorMessage={ErrorBoundary_index.errorMessage}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
