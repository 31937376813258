import axios from "../../axios";
export async function GetSchoolSubjects({
  currentPage = 1,
  itemsPerPage = 10,
}) {
  const url = "SchoolSubject/api/v1/GetAllSchoolSubjectsPaged"
  try {
    const response = await axios.get(url, {
      params: {
        CurrentPage: currentPage,
        ItemsPerPage: itemsPerPage,
      },
    });
    return response.data;
  } catch (error) {}
}
