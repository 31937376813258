import { css } from "aphrodite";
import React, { useContext, useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import { IoIosAlert, IoIosEye, IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import logo from "../../Assets/Logo.svg";
import { animationStyles } from "../../Components/Animations/Styles";
import InfoPopup from "../../Components/InfoPopup/InfoPopup";
import { RESPONSIVE_MODAL_STYLES } from "../../Components/Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import { Context } from "../../Context/Contexts";
import "./SignUpStep2.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./SignUpStep2_ModalStyles";
import { CreateNewClient } from "./signUpUser";
import HashingComponent from "../../Components/HashingComponent/HashingComponent ";
export default function SignUpStep2({ open, onClose, prevStep, nextStep }) {
  const { setShowIsloading } = useContext(Context);
	const [isMounted, setIsMounted] = useState(false);
	useEffect(() => {
		setIsMounted(true);
	}, []);

	const navigate = useNavigate();
	const lang = sessionStorage.getItem("_lang");
	const { newUser, setNewUser } = useContext(Context);
	const [pwd, setPwd] = useState("");
	const [pwdConfirmation, setPwdConfirmation] = useState("");
	const [isPwdError, setIsPwdError] = useState(false);
	const [pwdTooShort, setPwdTooShort] = useState(false);
	const [promptMessage, setPromptMessage] = useState("");
	const [promptMessageIsOpen, setPromptMessageIsOpen] = useState(false);
	const [completedPromptIsOpen, setCompletedPromptIsOpen] = useState(false);
	const [showPwd, setShowPwd] = useState(false);

	const CancelRegistration = () => {
		newUser.Name = "";
		newUser.LastName = "";
		newUser.Email = "";

		handlePwdChange("");
		handlePwdConfirmationChange("");
		onClose();
	};

	const handlePwdChange = (e) => {
		setPwd(e);
	};
	const handlePwdConfirmationChange = (e) => {
		setPwdConfirmation(e);
	};

	const PrevStep = () => {
		prevStep();
		onClose();
	};
	const Continue = async () => {
		if (isPwdError || pwdTooShort || pwd == "" || pwdConfirmation == "") {
			return;
		}

		setShowIsloading(true);

		var hashedPwd = await HashingComponent(pwd);

		CreateNewClient(
			newUser.Email,
			newUser.Name,
			newUser.LastName,
			hashedPwd,
			lang
		).then((response) => {
			setShowIsloading(false);
			setPromptMessage(GetPrompts("Sign up sent Text"));
			setCompletedPromptIsOpen(true);
		});
	};

	const GoToHome = () => {
		setCompletedPromptIsOpen(false);
		setPromptMessageIsOpen(false);
		navigate("/Landing");
	};

	useEffect(() => {
		if (pwd != "" && pwdConfirmation != "" && pwd != pwdConfirmation) {
			setIsPwdError(true);
			return;
		}
		setIsPwdError(false);
	}, [pwd, pwdConfirmation]);

	useEffect(() => {
		if (pwd != "" && pwd.length < 8) {
			setPwdTooShort(true);
			return;
		}
		setPwdTooShort(false);
	}, [pwd]);

	if (!open) return null;
	return (
		<>
			<div style={OVERLAY_STYLES} onClick={CancelRegistration}></div>

			<div
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
				className="sign-up-step2-container"
			>
				<div
					style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
					className="background-picture"
				></div>
				<div className="sign-up-step2-close-btn-container">
					<button
						className="sign-up-step2-close-btn"
						onClick={CancelRegistration}
					>
						X
					</button>
				</div>
				<span
					className={
						css(isMounted ? animationStyles.bounceInRight : null) +
						" modal-span"
					}
				>
					<div className="sign-up-step2-logo">
						<a className="brand-logo">
							<img src={logo} className="sign-up-step2-brand-logo" alt="logo" />
						</a>
					</div>
					<div className="sign-up-step2-text">
						{GetPrompts("SignUp Title Text")}{" "}
						{GetPrompts("SignUp Pararaph Text")}
					</div>
					<div
						className="sign-up-step2-back"
						onClick={() => {
							PrevStep();
						}}
					>
						<IoMdArrowBack />
					</div>
					<div className="sign-up-step2-title-text">
						{GetPrompts("Set up your password")}
					</div>
				</span>

				<span
					className={
						css(isMounted ? animationStyles.bounceInUp : null) + " modal-span"
					}
				>
					<div className="sign-up-step2-form-item">
						<span className="sign-up-step2-label">
							{GetPrompts("Login Pwd tag")}{" "}
							<a
								className="show-pwd-btn-black"
								onClick={() => {
									setShowPwd(!showPwd);
								}}
							>
								<IoIosEye />
							</a>
							{pwdTooShort ? (
								<IoIosAlert
									className="error-Item-pink"
									data-tip
									data-for="pwd-input"
								/>
							) : (
								""
							)}
						</span>
						<input
							id="sign-up-step2-pwd"
							type={showPwd ? "text" : "password"}
							className="sign-up-step2-input"
							onChange={(e) => handlePwdChange(e.target.value)}
						/>
					</div>
					<div className="sign-up-step2-form-item">
						<span className="sign-up-step2-label">
							{GetPrompts("SignUp Confirm Pwd Text")}{" "}
							{isPwdError ? (
								<IoIosAlert
									className="error-Item-pink"
									data-tip
									data-for="pwd-confirm-input"
								/>
							) : (
								""
							)}
						</span>
						<input
							id="sign-up-step2-confirm-pwd"
							type={showPwd ? "text" : "password"}
							className="sign-up-step2-input"
							onChange={(e) => handlePwdConfirmationChange(e.target.value)}
						/>
					</div>

					<div className="sign-up-step2-already-has-account-container">
						<span className="sign-up-step2-already-has-account">
							{GetPrompts("Already have an account")}
						</span>{" "}
						<span
							className="sign-up-step2-login"
							onClick={() => {
								onClose();
							}}
						>
							{GetPrompts("Login enter tag")}
						</span>
					</div>

					<div
						id="sign-up-step2-continue-btn"
						className="sign-up-step2-continue-container"
						onClick={async () => {
							await Continue();
						}}
					>
						<div className="sign-up-step2-continue">
							<p className="sign-up-step2-continue-text">
								{GetPrompts("Home page Register")}
							</p>
						</div>
					</div>
				</span>

				<div className="tooltips-container">
					{isPwdError && (
						<ReactTooltip id="pwd-confirm-input" place="top" effect="solid">
							{GetPrompts("Pwd confirmation must match Text")}
						</ReactTooltip>
					)}

					{pwdTooShort && (
						<ReactTooltip id="pwd-input" place="top" effect="solid">
							{GetPrompts("Pwd too short Text")}
						</ReactTooltip>
					)}
				</div>
			</div>

			<InfoPopup
				icon={<IoIcons.IoMdCheckmarkCircle />}
				open={completedPromptIsOpen}
				onClose={() => {
          			onClose();
					GoToHome();
				}}
				errorMessage={promptMessage}
			/>
		</>
	);
}
