import axios from "../../axios";
export async function DeleteCartItem({ cartItemId, userId }) {
  const url = "Cart/api/v1/DeleteCartItem"
  try {
    const response = await axios.post(url, {
      UserId: userId,
      CartItemId: cartItemId,
    });
    return response.data.result;
  } catch (error) {}
}
