import React, { useState } from "react";
import "./HelpItemCard.css";

export default function HelpItemCard({ icon, cardText, helpComponent }) {
  const [showItem, setShowItem] = useState(false);
  return (
    <>
      <div className="help-item-card" onClick={() => setShowItem(!showItem)}>
        <div className="help-item-card-icon">{icon}</div>
        <div className="help-item-card-text">{cardText}</div>
        <div className="help-render-container">
          {showItem ? (
            <>
              <div
                className="help-item-card-hide"
                onClick={() => setShowItem(false)}
              >
                Ocultar
              </div>
              <div>{helpComponent}</div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
