import React, { useContext } from "react";
import * as IoIcons from "react-icons/io";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import { Context } from "../../Context/Contexts";
import "./UserProfile.css";
import UserProfile_Alt from "./UserProfile_Modal";

export function UserProfileIcon({ isOverallMenu = false }) {
	const { userProfileIsOpen, setUserProfileIsOpen } = useContext(Context);
	return (
		<>
			<div
				className="user-profile-icon"
				onClick={() => {
					setUserProfileIsOpen(true);
				}}
			>
				<IoIcons.IoMdSettings />
				<span
					className={`sidebar-item-text ${
						isOverallMenu ? "overall-sidebar-menu-item" : "sidebar-menu-item"
					}`}
				>
					{GetPrompts("Sidebar My Profile")}
				</span>
			</div>
		</>
	);
}

export function UserProfileIcon_Home() {
	const { userProfileIsOpen, setUserProfileIsOpen } = useContext(Context);
	return (
		<>
			<div
				className="user-profile-home-icon-container"
				onClick={() => {
					setUserProfileIsOpen(true);
				}}
			>
				<div className="user-profile-home-icon-container-icon">
					{" "}
					<IoIcons.IoMdPerson />
				</div>
				<div className="user-profile-home-icon-container-text sidebar-menu-item">
					{" "}
					{GetPrompts("Sidebar My Profile")}
				</div>
			</div>
		</>
	);
}

export function UserProfileModal() {
	return <UserProfile_Alt />;
}
