import React from "react";
import * as IoIcons from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { GetPrompts } from "../Prompts/PromptsComponent";
import "./LogOutLink.css";
export default function LogOutLink({ isOverallMenu = false }) {
	const navigate = useNavigate();

	const ClickHandler = () => {
		sessionStorage.removeItem("_x");
		sessionStorage.removeItem("_c");
		navigate("/landing");
	};
	return (
		<>
			<div
				className="log-out-link"
				onClick={() => {
					ClickHandler();
				}}
			>
				<div>
					<span className="log-out-link-icon">
						<IoIcons.IoMdExit />
					</span>
					<span
						className={`log-out-link-text ${
							isOverallMenu ? "overall-sidebar-menu-item" : "sidebar-menu-item"
						}`}
					>
						{" "}
						{GetPrompts("Sidebar Exit")}
					</span>
				</div>
			</div>
		</>
	);
}

export function LogOutLink_Home() {
	const navigate = useNavigate();

	const ClickHandler = () => {
		sessionStorage.removeItem("_x");
		sessionStorage.removeItem("_c");
		navigate("/landing");
	};
	return (
		<>
			<div
				className="log-out-link-home-container"
				onClick={() => {
					ClickHandler();
				}}
			>
				<div className="log-out-link-home-icon">
					<IoIcons.IoMdExit />
				</div>
				<div className="log-out-link-home-text sidebar-menu-item">
					{GetPrompts("Sidebar Exit")}
				</div>
			</div>
		</>
	);
}
