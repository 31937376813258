export default function SchoolSubjectCard({
  schoolSubject,
  setSchoolSubjectInEdition,
  setIsOpen,
}) {
  const { schoolSubjectId, description } = schoolSubject;

  const EditionClickHandler = () => {
    setSchoolSubjectInEdition(schoolSubject);
    setIsOpen(true);
  };

  return (
    <article key={schoolSubjectId}>
      <div className="schoolSubject-card">
        <div className="schoolSubject-title">{description}</div>
        <div className="schoolSubject-edit-btn" onClick={EditionClickHandler}>
          Editar
        </div>
      </div>
    </article>
  );
}
