import { css } from "aphrodite";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HomePicture from "../../Assets/HomePicture.png";
import { animationStyles } from "../../Components/Animations/Styles";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import SidebarHome from "../../Components/SidebarHome/SidebarHome";
import { SPINNER_STYLES_ORANGE } from "../../Components/Spinner/SpinnerStyles";
import { Context } from "../../Context/Contexts";
import SignUpStep1 from "../SignUp/SignUpStep1";
import SignUpStep2 from "../SignUp/SignUpStep2";
import "./Landing_Mobile.css";

export default function Landing_Mobile() {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { prompts } = useContext(Context);
  const [textLoaded, setTextLoaded] = useState(false);
  const [openStep1, setOpenStep1] = useState(false);
  const [openStep2, setOpenStep2] = useState(false);
  const navigate = useNavigate();
  const STYLES = {
    height: window.innerHeight,
  };

  useEffect(() => {
    if (prompts.length > 0) {
      setTextLoaded(true);
    }
  }, [prompts]);

  if (textLoaded === false) {
    return
  }
  if (isMounted === false) {
    return
  }

  return (
    <>
      <SidebarHome />
      {textLoaded ? (
        <>
          <div className="landing-mobile-container" style={STYLES}>
            <div className="landing-mobile-background-picture">
              <img
                id="home-pictures-landing"
                src={HomePicture}
                alt="{home-pictures-landing}"
              />
            </div>

            <div
              className={
                css(isMounted ? animationStyles.bounceInDown : null) +
                " landing-mobile-title-text"
              }
            >
              <p className="landing-mobile-text-title">
                {GetPrompts("Home page title text")}
              </p>
              <p className="landing-mobile-text-paragraph">
                {GetPrompts("Home page paragraph text")}
              </p>
            </div>
            <div
              className={
                css(isMounted ? animationStyles.bounceInUp : null) +
                " landing-mobile-toggle-container"
              }
            >
              <div className="landing-mobile-toggle-container-background">
                <div
                  className="landing-mobile-toggle-register"
                  onClick={() => {
                    setOpenStep1(true);
                  }}
                >
                  {GetPrompts("Home Sidebar Register")}
                </div>
                <div
                  className="landing-mobile-toggle-login"
                  onClick={() => {
                    navigate("/login_mobile");
                  }}
                >
                  {GetPrompts("Login enter tag")}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <SignUpStep1
        open={openStep1}
        onClose={() => setOpenStep1(false)}
        prevStep={() => {
          return;
        }}
        nextStep={() => setOpenStep2(true)}
      />
      <SignUpStep2
        open={openStep2}
        prevStep={() => setOpenStep1(true)}
        onClose={() => setOpenStep2(false)}
        nextStep={() => {
          return;
        }}
      />

      {!textLoaded ? (
        <div style={SPINNER_STYLES_ORANGE}>
          <Spinner name="line-scale-pulse-out-rapid" />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
