import React from 'react'
import { animationStyles } from '../../Components/Animations/Styles'
import Calendar from 'react-calendar'
import { useState } from 'react'
import { css } from 'aphrodite'
import { useEffect } from 'react'
import { GetMentorshipCalendarAvailableSlots } from './GetMentorshipCalendarAvailableSlots'
import moment from 'moment'
import './MentorshipCalendar.css'
import { useRef } from 'react'
import { GetStyleToAdjustHeight_Alt } from '../../Components/Helpers/GetStyleToAdjustHeight'
import { useContext } from 'react'
import { Context } from '../../Context/Contexts'
import { Spinner } from 'react-bootstrap'
import { SPINNER_STYLES_NOTEXT } from '../../Components/Spinner/SpinnerStyles'

export default function MentorshipCalendar({
  setConfirmation1,
  setSelectedSlotId,
}) {
  var loadCount = 0
  var lasDayProcessed
  const [isMounted, setIsMounted] = useState(false)
  const [slotsList, setSlotsList] = useState([])
  const { setUpdateHeight } = useContext(Context)
  const { updateMentorshipAvailableSlots, setUpdateMentorshipAvailableSlots } =
    useContext(Context)

  const [dateToConsult, setDateToConsult] = useState(new Date())

  const DateChangeHandler = (e) => {
    GetSlots(e)
    setDateToConsult(e)
  }
  const GetSlots = (e) => {

    GetMentorshipCalendarAvailableSlots({ selectedDate: e }).then(
      (response) => {
        setSlotsList(response)
        setUpdateHeight(true)
      }
    )
  }
  const boxRef = useRef()
  const { STYLES } = GetStyleToAdjustHeight_Alt(boxRef)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (updateMentorshipAvailableSlots == true) {
      setUpdateMentorshipAvailableSlots(false)
      GetSlots(dateToConsult)
    }
  }, [updateMentorshipAvailableSlots])

  useEffect(() => {
    if (loadCount > 0) {
      return
    }
    InitialLoad()
  }, [loadCount])

  const InitialLoad = () => {
    GetSlots(new Date())
    loadCount = loadCount + 1
  }

  return (
    <>
      <div className="mentorship-calendar-container">
        <div className="mentorship-calendar">
          <div
            className={
              css(isMounted ? animationStyles.bounceInDown : null) +
              " planner-home-calendar"
            }
          >
            <Calendar
              defaultView={"month"}
              onChange={(e) => DateChangeHandler(e)}
              value={dateToConsult}
            />
          </div>
        </div>

        <div className="mentorship-calendar-available-title">
          {" "}
          <span className="base-title">Mentorias disponibles</span>
        </div>
        <div
          className="mentorship-calendar-available-container"
          ref={boxRef}
          style={STYLES}
        >
          {slotsList
            ? slotsList.map((slot) => {
                var isSameDay = false
                var currentSlotDay = moment(slot.availableFrom).format(
                  "DD-MMM-y"
                )

                if (currentSlotDay == lasDayProcessed) {
                  isSameDay = true
                }
                lasDayProcessed = currentSlotDay

                return (
                  <>
                    {!isSameDay ? <br /> : ""}
                    <div className="mentorship-calendar-available-card-container">
                      <div className="mentorship-calendar-available-card-description-container">
                        <div
                          className={
                            !isSameDay
                              ? "mentorship-calendar-available-card-description-day calendar-custom-day"
                              : " mentorship-calendar-available-card-description-day-transparent calendar-custom-day"
                          }
                        >
                          {moment(slot.availableFrom).format("DD")}
                        </div>
                        <span className="mentorship-calendar-available-card-description-line"></span>
                        <div className="mentorship-calendar-available-card-description-month calendar-custom-month">
                          {moment(slot.availableFrom).format("MMM")}
                        </div>
                        <div className="mentorship-calendar-available-card-description-hour calendar-custom-time">
                          {moment(slot.availableFrom).format("hh:00 a")}
                        </div>
                      </div>
                      <div
                        className="mentorship-calendar-available-card-book-btn"
                        onClick={() => {
                          setSelectedSlotId(slot.availableSlotId)
                          setConfirmation1(true)
                        }}
                      >
                        Seleccionar
                      </div>
                    </div>
                  </>
                )
              })
            : ""}
        </div>
      </div>
    </>
  )
}
