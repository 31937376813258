import axios from "../../axios";
export const UpdateSchoolSubject = async (
  userId,
  schoolSubjectId,
  schoolSubjectDescription
) => {
  try {
    const url = `SchoolSubject/api/v1/UpdateSchoolSubject`

    const response = await axios.post(url, {
      UserId: userId,
      SchoolSubjectId: schoolSubjectId,
      Description: schoolSubjectDescription,
    });
  } catch (error) {}
};
