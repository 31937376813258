import { useContext, useEffect, useState } from "react";
import { Context } from "../../Context/Contexts";

export function GetStyleToAdjustHeight(boxRef) {
  const {
    updateProductsHeight: updateHeight,
    setUpdateProductsHeight: setUpdateHeight,
  } = useContext(Context);

  const [x, setX] = useState();
  const [y, setY] = useState();

  const getPosition = () => {
    const x = boxRef.current.offsetLeft;
    setX(x);

    const y = boxRef.current.offsetTop;

    if (window.innerWidth <= 622.2) {
      setY(window.height);
    } else {
      setY(y);
    }

    window.removeEventListener("resize", getPosition);
  };

  const STYLES = {
    height: window.innerHeight - y + "px",
  };

  useEffect(() => {
    getPosition();
  }, []);
  useEffect(() => {
    if (updateHeight == true) {
      setUpdateHeight(false);
      getPosition();
    }
  }, [updateHeight]);

  return { getPosition, STYLES };
}

export function GetStyleToAdjustHeight_Alt(boxRef) {
  var navBarHeight = 61
  const { updateHeight, setUpdateHeight } = useContext(Context)
  const [x, setX] = useState()
  const [y, setY] = useState()

  const getPosition = () => {
    const x = boxRef.current.offsetLeft
    setX(x)

    const y = boxRef.current.offsetTop

    if (window.innerWidth <= 622.2) {
      setY(window.height)
    } else {
      setY(y)
    }
  }

  const STYLES = {
    height: window.innerHeight - y + "px",
  }

  useEffect(() => {
    if (updateHeight == true) {
      setUpdateHeight(false);
      getPosition();
    }
  }, [updateHeight]);
  return { getPosition, STYLES };
}
