import React, { useContext, useRef, useState } from 'react'
import Sidebar from '../../Components/Sidebar/Sidebar'
import { MentorshipSideBar } from './Sidebar/MentorshipSideBar'
import './Mentorship.css'
import { GetStyleToAdjustHeight } from '../../Components/Helpers/GetStyleToAdjustHeight'
import MentorshipCard from './MentorshipCard'
import MentorShipAppointments from './MentorShipAppointments'
import MentorshipCalendar from './MentorshipCalendar'
import YesNoConfirm from '../../Components/ModalConfirm/YesNoConfirm'
import { BookSlot } from './BookSlot'
import { Context } from '../../Context/Contexts'
import { Spinner } from 'react-bootstrap'
import {
  SPINNER_STYLES,
  SPINNER_STYLES_NOTEXT,
  SPINNER_STYLES_ORANGE,
} from '../../Components/Spinner/SpinnerStyles'
import { GetPrompts } from '../../Components/Prompts/PromptsComponent'

export default function Mentorship() {
  const boxRef = useRef()
  const { getPosition, STYLES } = GetStyleToAdjustHeight(boxRef)
  const [selectedSlotId, setSelectedSlotId] = useState(0)
  const [confirmation1, setConfirmation1] = useState(false)
  const [confirmation2, setConfirmation2] = useState(false)
  const [isloading, setIsloading] = useState(false)
  const { setShowIsloading } = useContext(Context)
  const {
    setUpdateMentorshipAvailableSlots,
    setUpdateMentorshipAppointmentsList,
  } = useContext(Context)

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  return (
    <>
      <Sidebar />
      <div className="mentorship-container">
        <div className="mentorship-left-container" ref={boxRef} style={STYLES}>
          <MentorshipSideBar />
        </div>

        <div className="mentorship-middle-container">
          <div className="mentorship-middle-header">
            <MentorshipCard
              pictureId={1}
              titleText={"Basic mentorship title"}
              descriptionText={"Basic mentorship description"}
            />

            <MentorshipCard
              pictureId={2}
              titleText={"Follow up mentorship title"}
              descriptionText={"Follow up mentorship description"}
            />
          </div>

          <div className="mentorship-middle-bottom">
            <MentorShipAppointments />
          </div>
        </div>

        <div className="mentorship-right-container">
          <MentorshipCalendar
            setConfirmation1={setConfirmation1}
            setSelectedSlotId={setSelectedSlotId}
          />
        </div>
      </div>

      <YesNoConfirm
        dlgOpen={confirmation1}
        callBackFunction={() => setConfirmation2(true)}
        onClose={() => setConfirmation1(false)}
        confirmMessage={
          "Luego de apartar la mentoria. Te contactaremos por correo para acordar el pago"
        }
      />
      <YesNoConfirm
        dlgOpen={confirmation2}
        callBackFunction={() => {
          setConfirmation1(false)
          setConfirmation2(false)
          BookSlot(selectedSlotId, "Mentoria de seguimiento")
          setShowIsloading(true)
          setTimeout(() => {
            setUpdateMentorshipAvailableSlots(true)
            setUpdateMentorshipAppointmentsList(true)
            setShowIsloading(false)
          }, 2000)
        }}
        onClose={() => {
          setConfirmation2(false)
          setConfirmation1(false)
        }}
        confirmMessage={"Seguro que quieres apartar esta mentoria?"}
      />

      {isloading ? (
        <div style={SPINNER_STYLES} className="loading-label">
          <div>{GetPrompts("Login we are confirming")}</div>
          <Spinner />
        </div>
      ) : (
        ""
      )}
    </>
  )
}
