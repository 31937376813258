import moment from "moment";
import React from "react";
import * as IoIcons from "react-icons/io";
import "./YearActivityNoteCard.css";

export default function YearActivityNotesCard({ note, DeleteNote, EditNote }) {
  return (
    <>
      <div className="year-activity-notes-card-ul">
        <li>
          <a href="#" contenteditable>
            <p className="year-activity-notes-card-title">
              {moment(note.lastModification).format("DD-MMM-YY")}
            </p>
            <p className="year-activity-notes-card-content">{note.note}</p>

            <div className="year-activity-notes-card-btn-container">
              <div>
                <IoIcons.IoMdTrash
                  className="year-activity-notes-card-trash"
                  onClick={() => {
                    DeleteNote(note);
                  }}
                />
              </div>
              <div
                className="year-activity-notes-card-edit"
                onClick={() => {
                  EditNote(note);
                }}
              >
                Editar
              </div>
            </div>
          </a>
        </li>
      </div>
    </>
  );
}
