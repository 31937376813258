import Moment from "moment";
import DataTable from "react-data-table-component";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import "./GetChildrenDatatable.css";
export function GetChildrenDatatable({
  childrenList,
  setChildInEdition,
  setIsOpen,
}) {
  const columns = [
    {
      name: (
        <>
          <div className="datatable-column-header">
            {GetPrompts('Name text')}
          </div>
        </>
      ),
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: (
        <>
          <div className="datatable-column-header">
            {GetPrompts('Last Name text')}
          </div>
        </>
      ),
      selector: (row) => row.lastName,
      sortable: true,
      width: '200px',
    },
    {
      name: (
        <>
          <div className="datatable-column-header">{GetPrompts('Id Text')}</div>
        </>
      ),
      selector: (row) => row.identification,
      sortable: true,
    },
    {
      name: (
        <>
          <div className="datatable-column-header">
            {GetPrompts('Date Of Birth Text')}
          </div>
        </>
      ),
      selector: (row) => Moment(row.dateOfBirth).format('DD/MM/YYYY'),
      sortable: true,
    },
    {
      name: '',
      cell: (row) => (
        <ActionComponent row={row} onClick={ActionComponent}>
          Action
        </ActionComponent>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  const customStyles = {
    rows: {
      style: {
        borderRadius: "20px",
      },
    },
    headCells: {
      style: {
        fontSize: "20px",
        // backgroundColor: "#ffc04d",
      },
    },
    cells: {
      style: {
        fontSize: "18px",
      },
    },
  };

  const ActionComponent = ({ row, onClick }) => {
    const clickHandler = () => {
      onClick(row);
      setChildInEdition(row);
      setIsOpen(true);
    };

    return (
      <div className="datatable-edit-btn" onClick={clickHandler}>
        {GetPrompts("Edit text")}
      </div>
    );
  };

  {
    return (
      <>
        <div className="container datatable">
          <DataTable
            columns={columns}
            data={childrenList}
            fixedHeader
            fixedHeaderScrollHeight="500px"
            customStyles={customStyles}
            responsive={true}
          />
        </div>
      </>
    );
  }
}
