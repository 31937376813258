import { useContext, useEffect, useState } from "react";
import { IoIosAlert, IoIosEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Spinner from "react-spinkit";
import ErrorPopUp from "../../Components/Errors/ErrorPopUp";
import { RESPONSIVE_MODAL_STYLES } from "../../Components/Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import { SPINNER_STYLES } from "../../Components/Spinner/SpinnerStyles";
import { Context } from "../../Context/Contexts";
import axios from "../../axios";
import "./Login.css";
const MODAL_STYLES = {
  position: "fixed",
  borderRadius: "30px",
  width: "460px",
  height: "500px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  padding: "50px",
  zIndex: 1000,
};
const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, .7)",
  zIndex: 1000,
};

export default function Login({ open, onClose }) {
  const navigate = useNavigate();
  const { prompts } = useContext(Context);
  const { userSettings } = useContext(Context);
  const { showForgotPwd, setShowForgotPwd } = useContext(Context);

  const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showAuthError, setShowAuthError] = useState(false);
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [isloading, setIsloading] = useState(false);
  const { setShowIsloading } = useContext(Context)
  const [showPwd, setShowPwd] = useState(false)

  const url = "UserAuth/api/v1/Authenticate"
  const authenticate = async (e) => {
    try {
      e.preventDefault()

      setShowIsloading(true)

      try {
        sessionStorage.removeItem("_x")
        const response = await axios.post(url, {
          Username: username,
          Password: pwd,
        })

        setShowIsloading(false)

        sessionStorage.setItem("_x", JSON.stringify(response.data.token))
        sessionStorage.setItem("_c", JSON.stringify(response.data.refreshToken))
        sessionStorage.setItem("_lang", response.data.language)
        if (response.status == "200") {
          setShowAuthError(false)
          onClose()
          navigate("/home")
        } else {
          sessionStorage.removeItem("_x")
          setShowAuthError(true)
        }
      } catch (error) {
        setShowIsloading(false)
        sessionStorage.removeItem("_x")
        setShowAuthError(true)
      }
    } catch (error) {}
  }

  const ShowPwdClickHandler = () => {
    setShowPwd(!showPwd);
  };
  useEffect(() => {
    setShowAuthError(false);
  }, [username, pwd]);

  useEffect(() => {
    if (showAuthError) {
      setErrorMessage(GetPrompts("Login Error"));
      setErrorPopUpIsOpen(true);
    }
  }, [showAuthError]);

  const HandleKeyDown = (e) => {
    if (e.key == "Enter") {
      authenticate(e);
    }
  };
  const OpenForgotPwd = () => {
    setShowForgotPwd(true);
    onClose();
  };

  if (!open) return null;
  return (
    <>
      <div style={OVERLAY_STYLES} onClick={onClose}></div>

      <div style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}>
        <div className="modalBackground-grid">
          <div>
            <div className="login-grid-container">
              <a className="titleCloseBtn">
                <button onClick={onClose}>X</button>
              </a>

              <form className="login_form">
                <label className="login-label">
                  {GetPrompts("Login User tag")}
                  {showAuthError ? <IoIosAlert className="error-Item" /> : ""}
                </label>

                <input
                  className="login-input"
                  type="text"
                  onChange={(e) => setUsername(e.target.value)}
                />

                <label className="login-label">
                  {GetPrompts("Login Pwd tag")}:{" "}
                  {showAuthError ? <IoIosAlert className="error-Item" /> : ""}
                  <a className="show-pwd-btn" onClick={ShowPwdClickHandler}>
                    <IoIosEye />
                  </a>
                </label>

                <input
                  className="login-input"
                  type={showPwd ? "text" : "password"}
                  onChange={(e) => setPwd(e.target.value)}
                  onKeyDown={HandleKeyDown}
                />
              </form>

              <label className="forgot-pwd" onClick={OpenForgotPwd}>
                {GetPrompts("Login forgot Pwd tag")}
              </label>

              <button className="login-btn" onClick={authenticate}>
                {GetPrompts("Login enter tag")}
              </button>

              {isloading ? (
                <div style={SPINNER_STYLES} className="loading-label">
                  <div>{GetPrompts("Login we are confirming")}</div>
                  <Spinner />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <ErrorPopUp
        open={errorPopUpIsOpen}
        onClose={() => setErrorPopUpIsOpen(false)}
        errorMessage={errorMessage}
      />
    </>
  );
}
