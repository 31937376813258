import axios from "../../axios";
export async function GetProductsByUser() {
  const url = "Product/api/v1/GetProductsByUser"
  try {
    const response = await axios.get(url, {
      params: {
        UserId: 0,
      },
    });
    return response.data;
  } catch (error) {}
}
