import customAxios from "../../../../../axios";

export const UpdateSkipWeek = async ({ calendarWeek, skipTitle }) => {
	try {
		const url = `PlannerCalendarPeriod/api/v1/UpdateSkipWeek`;

		const response = await customAxios.post(url, {
			UserId: 0,
			CalendarWeek: calendarWeek,
			SkipTitle: skipTitle,
		});
		return response.data;
	} catch (error) {}
};
