import { Elements } from "@stripe/react-stripe-js";
import { css } from "aphrodite";
import { loadStripe } from "@stripe/stripe-js";
import React, { useContext, useEffect, useState } from "react";
import Spinner from "react-spinkit";
import CartItemCard from "../../Components/Cart/CartItemCard";
import { GetCartItems } from "../../Components/Cart/GetCartItems";
import { GetCartItemsCount } from "../../Components/Cart/GetCartItemsCount";
import { GetCartSummary } from "../../Components/Cart/GetCartSummary";
import { PayCartItems } from "../../Components/Cart/PayCartItems";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { SPINNER_STYLES_NOTEXT } from "../../Components/Spinner/SpinnerStyles";
import { Context } from "../../Context/Contexts";
import "./Cart.css";
import HomePageSidebar from "../Home/HomePageSidebar";
import { animationStyles } from "../../Components/Animations/Styles";
import CartSummary from "./CartSummary";
import { GetStripePublicKey } from "../../Components/Cart/Stripe/GetStripePublicKey";

export default function Cart() {
	var loadCount = 0;
	const [stripePromise, setStripePromise] = useState("");
	const [yesNoDlgOpen, setYesNoDlgOpen] = useState(false);

	const { setShowFooter, cartItemCounter, setCartItemCounter } =
		useContext(Context);
	const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);
	const { isloading, setIsloading } = useContext(Context);
	const { setShowIsloading } = useContext(Context);

	const [cartSummary, setCartSummary] = useState("");

	const [totalItems, setTotalItems] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);

	const [cartItemsList, setcartItemsList] = useState([]);
	const [cartItemsIdsList, setCartItemsIdsList] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalPages, SetTotalPages] = useState(0);

	const [isMounted, setIsMounted] = useState(false);
	useEffect(() => {
		setIsMounted(true);
	}, []);

	useEffect(() => {
		setShowFooter(false);
		setShowNavbarItems(false);
		setShowSidebarToggle(true);
	});

	useEffect(() => {
		UpdateCartPage();
	}, [0]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [0]);

	const GetPublicKey = () => {
		GetStripePublicKey().then((response) => {
			setStripePromise(loadStripe(response.result));
		});
	};
	const UpdateCartPage = () => {
		setShowIsloading(true);
		GetCartSummary().then(function (response) {
			setTotalItems(response.totalItems);
			setTotalPrice(response.totalPrice);
		});

		GetCartItems({ currentPage, itemsPerPage }).then(function (response) {
			setcartItemsList(response);

			const idlist = response.map((item) => ({
				CartItemId: item.itemId,
			}));

			setCartItemsIdsList(idlist);

			setShowIsloading(false);
		});

		GetCartItemsCount().then(function (response) {
			setCartItemCounter(response);
		});
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		GetPublicKey();
		loadCount = loadCount + 1;
	};

	if (stripePromise === "") return null;
	return (
		<>
			<Sidebar />

			<Elements stripe={stripePromise}>
				<div className="container cart-container">
					<div
						className={
							css(isMounted ? animationStyles.bounceInDown : null) +
							" cart-sidebar sticky-side-container"
						}
					>
						<HomePageSidebar />
					</div>
					<div
						className={
							css(isMounted ? animationStyles.bounceInRight : null) +
							" cart-middle-container"
						}
					>
						<div className="cart-summary">
							<CartSummary
								totalItems={totalItems}
								totalPrice={totalPrice}
								UpdateCartPage={UpdateCartPage}
							/>
						</div>

						<div className="cart-items-list">
							{cartItemsList.map((cartItem) => {
								return (
									<CartItemCard
										cartItem={cartItem}
										UpdateCartPage={UpdateCartPage}
										yesNoDlgOpen={yesNoDlgOpen}
										setYesNoDlgOpen={setYesNoDlgOpen}
									/>
								);
							})}
						</div>
					</div>
					<div className="cart-right-container"></div>

					{isloading ? (
						<div style={SPINNER_STYLES_NOTEXT}>
							<Spinner />
						</div>
					) : (
						""
					)}
				</div>
			</Elements>
		</>
	);
}
