import React, { useState } from "react"
import "./PlannedActivitiesPerChildPerYearCard.css"
import moment from "moment"

export default function PlannedActivitiesPerChildPerYearCard(activity) {
  return (
    <>
      <div className="planned-activities-per-child-per-year-card-container">
        <div className="planned-activities-per-child-per-year-card-date">
          <span className="planned-activities-per-child-per-year-card-date-details">
            <span>{moment(activity.date).format("DD")}</span>
          </span>
          <span className="planned-activities-per-child-per-year-card-date-line"></span>
        </div>

        <div className="planned-activities-per-child-per-year-card-details">
          <span className="planned-activities-per-child-per-year-card-details-title base-sub-title">
            {activity.activity.description}
          </span>
          <span className="planned-activities-per-child-per-year-card-details-comment base-parafraph">
            {activity.comments}
          </span>
        </div>
      </div>
    </>
  )
}
