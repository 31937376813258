import { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import PlannerList from "../../Components/Planner/OldPlanner/PlannerList";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Context } from "../../Context/Contexts";
import "./Planner.css";

export default function Planner() {
  const { setShowFooter } = useContext(Context);
  const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);

  const [plannedDateConsult, setPlannedDateConsult] = useState(new Date());

  useEffect(() => {
    setShowFooter(false);
    setShowNavbarItems(false);
    setShowSidebarToggle(true);
  });

  return (
    <>
      <Sidebar />
      <div className="basic-page-title">Mi Mes</div>

      <div className="container planner-container">
        <div className="calendar-container">
          <Calendar
            onChange={setPlannedDateConsult}
            value={plannedDateConsult}
            defaultView={"month"}
          />
        </div>
      </div>

      <div className="container planner-list">
        <PlannerList plannedDateConsult={plannedDateConsult} />
      </div>
    </>
  );
}
