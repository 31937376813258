import DOMPurify from "dompurify";
import ReactQuill from "react-quill";

export function SafeHtmlComponent({ htmlContent }) {
	const safeHtml = DOMPurify.sanitize(htmlContent);

	return (
		<span className="safe-html-component">
			<p>
				<ReactQuill
					value={safeHtml}
					readOnly={true}
					theme="bubble"
					modules={{ toolbar: false }}
				/>
			</p>
		</span>
	);
}
