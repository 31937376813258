import axios from "../../axios";
export async function GetCartItemsCount() {
  const url = "Cart/api/v1/GetAllUserCartItemsCount"
  try {
    const response = await axios.get(url, {
      params: {
        UserId: 0,
      },
    });
    return response.data.result;
  } catch (error) {}
}
