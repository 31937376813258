import Moment from "moment";
import React, { useContext, useState } from "react";
import Spinner from "react-spinkit";
import { Context } from "../../../../Context/Contexts";
import DatePickerPopUp from "../../../DatePicker/DatePickerPopUp";
import { RESPONSIVE_MODAL_STYLES } from "../../../Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../../../Prompts/PromptsComponent";
import { SPINNER_STYLES_ORANGE } from "../../../Spinner/SpinnerStyles";
import { DeletePlannedActivityByDateRangeOmitComment } from "../../Actions/DeletePlannedActivityByDateRangeOmitComment";
import "./DeletePlanByDateRangeOmitComment.css";
import {
  MODAL_STYLES,
  OVERLAY_STYLES,
} from "./DeletePlanByDateRange_ModalStyles";

export default function DeletePlanByDateRangeOmitComment({
  open,
  close,
  activity,
}) {


  const lang = sessionStorage.getItem("_lang");
  const { isloading, setIsloading } = useContext(Context);
  const { setShowIsloading } = useContext(Context)
  const [deleteFromDate, setDeleteFromDate] = useState(new Date())
  const [deleteToDate, setDeleteToDate] = useState(new Date())

  const [deleteFromDateDateTimePicker, setDeleteFromDateDateTimePicker] =
    useState(false)
  const [deleteToDateDateTimePicker, setDeleteToDateDateTimePicker] =
    useState(false)

  const EditionFromDateHandler = (e) => {
    setDeleteFromDate(e)
  }
  const EditionToDateHandler = (e) => {
    setDeleteToDate(e)
  }

  const DeleteClickHandler = async () => {
    setShowIsloading(true)
    DeletePlannedActivityByDateRangeOmitComment({
      activity: activity,
      deleteFromDate: deleteFromDate,
      deleteToDate: deleteToDate,
    }).then((response) => {
      setShowIsloading(false)
      close()
    })
  }

  const CloseClickHandler = () => {
    close();
  };

  if (!open) return null;
  return (
    <>
      <div
        style={OVERLAY_STYLES}
        onClick={() => {
          CloseClickHandler();
        }}
      ></div>

      <div
        className="delete-plan-by-dat-range-container"
        style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
      >
        <div
          className="delete-plan-by-dat-range-close-btn"
          onClick={() => {
            CloseClickHandler();
          }}
        >
          X
        </div>

        <div className="delete-plan-by-dat-range-read-only-container">
          <div className="delete-plan-by-dat-range-read-only-name">
            {`${activity.activity[`description${lang}`]}`}
          </div>
          <div className="delete-plan-by-dat-range-read-only-hint">
            {`${activity.activity[`descriptionHint${lang}`]}`}
          </div>
        </div>

        <div className="delete-plan-by-dat-range-date-pickers-container">
          <div className="delete-plan-by-dat-range-date-pickers-label">
            {GetPrompts("Delete from")}
          </div>
          <div
            className="delete-plan-by-dat-range-date-pickers-input"
            onClick={() => {
              setDeleteFromDateDateTimePicker(true);
            }}
          >
            <div className="child-edition-form-input-text">
              {Moment(deleteFromDate).format("DD/MMM/YYYY")}
            </div>
          </div>
        </div>

        <div className="delete-plan-by-dat-range-date-pickers-container">
          <div className="delete-plan-by-dat-range-date-pickers-label">
            {GetPrompts("Delete to")}
          </div>
          <div
            className="delete-plan-by-dat-range-date-pickers-input"
            onClick={() => {
              setDeleteToDateDateTimePicker(true);
            }}
          >
            <div className="child-edition-form-input-text">
              {Moment(deleteToDate).format("DD/MMM/YYYY")}
            </div>
          </div>
        </div>

        <div className="delete-plan-by-dat-range-btns-container">
          <div
            className="delete-plan-by-dat-range-btns-cancel"
            onClick={() => {
              CloseClickHandler();
            }}
          >
            Cancel
          </div>
          <div
            className="delete-plan-by-dat-range-btns-delete"
            onClick={() => {
              DeleteClickHandler();
            }}
          >
            Delete
          </div>
        </div>

        {isloading ? <Spinner style={SPINNER_STYLES_ORANGE} /> : ""}
      </div>

      <DatePickerPopUp
        open={deleteFromDateDateTimePicker}
        close={() => {
          setDeleteFromDateDateTimePicker(false);
        }}
        onChange={setDeleteFromDate}
      />

      <DatePickerPopUp
        open={deleteToDateDateTimePicker}
        close={() => {
          setDeleteToDateDateTimePicker(false);
        }}
        onChange={setDeleteToDate}
      />
    </>
  );
}
