import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import "./SortableActivity.css";
import { SafeHtmlComponent } from "../../../RichTextEditor/SafeHtmlComponent";

export default function SortableActivity(props) {
	const [smallScreenSchedule, setSmallScreenSchedule] = useState(false);
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: props.id });

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		if (windowSize.width > 820) {
			setSmallScreenSchedule(false);
		} else {
			setSmallScreenSchedule(true);
		}

		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, [windowSize.width]);

	const hasEmptyComment = (activity) => {
		if (
			activity.formattedComments == "" ||
			activity.formattedComments === null ||
			activity.formattedComments === "<p><br></p>"
		) {
			return true;
		}
		return false;
	};

	const ActivityComponent = (activity) => {
		if (activity == null) {
			return;
		}
		var icon = (
			<>
				{/* <span className="weekly-ordered-schedule-activity-single-icon">
          <IoIcons.IoMdPerson />
        </span> */}
			</>
		);
		if (activity.grouped.length > 1) {
			icon = (
				<>
					{/* <span className="weekly-ordered-schedule-activity-group-icon">
            <IoIcons.IoMdPeople />
          </span> */}
				</>
			);
		}

		const GetGroupedChildren = () => {
			var grouped = activity.grouped;

			if (grouped != null) {
				if (grouped.length > 1) {
					return (
						<>
							<div>
								{grouped.map((activity) => {
									return (
										<div
											key={`grouped-child-${activity.planerChild.planChildId}`}
											className="weekly-ordered-schedule-activity-kid-name"
										>
											<span className="weekly-ordered-schedule-activity-group-icon">
												<IoIcons.IoMdPerson />
											</span>
											{activity.planerChild.firstName}
										</div>
									);
								})}
							</div>
						</>
					);
				} else {
					return (
						<>
							<div className="weekly-ordered-schedule-activity-kid-name">
								<span className="weekly-ordered-schedule-activity-single-icon">
									<IoIcons.IoMdPerson />
								</span>
								{activity.planerChild.firstName}
							</div>
						</>
					);
				}
			} else {
				return (
					<div className="weekly-ordered-schedule-activity-kid-name">
						<span className="weekly-ordered-schedule-activity-single-icon">
							<IoIcons.IoMdPerson />
						</span>
						{activity.planerChild.firstName}
					</div>
				);
			}
		};

		return (
			<>
				{smallScreenSchedule ? (
					<>
						<div
							className={`weekly-ordered-schedule-activity-container-small ${
								props.isSelectedForEdition(activity.plannerId) === true
									? "weekly-ordered-schedule-activity-container-normal-selected"
									: ""
							}`}
						>
							<div>{icon}</div>
							<div className="weekly-ordered-schedule-activity-container-small-title">
								{activity.activity.description.length > 7
									? activity.activity.description.slice(0, 7) + "..."
									: activity.activity.description}
							</div>
							<div>
								{activity.isPending ? (
									<IoIcons.IoMdFlag className="planned-activity-pending" />
								) : (
									""
								)}
							</div>
							<div className="weekly-ordered-schedule-activity-container-small-comment">
								{activity.comments.length > 7
									? activity.comments.slice(0, 9) + "..."
									: activity.comments}
							</div>
						</div>
					</>
				) : (
					<>
						<div
							className={`weekly-ordered-schedule-activity-container-normal ${
								props.isSelectedForEdition(activity.plannerId) === true
									? "weekly-ordered-schedule-activity-container-normal-selected"
									: ""
							}`}
						>
							<div>{icon}</div>
							<div className="weekly-ordered-schedule-activity-container-normal-title">
								{activity.activity.description}
							</div>
							<div>
								{activity.isPending ? (
									<IoIcons.IoMdFlag className="planned-activity-pending" />
								) : (
									""
								)}
							</div>
							{hasEmptyComment(activity) === false ? (
								<div className="weekly-ordered-schedule-activity-container-normal-comment">
									{activity.formattedComments !== null ? (
										<SafeHtmlComponent
											htmlContent={activity.formattedComments}
										/>
									) : (
										activity.comments
									)}
								</div>
							) : (
								<></>
							)}
							<div>{GetGroupedChildren()}</div>
						</div>
					</>
				)}
			</>
		);
	};

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		opacity: props.isDragging ? 0.5 : 1,
	};

	return (
		<>
			<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
				{ActivityComponent(props.activity)}
			</div>
		</>
	);
}
