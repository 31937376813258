import React, { useContext, useState } from "react"
import * as IoIcons from "react-icons/io";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent"
import "./MembershipCard.css"
import { AddMembershipToCart } from "./AddMembershipToCart"
import { Context } from "../../Context/Contexts"
import { GetCartItemsCount } from "../../Components/Cart/GetCartItemsCount"
import YesNoConfirm from "../../Components/ModalConfirm/YesNoConfirm"
import { useNavigate } from "react-router-dom"
import freeMembershipLogo from "../../Assets/MembershipIcons/Free_Membership_Logo.jpg";
import basicMembershipLogo from "../../Assets/MembershipIcons/Basic_Membership_Logo.jpg";
import premiumMembershipLogo from "../../Assets/MembershipIcons/Premium_Membership_Logo.jpg";
import {
	MEMBERSHIP_ALREADY_PRESENT,
	MEMBERSHIP_INTENT_TO_PURCHASE_ACTIVE_MEMBERSHIP,
} from "../../Components/Constants/Constants";
import ErrorPopUp from "../../Components/Errors/ErrorPopUp";
import InfoPopup from "../../Components/InfoPopup/InfoPopup";
export default function MembershipCard({ membership }) {

	const { setShowIsloading } = useContext(Context);
	const [addToCartconfirmation, setAddToCartconfirmation] = useState(false);

	const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const navigate = useNavigate();
	const GotoModule = (module) => {
		navigate(module);
	};

	const UpdateCart = () => {
		GetCartItemsCount().then(function (response) {
			GotoModule("/cart");
		});
	};

	const AddToCart = () => {
		setShowIsloading(true);
		AddMembershipToCart(membership.membershipId).then((response) => {
			setAddToCartconfirmation(false);
			setShowIsloading(false);
			if (response.errors === MEMBERSHIP_ALREADY_PRESENT) {
				setErrorMessage("Ya tienes una membresia en el carrito");
				setErrorPopUpIsOpen(true);
				return;
			}
			if (response.errors === MEMBERSHIP_INTENT_TO_PURCHASE_ACTIVE_MEMBERSHIP) {
				setErrorMessage("Esta membresia ya esta activa, disfrutala.");
				setErrorPopUpIsOpen(true);
				return;
			}
			UpdateCart();
		});
	};

	const membershipIcon = (membershipIdentifier) => {
		let membershipType = membershipIdentifier.toLowerCase();

		switch (true) {
			case membershipType.includes("basic"):
				return (
					<div>
						<img src={basicMembershipLogo} width={"100px"} />
					</div>
				);
			case membershipType.includes("premium"):
				return (
					<div>
						<img src={premiumMembershipLogo} width={"100px"} />
					</div>
				);
			default:
				return (
					<div>
						<img src={freeMembershipLogo} width={"100px"} />
					</div>
				);
		}
	};

	return (
		<>
			<div
				id={`membership-card-key${membership.membershipId}`}
				className="membership-card"
				onClick={() => {
					if (membership.title.toLowerCase().includes("free")) {
						return;
					}
					setAddToCartconfirmation(true);
				}}
			>
				<div className="membership-card-icon">
					{membershipIcon(membership.title)}
				</div>
				<div className="membership-card-title base-title">
					{GetPrompts(membership.title)}
				</div>
				<div className="membership-card-description base-parafraph">
					{GetPrompts(membership.description)}
				</div>
				<br />
				<div className="membership-card-cost">
					<div className="base-sub-title">{GetPrompts("MembershipCost")}:</div>
					<div className="base-parafraph">
						{" "}
						{membership.cost} / USD / {GetPrompts(membership.regularity)}
					</div>
				</div>
				<br />
				<div className="membership-card-benefit-container">
					<div className="base-sub-title">
						{GetPrompts("MembershipBenefits")}:
					</div>
					{membership.membershipBenefits.map((benefit) => (
						<div className="membership-card-benefit-item-container">
							<div className="base-parafraph">
								<span className="membership-card-benefit-title-check-mark">
									<IoIcons.IoMdCheckmark />
								</span>
								{GetPrompts(benefit.memBenefit.description)}
							</div>
						</div>
					))}
				</div>
			</div>

			<YesNoConfirm
				dlgOpen={addToCartconfirmation}
				callBackFunction={() => AddToCart()}
				onClose={() => setAddToCartconfirmation(false)}
				confirmMessage={
					"Al confirmar aqui, vamos a adicionar este item al carrito para completar el proceso. Deseas continuar?"
				}
			/>

			<InfoPopup
				icon={<IoIcons.IoMdCheckmarkCircle />}
				open={errorPopUpIsOpen}
				onClose={() => {
					setErrorPopUpIsOpen(false);
				}}
				errorMessage={errorMessage}
			/>
		</>
	);
}
