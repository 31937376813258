import React, { useContext, useEffect, useState } from "react"
import { IoIosAlert, IoIosEye } from "react-icons/io"
import { useNavigate, useSearchParams } from "react-router-dom"
import Spinner from "react-spinkit"
import ErrorPopUp from "../../Components/Errors/ErrorPopUp"
import CustomConfirmation from "../../Components/ModalConfirm/CustomConfirmation"
import { RESPONSIVE_MODAL_STYLES } from "../../Components/Modal_Styles/ResponsiveModalStyle"
import { SendPwdReset } from "../../Components/PwdReset/SendPwdReset"
import { SPINNER_STYLES_ORANGE } from "../../Components/Spinner/SpinnerStyles"
import "./PwdReset.css"
import { MODAL_STYLES, OVERLAY_STYLES } from "./PwdReset_ModalStyle"
import { Context } from "../../Context/Contexts"
export default function PwdReset({ open, onClose }) {
  const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false)
  const [showPwdResetConf, setShowPwdResetConf] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [showAuthError, setShowAuthError] = useState(false)
  const [showPwd, setShowPwd] = useState(false)
  const [pwd, setPwd] = useState("")
  const [confirmPwd, seConfirmPwd] = useState("")
  const [isloading, setIsloading] = useState(false)
  const { setShowIsloading } = useContext(Context)
  const navigate = useNavigate()
  const ShowPwdClickHandler = () => {
    setShowPwd(!showPwd)
  }

  useEffect(() => {
    sessionStorage.setItem("_x", JSON.stringify(searchParams.get("tk")))
  }, [searchParams])

  const HandleKeyDown = (e) => {
    if (e.key == "Enter") {
    }
  }

  const CloseConfirmationPopUp = () => {
    setShowPwdResetConf(false)
    navigate("/Landing")
  }
  const SendResetPwdRequest = (e) => {
    e.preventDefault()

    if (pwd == "" || pwd == undefined) {
      return
    }

    if (pwd != confirmPwd) {
      setErrorPopUpIsOpen(true)
      return
    }

    setShowIsloading(true)
    SendPwdReset({ newPwd: pwd }).then((response) => {
      if (response == true) {
        setShowPwdResetConf(true)
        setShowIsloading(false)
        return
      }
    })
  }

  return (
    <>
      <div style={OVERLAY_STYLES}></div>
      <div style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}>
        <div className="container pwd-reset-container">
          <div className="pwd-reset-close-btn" onClick={CloseConfirmationPopUp}>
            X
          </div>

          <form className="pwd-reset-form">
            <div className="pwd-reset-form-item">
              <label className="pwd-reset-label">
                Nueva Contraseña:
                {showAuthError ? (
                  <IoIosAlert className="pwd-reset-error-Item" />
                ) : (
                  ""
                )}
                <a
                  className="pwd-reset-show-pwd-btn"
                  onClick={ShowPwdClickHandler}
                >
                  <IoIosEye />
                </a>
              </label>
              <input
                className="pwd-reset-new-pwd-input"
                type={showPwd ? "text" : "password"}
                onChange={(e) => setPwd(e.target.value)}
              />
            </div>

            <div className="pwd-reset-form-item">
              <label className="pwd-reset-label">
                Confirmar Contraseña:
                {showAuthError ? (
                  <IoIosAlert className="pwd-reset-error-Item" />
                ) : (
                  ""
                )}
                <a
                  className="pwd-reset-show-pwd-btn"
                  onClick={ShowPwdClickHandler}
                >
                  <IoIosEye />
                </a>
              </label>
              <input
                className="pwd-reset-new-pwd-input"
                type={showPwd ? "text" : "password"}
                onChange={(e) => seConfirmPwd(e.target.value)}
              />
            </div>

            {!isloading ? (
              <div className="pwd-reset-send-btn-container">
                <div
                  className="pwd-reset-send-btn"
                  onClick={SendResetPwdRequest}
                >
                  Cambiar contraseña
                </div>
              </div>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>

      <CustomConfirmation
        dlgOpen={showPwdResetConf}
        onClose={CloseConfirmationPopUp}
        confirmMessage={"Listo, ya puedes utilizar tu nueva contraseña"}
      />

      <ErrorPopUp
        open={errorPopUpIsOpen}
        onClose={() => setErrorPopUpIsOpen(false)}
        errorMessage={"Las contraseñas deben coincidir"}
      />

      <div>
        {isloading ? (
          <div style={SPINNER_STYLES_ORANGE}>
            <Spinner />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  )
}
