import React, { useEffect, useRef, useState } from "react";
import { GetStyleToAdjustHeight } from "../../Components/Helpers/GetStyleToAdjustHeight";
import { ProcessWindowResize } from "../../Components/Helpers/ProcessWindowResize";
import WeeklyScheduleTimeSidebar from "./Sidebar/WeeklyScheduleTimeSidebar";
import WeeklySchedule from "./WeeklySchedule";
import "./WeeklySchedule_Redesign.css";
import CalendarSettings from "../../Components/Settings/Calendar/CalendarSettings";

export default function WeeklyScheduleTime_Redesign() {
	const boxRef = useRef();
	const { getPosition, STYLES } = GetStyleToAdjustHeight(boxRef);
	const [printWindowOpen, setPrintWindowOpen] = useState(false);

	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	ProcessWindowResize(setWindowSize, getPosition, windowSize);

	const handlePrint = () => {
		setPrintWindowOpen(true);

		const delay = 500;
		const timerId = setTimeout(OpenPrint, delay);
		return () => clearTimeout(timerId);
	};
	const OpenPrint = () => {
		window.print();
	};

	useEffect(() => {
		const handleAfterPrint = () => {
			setPrintWindowOpen(false);
		};
		window.onafterprint = handleAfterPrint;

		return () => {
			window.onafterprint = null;
		};
	}, []);

	return (
		<>
			<div className="weekly-schedule-calendar-container">
				{!printWindowOpen ? (
					<div
						className="weekly-schedule-calendar-sidebar"
						ref={boxRef}
						style={STYLES}
					>
						<WeeklyScheduleTimeSidebar />
					</div>
				) : (
					""
				)}
				<div
					className={
						!printWindowOpen
							? "weekly-schedule-calendar-calendar"
							: "weekly-schedule-calendar-calendar-print"
					}
				>
					<WeeklySchedule handlePrint={handlePrint} />
				</div>
				<CalendarSettings />
			</div>
		</>
	);
}
