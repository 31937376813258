import React, { useEffect, useRef, useState } from "react"
import { GetMemberships } from "./GetMemberships"
import "./Membership.css"
import Sidebar from "../../Components/Sidebar/Sidebar"
import MembershipCard from "./MembershipCard"
import { MembershipSideBar } from "./MembershipSideBar"
import { GetStyleToAdjustHeight } from "../../Components/Helpers/GetStyleToAdjustHeight"
import { ProcessWindowResize } from "../../Components/Helpers/ProcessWindowResize"
import { PlannerHome } from "../../Components/Planner/PlannerHome/PlannerHome"
export default function Membership() {
  const boxRef = useRef()
  const { getPosition, STYLES } = GetStyleToAdjustHeight(boxRef)

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  ProcessWindowResize(setWindowSize, getPosition, windowSize)

  const [memberships, setMemberships] = useState([])

  useEffect(() => {
    GetMemberships().then((response) => {
      setMemberships(response)
    })
  }, [0])

  return (
    <>
      <Sidebar />

      <div className="membership-main-component-container">
        <div
          className="membership-main-component-left-container"
          ref={boxRef}
          style={STYLES}
        >
          <MembershipSideBar />
        </div>
        <div className="membership-main-component-middle-container">
          {memberships.map((membership) => (
            <MembershipCard membership={membership} />
          ))}
        </div>
        {/* <div className="membership-main-component-right-container">
          Empty
        </div> */}
      </div>
    </>
  )
}
