import React, { useEffect, useState } from "react";
import ErrorPopUp from "../../../../Errors/ErrorPopUp";

import { RESPONSIVE_MODAL_STYLES } from "../../../../Modal_Styles/ResponsiveModalStyle";
import "../NewYearActivityNoteCard/NewYearActivityNoteCard.css";
import { UpdateNewYearActivityNote } from "./UpdateNewYearActivityNote";
import "./UpdateYearActivityNoteCard.css";
import {
  MODAL_STYLES,
  OVERLAY_STYLES,
} from "./UpdateYearActivityNoteCard_ModalStyle";

export default function UpdateYearActivityNoteCard({
  open,
  onClose,
  noteToEdit,
  GetNotes,
}) {
  const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [note, setNote] = useState(noteToEdit.note);

  const SaveClickHandler = async () => {
    if (note == "") {
      setErrorMessage("Necesitamos el texto de la nota");
      setErrorPopUpIsOpen(true);
      return;
    }
    await UpdateNewYearActivityNote(noteToEdit.yearActivityNoteId, note).then(
      (response) => {
        GetNotes();
        setNote("");
        onClose();
      }
    );
  };

  const CloseClickHandler = () => {
    onClose();
  };
  useEffect(() => {
    setNote(noteToEdit.note);
  }, [noteToEdit]);

  if (!open) return null;

  return (
    <>
      <div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>
      <div
        className="new-year-activity-note-card"
        style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
      >
        <div
          className="new-year-activity-note-card-close-btn"
          onClick={CloseClickHandler}
        >
          X
        </div>

        <div className="year-activity-notes-card-ul">
          <li>
            <textarea
              autoFocus
              type="text"
              className="new-year-activity-note-card-text-area"
              placeholder={noteToEdit.note}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </li>
        </div>

        <div
          className="new-year-activity-note-card-create-btn"
          onClick={SaveClickHandler}
        >
          Guardar
        </div>
      </div>
      <ErrorPopUp
        open={errorPopUpIsOpen}
        onClose={() => setErrorPopUpIsOpen(false)}
        errorMessage={errorMessage}
      />
    </>
  );
}
