import axios from "../../../../axios";
export async function GetPlannedActivityPlan({
  activityId,
  // childId,
  fromDate,
  toDate,
}) {
  const url = "Planner/api/v1/GetPlannedActivityPlan"
  try {
    const response = await axios.get(url, {
      params: {
        UserId: 0,
        ActivityId: activityId,
        // ChildId: childId,
        FromDateTime: fromDate,
        ToDateTime: toDate,
      },
    });
    return response.data.result;
  } catch (error) {}
}
