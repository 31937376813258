import customAxios from "../../../axios"

export async function GetPlannedActivitiesByChildByYear({
  childId = 0,
  yearId = 0,
}) {
  const url = "Planner/api/v1/GetPlannedActivitiesByChildByYear"
  try {
    const response = await customAxios.get(url, {
      params: {
        UserId: 0,
        PlanChildId: childId,
        YearId: yearId,
      },
    })

    return response.data.result
  } catch (error) {}
}
