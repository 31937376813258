import Moment from "moment";
import axios from "../../../../axios";
export async function GetWeeklyScheduleListAll({
  weeklyScheduleDate = "08-10-2022 07:00:00",
  childId = 0,
}) {
  const url = "Planner/api/v1/GetWeeklyScheduleAll"
  try {
    const response = await axios.get(url, {
      params: {
        UserId: 0,
        CurrentDate: Moment(weeklyScheduleDate).format("MM/DD/YYYY"),
        ChildId: childId,
      },
    })

    return response.data.result
  } catch (error) {}
}
