import React from "react";
import "./CartSummary.css";
import CheckOutWindow from "../../Components/Checkout/CheckOutWindow";
import { loadStripe } from "@stripe/stripe-js";
import * as IoIcons from "react-icons/io";

export default function CartSummary({
	totalItems,
	totalPrice,
	UpdateCartPage,
}) {
	return (
		<>
			<div className="cart-summary-container">
				<div className="cart-summary-icon">
					<IoIcons.IoMdCart />
				</div>
				<div className="cart-summary-total-items base-title-normal-height">
					Total items:{" "}
					<span className="cart-summary-total-items-number"> {totalItems}</span>
				</div>
				<div className="cart-summary-total-price base-title-normal-height">
					Total:{" "}
					<span className="cart-summary-total-price-number">${totalPrice}</span>
				</div>
				{totalPrice > 0 ? (
					<div className="cart-summary-checkout-btn">
						<CheckOutWindow
							UpdateCartPage={UpdateCartPage}
							totalPrice={totalPrice}
						/>
					</div>
				) : (
					""
				)}
			</div>
		</>
	);
}
