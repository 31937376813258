import customAxios from "../../../../axios";

export async function CreatePlanPeriod({
	startFromDate,
	numberOfWeeks,
	periodTitle,
}) {
	try {
		const url = `PlannerCalendarPeriod/api/v1/CreatePlanPeriod`;

		const response = await customAxios.put(url, {
			UserId: 0,
			StartFromDate: startFromDate,
			NumberOfWeeks: numberOfWeeks,
			PeriodTitle: periodTitle,
		});
		return response.data;
	} catch (error) {}
}
