export function EditPlannerSettingFromDate(state, action) {
  var newSettings = state.plannerSettings;
  newSettings.fromDate = action.payload.newDate;
  return newSettings;
}

export function EditPlannerSettingToDate(state, action) {
  var newSettings = state.plannerSettings;
  newSettings.toDate = action.payload.newDate;
  return newSettings;
}

export function EditPlannerSettingFromTime(state, action) {
  var newSettings = state.plannerSettings;
  newSettings.fromTime = action.payload.newTime;
  return newSettings;
}

export function EditPlannerSettingToTime(state, action) {
  var newSettings = state.plannerSettings;
  newSettings.toTime = action.payload.newTime;
  return newSettings;
}
