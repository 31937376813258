import { StyleSheet, css } from "aphrodite";
import {
  bounceInDown,
  bounceInRight,
  bounceInUp,
  fadeIn,
  fadeInDown,
  fadeInUp,
  shake,
  bounce,
} from "react-animations"
export const animationStyles = StyleSheet.create({
  fadeIn: {
    animationName: fadeIn,
    animationDuration: "1s",
  },
  fadeInDown: {
    animationName: fadeInDown,
    animationDuration: "1s",
  },

  fadeInUp: {
    animationName: fadeInUp,
    animationDuration: "1s",
  },

  bounceInDown: {
    animationName: bounceInDown,
    animationDuration: "2s",
  },
  bounceInDownQuick: {
    animationName: bounceInDown,
    animationDuration: "1s",
  },
  shake: {
    animationName: shake,
    animationDuration: "1s",
  },
  bounce: {
    animationName: bounce,
    animationDuration: "2s",
  },
  bounceInUp: {
    animationName: bounceInUp,
    animationDuration: "2s",
  },
  bounceInRight: {
    animationName: bounceInRight,
    animationDuration: "2s",
  },
})

export const BounceInDown = (isMounted) => {
  return css(isMounted ? animationStyles.bounceInDown : null);
};
