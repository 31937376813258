import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../Context/Contexts";
import YesNoConfirm from "../../../ModalConfirm/YesNoConfirm";
import { RESPONSIVE_MODAL_STYLES } from "../../../Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../../../Prompts/PromptsComponent";
import { DeleteYearPlanActivity } from "../Activities/DeleteYearPlanActivity/DeleteYearPlanActivity";
import DeletePlanByDateRangeOmitComment from "../DeletePlan/DeletePlanByDateRangeOmitComment";
import PlannedActivityPlan from "../PlannedActivityPlan/PlannedActivityPlan";
import YearActivityNotes from "../YearActivityNotes/YearActivityNotes";
import "./ActivityOptions.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./ActivityOptions_ModalStyles";

export default function ActivityOptions({
  activity,
  dlgOpen,
  onclose,
  planActivity,
}) {
	const lang = sessionStorage.getItem("_lang");
	const navigate = useNavigate();
	const { refreshYearPlanner, setRefreshYearPlanner } = useContext(Context);
	const [yesNoOpen, setYesNoOpen] = useState(false);
	const [showPlannedActivityPlan, setShowPlannedActivityPlan] = useState(false);
	const [yearActivityNotesIsOpen, setYearActivityNotesIsOpen] = useState(false);
	const [deletePlanIsOpen, setDeletePlanIsOpen] = useState(false);

	if (dlgOpen == false) {
		return;
	}

	const Plan = () => {
		planActivity();
		onclose();
	};

	const DeleteClickHandler = async () => {
		DeleteYearPlanActivity(activity.yearActivityId).then((response) => {
			setRefreshYearPlanner(true);
			onclose();
		});

		setYesNoOpen(false);
	};

	const GotoWeeklyCalendar = () => {
		navigate("/WeeklyScheduleOrdered");
	};
	return (
		<>
			<div style={OVERLAY_STYLES} onClick={onclose}></div>
			<div
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
				className="activity-options-container"
			>
				<div className="activity-options-close-btn" onClick={onclose}>
					X
				</div>

				<div>
					<span className="activity-options-item-title"></span>
					<span className="activity-options-item-title base-title-normal-height">
						{" "}
						{`${activity.activity[`description${lang}`]}`}
					</span>
					<div className="year-plan-activity-descriptionHint base-parafraph">
						{`${activity.activity[`descriptionHint${lang}`]}`}
					</div>
				</div>
				<div
					className="activity-options-item base-sub-title"
					onClick={() => GotoWeeklyCalendar()}
				>
					{GetPrompts("Calendar")}
				</div>
				<div
					className="activity-options-item base-sub-title"
					onClick={() => {
						setYearActivityNotesIsOpen(true);
					}}
				>
					{GetPrompts("Notes")}
				</div>
				<div
					className="activity-options-item base-sub-title"
					onClick={() => {
						Plan();
					}}
				>
					{GetPrompts("Planner Plan")}
				</div>

				{activity.lastPlanned !== null ? (
					<>
						<div
							className="activity-options-item base-sub-title"
							onClick={() => {
								setDeletePlanIsOpen(true);
							}}
						>
							{GetPrompts("Delete plan")}
						</div>
					</>
				) : (
					""
				)}

				{activity.activity.userId != process.env.REACT_APP_DEFAULT_USER_ID ? (
					<>
						<div
							className="activity-options-item base-sub-title"
							onClick={() => setYesNoOpen(true)}
						>
							{GetPrompts("Delete activity")}
						</div>
					</>
				) : (
					""
				)}
			</div>

			{showPlannedActivityPlan ? (
				<PlannedActivityPlan
					activity={activity}
					dlgOpen={showPlannedActivityPlan}
					onclose={() => {
						setShowPlannedActivityPlan(false);
					}}
				/>
			) : (
				""
			)}

			{yearActivityNotesIsOpen ? (
				<YearActivityNotes
					open={yearActivityNotesIsOpen}
					onClose={() => {
						setYearActivityNotesIsOpen(false);
					}}
					activityId={activity.activityId}
				/>
			) : (
				""
			)}

			<DeletePlanByDateRangeOmitComment
				open={deletePlanIsOpen}
				close={() => setDeletePlanIsOpen(false)}
				activity={activity}
			/>

			<YesNoConfirm
				dlgOpen={yesNoOpen}
				callBackFunction={DeleteClickHandler}
				onClose={() => setYesNoOpen(false)}
				confirmMessage={GetPrompts("Delete Confirmation Text")}
				centered={true}
			/>
		</>
	);
}
