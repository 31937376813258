import { css } from "aphrodite";
import React, { useContext, useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import { IoMdArrowBack } from "react-icons/io";
import logo from "../../Assets/Logo.svg";
import { animationStyles } from "../../Components/Animations/Styles";
import InfoPopup from "../../Components/InfoPopup/InfoPopup";
import { RESPONSIVE_MODAL_STYLES } from "../../Components/Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import { Context } from "../../Context/Contexts";
import "./SignUpStep1.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./SignUpStep1_ModalStyles";
import { IsValidEmail } from "../../Components/IsValidEmail/IsValidEmail"
export default function SignUpStep1({ open, onClose, prevStep, nextStep }) {
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])

  const { newUser, setNewUser } = useContext(Context)
  const [promptMessage, setPromptMessage] = useState("")
  const [promptMessageIsOpen, setPromptMessageIsOpen] = useState(false)

  const CancelRegistration = () => {
    handleNameChange("")
    handleLastNameChange("")
    handleEmailChange("")
    onClose()
  }

  const handleNameChange = (e) => {
    newUser.Name = e
  }
  const handleLastNameChange = (e) => {
    newUser.LastName = e
  }
  const handleEmailChange = (e) => {
    newUser.Email = e
  }

  const PrevStep = () => {
    prevStep()
    onClose()
  }
  const Continue = () => {
    var showError = false
    if (
      !newUser.hasOwnProperty("Name") ||
      !newUser.hasOwnProperty("LastName") ||
      !newUser.hasOwnProperty("Email")
    ) {
      showError = true
    }
    if (newUser.Name == "" || newUser.LastName == "" || newUser.Email == "") {
      showError = true
    }
    if (showError) {
      setPromptMessage(GetPrompts("We need name and last name"))
      setPromptMessageIsOpen(true)
      return
    }
    if (!IsValidEmail(newUser.Email)) {
      setPromptMessage(GetPrompts("We need a valid email"))
      setPromptMessageIsOpen(true)
      return
    }

    nextStep()
    onClose()
  }

  if (!open) return null
  return (
		<>
			<div style={OVERLAY_STYLES} onClick={CancelRegistration}></div>

			<div
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
				className="sign-up-step1-container"
			>
				<div
					style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
					className="background-picture"
				></div>
				<div className="sign-up-step1-close-btn-container">
					<button
						className="sign-up-step1-close-btn"
						onClick={CancelRegistration}
					>
						X
					</button>
				</div>

				<span
					className={
						css(isMounted ? animationStyles.bounceInRight : null) +
						" modal-span"
					}
				>
					<div className="sign-up-step1-logo">
						<a className="brand-logo">
							<img src={logo} className="sign-up-step1-brand-logo" alt="logo" />
						</a>
					</div>
					<div className="sign-up-step1-text">
						{GetPrompts("SignUp Title Text")}{" "}
						{GetPrompts("SignUp Pararaph Text")}
					</div>
					<div
						className="sign-up-step1-back"
						onClick={() => {
							PrevStep();
						}}
					>
						<IoMdArrowBack />
					</div>
					<div className="sign-up-step1-title-text">
						{GetPrompts("Enter your information")}
					</div>
				</span>

				<span
					className={
						css(isMounted ? animationStyles.bounceInUp : null) + " modal-span"
					}
				>
					<div className="sign-up-step1-form-item">
						<span className="sign-up-step1-label">
							{GetPrompts("Name text")}
						</span>
						<input
							id="sign-up-step1-name"
							type="text"
							className="sign-up-step1-input"
							onChange={(e) => handleNameChange(e.target.value)}
							defaultValue={newUser.Name ? newUser.Name : ""}
						/>
					</div>
					<div className="sign-up-step1-form-item">
						<span className="sign-up-step1-label">
							{GetPrompts("Last Name text")}
						</span>
						<input
							id="sign-up-step1-last-name"
							type="text"
							className="sign-up-step1-input"
							onChange={(e) => handleLastNameChange(e.target.value)}
							defaultValue={newUser.LastName ? newUser.LastName : ""}
						/>
					</div>
					<div className="sign-up-step1-form-item">
						<span className="sign-up-step1-label">
							{GetPrompts("Email text")}
						</span>
						<input
							id="sign-up-step1-email"
							type="text"
							className="sign-up-step1-input"
							onChange={(e) => handleEmailChange(e.target.value.toLowerCase())}
							defaultValue={newUser.Email ? newUser.Email : ""}
						/>
					</div>

					<div className="sign-up-step1-already-has-account-container">
						<span className="sign-up-step1-already-has-account">
							{GetPrompts("Already have an account")}
						</span>{" "}
						<span
							className="sign-up-step1-login"
							onClick={() => {
								onClose();
							}}
						>
							{GetPrompts("Login enter tag")}
						</span>
					</div>

					<div
						id="sign-up-step1-continue-btn"
						className="sign-up-step1-continue-container"
						onClick={() => {
							Continue();
						}}
					>
						<div className="sign-up-step1-continue">
							<p className="sign-up-step1-continue-text">
								{GetPrompts("Continue with registration")}
							</p>
						</div>
					</div>
				</span>
			</div>

			<InfoPopup
				icon={<IoIcons.IoMdAlert />}
				open={promptMessageIsOpen}
				onClose={() => {
					setPromptMessageIsOpen(false);
				}}
				errorMessage={promptMessage}
			/>
		</>
	);
}
