export const RESPONSIVE_MODAL_STYLES = (modalObj) => {
  var objToReturn = modalObj;

  if (window.innerHeight < parseInt(objToReturn.height)) {
    const temp = JSON.stringify(objToReturn);
    const copiedValues = JSON.parse(temp);
    copiedValues.height = "100%";

    return copiedValues;
  } else {
    return objToReturn;
  }
};

export const MODAL_STYLES_BACKGROUND = {
  position: "fixed",
  width: "400px",
  height: "540px",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  zIndex: 1000,
};
