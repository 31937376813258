import axios from "../../axios";
export async function AddItemToCart({ productId, qty = 1 }) {
  const url = "Cart/api/v1/CreateNewCartItem"
  try {
    const response = await axios.put(url, {
      UserId: 0,
      ProductId: productId,
      Qty: qty,
    });
    return response.data.result;
  } catch (error) {}
}
