import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import { Context } from "../../Context/Contexts";
import Pagination from "../Pagination/Pagination";
import ActivityCard from "./ActivityCard";
import ActivityCard_Edit from "./ActivityCard_Edit";
import { GetActivitiesTable } from "./DataTable/GetActivitiesTable";
import { EmptyActivity } from "./EmptyActivity";
import { GetActivities } from "./GetActivities";
import { GetFilteredActivities } from "./GetFilteredActivities";

export default function ActivitiesList() {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(Context);
  const { isloading, setIsloading } = useContext(Context);
  const [showDatatable, setShowDatatable] = useState(false);
  const [userId, setUserId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, SetTotalPages] = useState(0);

  const [showFilter, setShowFilter] = useState(false);
  const [filterBySchoolSubject, setFilterBySchoolSubject] = useState("");
  const [filterByActivity, setFilterByActivity] = useState("");

  const [activityInEdition, setActivityInEdition] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [activitiesList, setActivitiesList] = useState([]);

  useEffect(() => {
    if (filterByActivity != "" || filterBySchoolSubject != "") {
      FilterClickHandler();
    } else {
      FeedActivitiesList();
    }
  }, [currentPage]);

  useEffect(() => {
    if (filterBySchoolSubject == "" && filterByActivity == "") {
      FilterClickHandler();
    }
  }, [filterBySchoolSubject, filterByActivity]);

  const FeedActivitiesList = () => {
    GetActivities({ currentPage, itemsPerPage }).then(function (response) {
      setCurrentPage(response.currentPage);
      SetTotalPages(response.pages);

      setActivitiesList(response.result);
    });
  };

  const NewActivityClickHandler = () => {
    const emptyActivity = EmptyActivity;
    emptyActivity.userId = userId;

    setActivityInEdition(emptyActivity);
    setIsOpen(true);
  };

  const FilterClickHandler = () => {
    GetFilteredActivities({
      currentPage,
      itemsPerPage,
      filterBySchoolSubject,
      filterByActivity,
    }).then(function (response) {
      setCurrentPage(response.currentPage);
      SetTotalPages(response.pages);
      setActivitiesList(response.result);
    });
  };

  const HandleKeyDown = (e) => {
    if (e.key == "Enter") {
      FilterClickHandler();
    }
  };
  function GetListCards() {
    {
      return activitiesList.map((activity) => {
        return ActivityCard({ activity, setActivityInEdition, setIsOpen });
      });
    }
  }

  return (
    <>
      <div className="container">
        <div className="page-menu-container">
          <div
            className="show-filter-button-btn"
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            <FontAwesomeIcon icon={faFilter} />{" "}
          </div>

          <div className="add-button-btn" onClick={NewActivityClickHandler}>
            <IoIcons.IoMdAddCircle />
          </div>

          <div
            className="change-view-btn"
            onClick={() => {
              setShowDatatable(!showDatatable);
            }}
          >
            <RiIcons.RiTableAltLine />
          </div>
        </div>

        {showFilter ? (
          <>
            <div
              className="activities-filters-container"
              onKeyDown={HandleKeyDown}
            >
              <div className="filter-activity">
                Actividad:{" "}
                <input
                  type="text"
                  className="filter-input"
                  onChange={(e) => setFilterByActivity(e.target.value)}
                />
              </div>

              <div className="filter-assignment">
                Grupo:{" "}
                <input
                  type="text"
                  className="filter-input"
                  onChange={(e) => setFilterBySchoolSubject(e.target.value)}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div
          className={
            showDatatable
              ? "container activities-container-datatable"
              : "container activities-container"
          }
        >
          {activitiesList ? (
            <>
              {showDatatable ? (
                <>
                  {GetActivitiesTable({
                    activitiesList,
                    setActivityInEdition,
                    setIsOpen,
                  })}
                </>
              ) : (
                <>{GetListCards()}</>
              )}
            </>
          ) : (
            <></>
          )}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <ActivityCard_Edit
        open={isOpen}
        activity={activityInEdition}
        onClose={() => setIsOpen(false)}
        getActivities={FeedActivitiesList}
        setUserId={setUserId}
      />
    </>
  );
}
