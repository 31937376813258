import React, { useContext, useEffect, useState } from 'react'
import WorldMap from 'react-svg-worldmap'
import './WorldMapUsers_Modal.css'
import { RESPONSIVE_MODAL_STYLES } from '../Modal_Styles/ResponsiveModalStyle'
import { MODAL_STYLES, OVERLAY_STYLES } from './WorldMapUsers_Modal_ModalStyle'
import { Context } from '../../Context/Contexts'
import { GetUsersPerCountryCode } from './GetUsersPerCountryCode'
import { GetPrompts } from '../Prompts/PromptsComponent'

// Sample data (replace this with your actual data)
const countryUser = {
  US: 100,
  CA: 50,
  MX: 30,
  CO: 350,
  // Add more countries and user counts as needed
}

export default function WorldMapUsers_Modal() {
  const { showUsersInTheWorld, setShowUsersInTheWorld } = useContext(Context)

  const [dataForMap, setdataForMap] = useState([])

  const CloseClickHandler = () => {
    setShowUsersInTheWorld(false)
  }

  const GetusersPerCountryList = () => {
    GetUsersPerCountryCode().then((response) => {
      var mapData = response.map((item) => ({
        country: item.countryCode,
        value: item.usersValue,
      }))

      setdataForMap(mapData)
    })
  }

  useEffect(() => {
    if (showUsersInTheWorld == true) {
      GetusersPerCountryList()
    }
  }, [showUsersInTheWorld])

  if (!showUsersInTheWorld) return null

  return (
    <>
      <div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>

      {dataForMap.length > 0 ? (
        <div
          className="world-map-users-container"
          style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
        >
          <div
            className="world-map-users-close-btn"
            onClick={CloseClickHandler}
          >
            X
          </div>

          <div className="world-map-users-title">
            {GetPrompts('Educators in the world using our platform')}
          </div>
          <div className="world-map-users-maap">
            <WorldMap
              color="green"
              valueSuffix="users"
              size="responsive"
              data={dataForMap}
              tooltipBgColor="orange"
              borderColor="black"
              // backgroundColor="black"
              tooltipTextColor="black"
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}
