import axios from "../../axios";
export async function GetActivities({ currentPage = 1, itemsPerPage = 10 }) {
  const url = "Activity/api/v1/GetAllActivitiesPaged"
  try {
    const response = await axios.get(url, {
      params: {
        CurrentPage: currentPage,
        ItemsPerPage: itemsPerPage,
      },
    });

    return response.data;
  } catch (error) {}
}
