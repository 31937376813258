import customAxios from "../../../axios"

export async function HandleGoogleLogin({ credential }) {
  const url = "UserAuthGoogle/api/v1/SignIn"
  try {
    const response = await customAxios.post(url, {
      Credential: credential,
    })
    return response.data.result
  } catch (error) {}
}
