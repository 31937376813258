import React from "react";
import "./Pagination.css";

export default function Pagination({
  currentPage,
  totalPages,
  setCurrentPage,
}) {
  const PreviousPageClickHandler = () => {
    var page = currentPage - 1;
    if (page <= 0) {
      return;
    }
    setCurrentPage(page);
  };
  const NextPageClickHandler = () => {
    var page = currentPage + 1;
    if (page > totalPages) {
      return;
    }
    setCurrentPage(page);
  };

  return (
    <>
      <div className="container page-info-container">
        <div className="page-info">
          <a className="page-info page-back" onClick={PreviousPageClickHandler}>
            {"<< "}
          </a>
          Pagina {currentPage} de {totalPages}
          <a className="page-info page-forward" onClick={NextPageClickHandler}>
            {" >>"}
          </a>
        </div>
      </div>
    </>
  );
}
