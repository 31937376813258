import Moment from "moment";
import axios from "../../../axios";
export const DeletePlannedActivityByDateRange = async ({
  plannedActivity,
  deleteFromDate,
  deleteToDate,
}) => {
  try {
    const url = `Planner/api/v1/DeletePlannedActivityByDateRange`

    const response = await axios.post(url, {
      UserId: 0,
      PlannerId: plannedActivity.plannerId,
      DeleteFromDate: Moment(deleteFromDate).format("YYYY-MM-DDT00:00:00"),
      DeleteToDate: Moment(deleteToDate).format("YYYY-MM-DDT11:59:00"),
    });
  } catch (error) {}
};
