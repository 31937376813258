import axios from "../../../../../axios";
export const UpdateNewYearActivityNote = async (noteId, note) => {
  try {
    const url = `PlannerYearActivitiesNotes/api/v1/UpdateYearActivityNote`

    const response = await axios.post(url, {
      UserId: 0,
      YearActivityNoteId: noteId,
      Note: note,
    });
  } catch (error) {}
};
