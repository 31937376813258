import React, { useContext, useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import { Context } from "../../../Context/Contexts";
import { ChildrenEmbededSelector } from "../../Children/ChildrenHome/ChildrenHome";
import ErrorPopUp from "../../Errors/ErrorPopUp";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import NewYearPlannedActivity from "../AddYearPlannedActivity/NewYearPlannedActivity";
import YearPlanActivitiesSelector from "../YearPlan/Activities/YearPlanActivitiesSelector/YearPlanActivitiesSelector";
import "./PlanFromSchedule.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./PlanFromSchedule_ModalStyles";

export default function PlanFromSchedule({
	open,
	close,
	defaultDate,
	isActivityWithTime = false,
}) {
	const { selectedChildGlobal } = useContext(Context);
	const { setShowChildrenSelectorPopUp } = useContext(Context);
	const { setShowSelectAllChildrenOption } = useContext(Context);
	const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [selectedChild, setSelectedChild] = useState("");
	const [selectedActivity, setSelectedActivity] = useState();
	const [openActivitySelector, setOpenActivitySelector] = useState(false);
	const [activityPlannerIsOpen, setActivityPlannerIsOpen] = useState(false);
	const { refreshWeeklySchedule, setRefreshWeeklySchedule } =
		useContext(Context);

	const CloseClickHandler = () => {
		close();
	};

	const PlanHandler = () => {
		if (selectedChildGlobal.planChildId == 0) {
			setErrorMessage(GetPrompts("Please select one student"));
			setErrorPopUpIsOpen(true);
			return;
		}

		if (selectedActivity == undefined) {
			setErrorMessage(GetPrompts("Please select one activity"));
			setErrorPopUpIsOpen(true);
			return;
		}

		setActivityPlannerIsOpen(true);
	};

	useEffect(() => {}, [selectedActivity]);
	if (!open) return null;
	return (
		<>
			<div
				style={OVERLAY_STYLES}
				onClick={() => {
					CloseClickHandler();
				}}
			></div>

			<div
				className="plan-activity-from-schedule-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className="plan-activity-from-schedule-close-btn"
					onClick={() => {
						CloseClickHandler();
					}}
				>
					X
				</div>

				<div className="plan-activity-from-schedule-form-row">
					<div className="plan-activity-from-schedule-form-label">
						{GetPrompts("Choose the student")}
					</div>
					<div
						className="plan-activity-from-schedule-form-label-input"
						onClick={() => {
							setShowSelectAllChildrenOption(false);
							setShowChildrenSelectorPopUp(true);
						}}
					>
						<div className="plan-activity-from-schedule-children-btn hover-brighten">
							<IoIcons.IoMdPeople />
						</div>
					</div>
				</div>
				<dir className={"plan-activity-from-schedule-selected-activity"}>
					{selectedChildGlobal != undefined ? (
						<div className="plan-activity-from-schedule-activities-selected">
							Estudiante: {selectedChildGlobal.firstName}
						</div>
					) : (
						""
					)}
				</dir>
				<div className="plan-activity-from-schedule-form-row">
					<div className="plan-activity-from-schedule-form-label">
						{GetPrompts("Choose the activity")}
					</div>

					<div
						className="plan-activity-from-schedule-form-label-input"
						onClick={() => {
							setOpenActivitySelector(true);
						}}
					>
						<div className="plan-activity-from-schedule-activity-btn hover-brighten">
							<IoIcons.IoMdBook />
						</div>
					</div>
				</div>
				<dir className={"plan-activity-from-schedule-selected-activity"}>
					{selectedActivity != undefined ? (
						<div className="plan-activity-from-schedule-activities-selected">
							Actividad: {selectedActivity.description}
						</div>
					) : (
						""
					)}
				</dir>
				<div className="plan-activity-from-schedule-plan-btn-container">
					<div
						className="plan-activity-from-schedule-cancel-btn hover-darken"
						onClick={() => {
							CloseClickHandler();
						}}
					>
						{GetPrompts("Cancel text")}
					</div>
					<div
						className="plan-activity-from-schedule-plan-btn hover-brighten"
						onClick={() => {
							PlanHandler();
						}}
					>
						{GetPrompts("Planner Plan")}
					</div>
				</div>
			</div>

			<NewYearPlannedActivity
				open={activityPlannerIsOpen}
				plannedActivity={selectedActivity}
				onClose={() => setActivityPlannerIsOpen(false)}
				GetPlannedActivities={() => {
					setRefreshWeeklySchedule(true);
				}}
				setUserId={0}
				selectedChild={
					selectedChildGlobal != undefined ? selectedChildGlobal.planChildId : 0
				}
				selectedChildDisplay={
					selectedChildGlobal != undefined
						? selectedChildGlobal.firstName
						: "N/A"
				}
				isActivityWithTime={isActivityWithTime}
				currentYearId={
					selectedChildGlobal != undefined ? selectedChildGlobal.yearId : 0
				}
			/>

			<YearPlanActivitiesSelector
				setSelectedActivity={setSelectedActivity}
				setActivitiesList={() => {
					return;
				}}
				openSelector={openActivitySelector}
				setOpenSelector={setOpenActivitySelector}
			/>

			<ErrorPopUp
				open={errorPopUpIsOpen}
				onClose={() => setErrorPopUpIsOpen(false)}
				errorMessage={errorMessage}
			/>
		</>
	);
}
