import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as IoIcons from 'react-icons/io'
import { UserProfileIcon_Home } from '../../UserProfile/UserProfile'
import { LogOutLink_Home } from '../../../Components/LogOutLink/LogOutLink'
import { GetPrompts } from '../../../Components/Prompts/PromptsComponent'

export function MentorshipSideBar() {
  const navigate = useNavigate()
  const GotoModule = (module) => {
    navigate(module)
  }
  return (
    <>
      <div className="home-page-sidebar-container-year-plan">
        <div className="home-page-sidebar-upper">
          <div className="home-page-sidebar-item">
            <div className="home-page-sidebar-item-icon">
              <IoIcons.IoMdCalendar />
            </div>
            <div
              className="home-page-sidebar-item-text sidebar-menu-item"
              onClick={() => {
                GotoModule("/WeeklyScheduleOrdered")
              }}
            >
              {GetPrompts("Sidebar Schedule")}
            </div>
          </div>

          <div className="home-page-sidebar-item">
            <div className="home-page-sidebar-item-icon">
              <IoIcons.IoMdCalendar />
            </div>
            <div
              className="home-page-sidebar-item-text sidebar-menu-item"
              onClick={() => {
                GotoModule("/WeeklySchedule")
              }}
            >
              {GetPrompts("Sidebar Schedule With Time")}
            </div>
          </div>

          <div className="home-page-sidebar-item">
            <div className="home-page-sidebar-item-icon">
              <IoIcons.IoMdList />
            </div>
            <div
              className="home-page-sidebar-item-text sidebar-menu-item"
              onClick={() => {
                GotoModule("/PlanPerDayCustomReport")
              }}
            >
              {GetPrompts("Sidebar Activities Report")}
            </div>
          </div>

          {/* <div className="home-page-sidebar-item">
            <div className="home-page-sidebar-item-icon">
              <IoIcons.IoMdCalendar />
            </div>
            <div
              className="home-page-sidebar-item-text sidebar-menu-item"
              onClick={() => {
                GotoModule("/Planner")
              }}
            >
              {GetPrompts("Sidebar Monthly Calendar")}
            </div>
          </div> */}
        </div>

        <div className="home-page-sidebar-lower-year-plan">
          <UserProfileIcon_Home />
          <LogOutLink_Home />
        </div>
      </div>
    </>
  )
}
