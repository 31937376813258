export const MODAL_STYLES = {
	position: "fixed",
	width: "430px",
	height: "200px",
	top: "55%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	backgroundColor: "#FFF",
	zIndex: 1001,
};

export const OVERLAY_STYLES = {
	position: "fixed",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	backgroundColor: "rgba(0, 0, 0, .7)",
	zIndex: 1001,
};
