import axios from "../../axios";
export async function GetPlaylist({ playlistId = 0 }) {
  const url = "Video/api/v1/GetPlaylist"
  try {
    const response = await axios.get(url, {
      params: {
        PlaylistId: playlistId,
      },
    });
    return response.data.result;
  } catch (error) {}
}
