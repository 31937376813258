import customAxios from "../../../../axios";

export async function DeletePlanPeriod({ periodId }) {
	try {
		const url = `PlannerCalendarPeriod/api/v1/DeletePlanPeriod`;

		const response = await customAxios.post(url, {
			UserId: 0,
			PlanPeriodId: periodId,
		});
		return response.data;
	} catch (error) {}
}
