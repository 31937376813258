import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import Spinner from "react-spinkit";
import { Context } from "../../Context/Contexts";
import YesNoConfirm from "../ModalConfirm/YesNoConfirm";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../Prompts/PromptsComponent";
import { SPINNER_STYLES_NOTEXT } from "../Spinner/SpinnerStyles";
import "./ActivityCard_Edit.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./ActivityCard_Edit_ModalStyle";
import { CreateActivity } from "./CreateActivity";
import { DeleteActivity } from "./DeleteActivity";
import { GetActivitiesDropdowns } from "./GetActivitiesDropdowns";
import { UpdateActivity } from "./UpdateActivity";
export default function ActivityCard_Edit({
	open,
	activity,
	onClose,
	getActivities,
	setUserId,
}) {
	const [dlgOpen, setDlgOpen] = useState(false);
	const { isloading, setIsloading } = useContext(Context);
	const { setShowIsloading } = useContext(Context);
	const userId = activity.userId;
	const [isNew, setIsNew] = useState(false);

	const [schoolSubjectList, setSchoolSubjectList] = useState([]);

	const [schoolSubjectId, setSchoolSubjectId] = useState();
	const [schoolSubject, setSchoolSubject] = useState();

	const [activityDescription, setActivityDescription] = useState();
	const [activityId, setActivityId] = useState();

	const FeedEditionVariables = async (e) => {
		if (open) {
			if (activity.description == "") {
				setIsNew(true);
			} else {
				setIsNew(false);
			}

			setUserId(activity.userId);
			setSchoolSubjectId(activity.schoolSubject.schoolSubjectId);
			setSchoolSubject(activity.schoolSubject.description);

			setActivityId(activity.activityId);
			setActivityDescription(activity.description);
		}
	};

	useEffect(() => {
		GetActivitiesDropdowns().then(function (response) {
			setSchoolSubjectList(response.schoolSubjects);
		});

		FeedEditionVariables();
	}, [open]);

	const SchoolSubjectEditionHandler = async (e) => {
		setSchoolSubjectId(e);
	};

	const CreateButtonClickHandler = async () => {
		setShowIsloading(true);
		await CreateActivity(activityDescription, schoolSubjectId);
		getActivities();
		onClose();
		setShowIsloading(false);
	};

	const SaveButtonClickHandler = async () => {
		setShowIsloading(true);
		await UpdateActivity(userId, activityId, schoolSubjectId);
		getActivities();
		onClose();
		setShowIsloading(false);
	};

	const DeleteButtonClickHandler = async () => {
		setShowIsloading(true);
		await DeleteActivity(userId, activityId);
		getActivities();
		onClose();
		setDlgOpen(false);
		setShowIsloading(false);
	};

	if (!open) return null;
	return (
		<article key={activityId}>
			<div style={OVERLAY_STYLES}></div>

			<div
				className="edit-activity-card"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				{isNew ? (
					<>
						<div className="edit-activity-label edit-activity-activity">
							Actividad:
						</div>
						<input
							type="text"
							className="edit-new-activity-title"
							placeholder={activityDescription}
							onChange={(e) => setActivityDescription(e.target.value)}
						/>
					</>
				) : (
					<div className="edit-activity-title">{activityDescription}</div>
				)}

				<div className="edit-activity-label edit-activity-subject">Grupo:</div>

				<select
					className="edit-subject-content"
					placeholder={schoolSubject}
					onChange={(e) => SchoolSubjectEditionHandler(e.target.value)}
				>
					<option
						key={`schoolSubjectItem${activity.schoolSubject.schoolSubjectId}`}
						defaultValue={activity.schoolSubject.description}
						value={activity.schoolSubject.schoolSubjectId}
					>
						{activity.schoolSubject.description}
					</option>

					{schoolSubjectList.map((schoolSubjectItem) => {
						if (schoolSubjectItem.description !== schoolSubject) {
							return (
								<option
									key={`schoolSubjectItem${schoolSubjectItem.schoolSubjectId}`}
									value={schoolSubjectItem.schoolSubjectId}
								>
									{schoolSubjectItem.description}
								</option>
							);
						}
					})}
				</select>

				<button
					className="basic-edition-btn edit-activity-close-btn"
					onClick={onClose}
				>
					Cerrar
				</button>

				{!isNew ? (
					<>
						<button
							className="basic-edition-btn edit-activity-save-btn"
							onClick={SaveButtonClickHandler}
						>
							Guardar
						</button>

						<FontAwesomeIcon
							icon={faTrash}
							className="edit-activity-delete-btn"
							onClick={() => setDlgOpen(true)}
						/>
					</>
				) : (
					<>
						<button
							className="basic-edition-btn edit-activity-create-btn"
							onClick={CreateButtonClickHandler}
						>
							Crear
						</button>
					</>
				)}

				{isloading ? (
					<div style={SPINNER_STYLES_NOTEXT}>
						<Spinner />
					</div>
				) : (
					""
				)}

				<YesNoConfirm
					dlgOpen={dlgOpen}
					callBackFunction={DeleteButtonClickHandler}
					onClose={() => setDlgOpen(false)}
					confirmMessage={GetPrompts("Delete Confirmation Text")}
				/>
			</div>
		</article>
	);
}
