import Moment from "moment";
import "./PlannedActivityBriefCard.css";

export default function PlannedActivityBriefCard({
  plannedActivity,
  setIsOpen,
}) {
  const comments = plannedActivity.comments;
  const child = plannedActivity.planerChild.firstName;

  const description = plannedActivity.activity.description;
  const plannedDate = Moment(plannedActivity.date).format("DD/MM/YYYY");
  const fromTime = Moment(plannedActivity.fromTime).format("h:mm a");
  const toTime = Moment(plannedActivity.toTime).format("h:mm a");

  const EditionClickHandler = () => {
    setIsOpen(plannedActivity);
  };

  const GetGroupedChildren = () => {
    var grouped = plannedActivity.grouped

    if (grouped != null) {
      if (grouped.length > 1) {
        return (
          <>
            <div>
              {grouped.map((activity) => {
                return (
                  <div
                    key={`grouped-child-${activity.planerChild.planChildId}`}
                    className="table-row-header"
                  >
                    {activity.planerChild.firstName}
                  </div>
                )
              })}
            </div>
          </>
        )
      } else {
        return <>{child}</>
      }
    } else {
      return <>{child}</>
    }
  }

  return (
    <article key={plannedActivity.plannerId}>
      <div className="planned-activity-brief-card-container">
        <div className="planned-activity-brief-card-title base-sub-title">
          {GetGroupedChildren()}
        </div>

        <div className="planned-activity-brief-card-title base-parafraph">
          {comments}
        </div>

        <div
          className="green-btn-hover planned-activity-brief-card-edit-btn"
          onClick={EditionClickHandler}
        >
          Editar
        </div>
      </div>
    </article>
  )
}
