import { useContext, useEffect } from "react";
import ProductList from "../../Components/Product/ProductList";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Context } from "../../Context/Contexts";
import "./Products.css";

const Products = () => {
  const { setShowFooter } = useContext(Context);
  const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);

  useEffect(() => {
    setShowFooter(false);
    setShowNavbarItems(false);
    setShowSidebarToggle(true);
  });

  return (
    <>
      <Sidebar />
      <ProductList />
    </>
  );
};

export default Products;
