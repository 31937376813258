import customAxios from "../../axios";

export const ImpersonateUser = async ({ userName, userId }) => {
	try {
		const url = `UserAuth/api/v1/ImpersonateUser`;

		const response = await customAxios.post(url, {
			UserId: 0,
			UserIdToImpersonate: userId,
			UserNameToImpersonate: userName,
		});
		return response.data;
	} catch (error) {}
};
