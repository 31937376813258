export const EmptyChild = {
  userId: 0,
  planChildId: 0,
  firstName: '',
  lastName: '',
  identification: '',
  dateOfBirth: '',
  planYear: {
    yearId: 0,
  },
}
