import React from "react";
import ProductHome from "../../Components/Product/ProductHome/ProductHome";
import "./HomePageProducts.css";

export default function HomePageProducts() {
	return (
		<>
			<div className="home-page-products-container">
				<ProductHome />
			</div>
		</>
	);
}
