import customAxios from "../../../../axios";

export async function GetDateBasedPlanReport({
  currentPage = 1,
  itemsPerPage = 10,
  fromDate,
  toDate,
  childId = 0,
}) {
  const url = "PlannerReports/api/v1/GetDateBasedPlanReport"
  try {
    const response = await customAxios.get(url, {
      params: {
        CurrentPage: currentPage,
        ItemsPerPage: itemsPerPage,
        UserId: 0,
        FromDate: fromDate,
        ToDate: toDate,
        ChildId: childId,
      },
    })
    return response.data;
  } catch (error) {}
}
