export default function ActivityCard({
  activity,
  setActivityInEdition,
  setIsOpen,
}) {
  const schoolSubject = activity.schoolSubject.description;
  const { activityId, description } = activity;

  const EditionClickHandler = () => {
    setActivityInEdition(activity);
    setIsOpen(true);
  };

  return (
    <article key={activityId}>
      <div className="activity-card">
        <div className="activity-title">{description}</div>
        <div className="activity-label activity-subject">Grupo:</div>

        <div className="subject-content">{schoolSubject}</div>
        <div className="activity-edit-btn" onClick={EditionClickHandler}>
          Editar
        </div>
      </div>
    </article>
  );
}
