import React, { useState } from 'react'
import { GetPrompts } from '../../Components/Prompts/PromptsComponent'
import { GetProductPicture } from '../../Components/Product/ProductsPictures/ProductsPictures'
import './MentorshipCard.css'
import * as IoIcons from 'react-icons/io'
import MentorshipAvailableSlots from './MentorshipAvailableSlots'
export default function MentorshipCard({
  pictureId,
  titleText,
  descriptionText,
}) {
  const [showAvailableSlots, setShowAvailableSlots] = useState(false)
  return (
    <>
      <div className="mentorship-card-container">
        <div className="mentorship-card-picture">
          <img src={GetProductPicture(pictureId)} alt="" />
        </div>
        <div className="mentorship-card-description">
          <p className="mentorship-card-description-title base-sub-title">
            {GetPrompts(titleText)}
          </p>
          <p className="mentorship-card-description-desc base-parafraph">
            {GetPrompts(descriptionText)}{" "}
          </p>
          <div className="mentorship-card-add-btn-container">
            <div
              className="mentorship-card-add-btn"
              onClick={() => {
                setShowAvailableSlots(true)
              }}
            >
              {" "}
              <span className="base-button-text">
                Aparta esta mentoria aqui
              </span>
            </div>
          </div>
        </div>
      </div>
      <MentorshipAvailableSlots
        isOpen={showAvailableSlots}
        close={() => {
          setShowAvailableSlots(false)
        }}
        defaultMessage={GetPrompts(titleText)}
      />
    </>
  )
}
