import React, { useContext, useEffect, useState } from 'react'
import './MentorShipAppointments.css'
import { GetMentorshipAppointments } from './GetMentorshipAppointments'
import MentorshipAppointmentCard from './MentorshipAppointmentCard'
import { useRef } from 'react'
import { GetStyleToAdjustHeight_Alt } from '../../Components/Helpers/GetStyleToAdjustHeight'
import { Context } from '../../Context/Contexts'

export default function MentorShipAppointments() {
  const [appointmentsList, setAppointmentsList] = useState([])
  const { updateHeight, setUpdateHeight } = useContext(Context)
  const {
    updateMentorshipAppointmentsList,
    setUpdateMentorshipAppointmentsList,
  } = useContext(Context)

  const GetAppointments = () => {
    GetMentorshipAppointments().then((response) => {

      setAppointmentsList(response)
      setUpdateHeight(true)
    })
  }

  const boxRef = useRef()
  const { STYLES } = GetStyleToAdjustHeight_Alt(boxRef)

  useEffect(() => {
    GetAppointments()
  }, [0])

  useEffect(() => {
    if (updateMentorshipAppointmentsList == true) {
      setUpdateMentorshipAppointmentsList(false)
      GetAppointments()
    }
  }, [updateMentorshipAppointmentsList])

  return (
    <>
      <div className="mentorship-appointments-container">
        <div className="mentorship-appointments-title base-title">
          Notificationes{" "}
        </div>

        <div
          className="mentorship-appointments-table"
          ref={boxRef}
          style={STYLES}
        >
          {appointmentsList.length > 0
            ? appointmentsList.map((appointment) => {
                return <MentorshipAppointmentCard appointment={appointment} />
              })
            : ""}
        </div>
      </div>
    </>
  )
}
