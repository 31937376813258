import { useContext, useEffect } from "react";
import ActivitiesList from "../../Components/Activity/ActivitiesList";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Context } from "../../Context/Contexts";

import "./Activities.css";

export default function Activities() {
  const { setShowFooter } = useContext(Context);

  const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);
  useEffect(() => {
    setShowFooter(false);
    setShowNavbarItems(false);
    setShowSidebarToggle(true);
  });

  return (
    <>
      <Sidebar />
      <div className="basic-page-title">Mis Activitdades</div>
      <ActivitiesList />
    </>
  );
}
