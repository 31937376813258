import Moment from "moment";
import React, { useEffect, useState } from "react";
import { RESPONSIVE_MODAL_STYLES } from "../../../Modal_Styles/ResponsiveModalStyle";
import EditPlannedActivity from "../../EditPlannedActivity/EditPlannedActivity";
import { GetPlannedActivityPlan } from "./GetPlannedActivityPlan";
import "./PlannedActivityPlan.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./PlannedActivityPlan_ModalStyle";
export default function PlannedActivityPlan({ activity, dlgOpen, onclose }) {
	const [showEditPlannedActivity, setShowEditPlannedActivity] = useState(false);
	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState(new Date());
	const [activityList, setActivityList] = useState([]);
	const [activityInEdition, setActivityInEdition] = useState("");

	const GetPlan = () => {
		var activityId = activity.activityId;
		var from = Moment(fromDate).day(-3).format("YYYY-MM-DDTHH:mm:ss");
		var to = Moment(toDate).day(3).format("YYYY-MM-DDTHH:mm:ss");

		GetPlannedActivityPlan({
			activityId,
			fromDate: from,
			toDate: to,
		}).then((response) => {
			setActivityList(response);
		});
	};

	const ActivityEdition = (activity) => {
		setActivityInEdition(activity);
		setShowEditPlannedActivity(true);
	};

	useEffect(() => {
		GetPlan();
	}, [activity.activityId]);

	if (dlgOpen == false) {
		return;
	}

	return (
		<>
			<div style={OVERLAY_STYLES} onClick={onclose}></div>

			<div
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
				className="planned-activity-plan-container"
			>
				<div className="planned-activity-plan-close-btn" onClick={onclose}>
					X
				</div>

				{activityList.map((activity) => {
					return (
						<div className="planned-activity-plan-card">
							<div className="planned-activity-plan-card-date">
								{Moment(activity.date).format("MMM-DD-YYYY")}{" "}
								<span className="planned-activity-plan-card-time">
									{Moment(activity.fromTime).format("hh:mm a")}
								</span>
							</div>
							<div className="planned-activity-plan-card-comment">
								{activity.comments}
							</div>
							<div className="planned-activity-plan-card-name">
								{activity.planerChild.firstName}
							</div>
							<div className="planned-activity-plan-card-btn-container">
								<div
									className="planned-activity-plan-card-btn-edit"
									onClick={() => {
										ActivityEdition(activity);
									}}
								>
									Editar
								</div>
							</div>
						</div>
					);
				})}
			</div>

			{showEditPlannedActivity ? (
				<EditPlannedActivity
					open={showEditPlannedActivity}
					plannedActivity={activityInEdition}
					onClose={() => setShowEditPlannedActivity(false)}
					setUserId={() => {}}
				/>
			) : (
				""
			)}
		</>
	);
}
