import axios from "../../../../../axios";
export const DeleteNewYearActivityNote = async (noteId) => {
  try {
    const url = `PlannerYearActivitiesNotes/api/v1/DeleteYearActivityNote`

    const response = await axios.post(url, {
      UserId: 0,
      YearActivityNoteId: noteId,
    });
  } catch (error) {}
};
