import axios from "../../../axios";
export async function GetOverallPlannedReport({
  currentPage = 1,
  itemsPerPage = 10,
  fromDate,
  toDate,
}) {
  const url = "PlannerReports/api/v1/GetOverallPlannedReport"
  try {
    const response = await axios.get(url, {
      params: {
        CurrentPage: currentPage,
        ItemsPerPage: itemsPerPage,
        UserId: 0,
        FromDate: fromDate,
        ToDate: toDate,
      },
    });
    return response.data;
  } catch (error) {}
}
