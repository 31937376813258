import axios from "../../axios";
export async function GetChildrenAll() {
	const url = "Child/api/v1/GetAllChildren";
	try {
		const response = await axios.get(url, {
			params: {
				UserId: 0,
			},
		});
		return response.data;
	} catch (error) {}
}
