import { useContext, useEffect, useState } from "react";
import "./WeeklyScheduleDatatableAll.css";
import Moment from "moment";
import { Context } from "../../../../Context/Contexts";

export function WeeklyScheduleDayHeader({ dayText, currentDate }) {
	const [smallScreenSchedule, setSmallScreenSchedule] = useState(false);
	const { showNewPlannedActivity, setShowNewPlannedActivity } =
		useContext(Context);
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		if (windowSize.width > 820) {
			setSmallScreenSchedule(false);
		} else {
			setSmallScreenSchedule(true);
		}

		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, [windowSize.width]);

	var isWeekend = false;

	dayText = dayText.substring(0, 3);

	if (Moment(currentDate).day() == 0 || Moment(currentDate).day() == 6) {
		isWeekend = true;
	}

	return (
		<>
			{smallScreenSchedule ? (
				<>
					<div
						className="weekschedule-day-header-container"
						onClick={() => {
							setShowNewPlannedActivity(true);
						}}
					>
						<div className="weekschedule-day-header">
							{dayText.substring(0, 1)}{" "}
						</div>
						<div className="weekschedule-day-header-day">
							{Moment(currentDate).format("DD")}
						</div>
					</div>
				</>
			) : (
				<>
					<div className="weekschedule-day-header-container">
						<div
							className={
								isWeekend
									? "weekschedule-day-header-weekend"
									: "weekschedule-day-header"
							}
						>
							{dayText}{" "}
						</div>
						<div className="weekschedule-day-header-day">
							<div className="weekschedule-day-header-day-number-container">
								<div className="weekschedule-day-header-day-number">
									<div className="weekschedule-day-header-day-number-text">
										{Moment(currentDate).format("DD")}
									</div>
								</div>
							</div>

							<div className="weekschedule-day-header-day-monthyear">
								{Moment(currentDate).format("MMM-YYYY")}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
