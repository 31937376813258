import React, { useEffect, useState } from "react"
import "./DoneComponent.css"
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle"
import logo from "../../Assets/Logo.svg"
import { MODAL_STYLES, OVERLAY_STYLES } from "./DoneComponent_ModalStyles"
import * as IoIcons from "react-icons/io"
import { useContext } from "react"
import { Context } from "../../Context/Contexts"
import { css } from "aphrodite"
import { animationStyles } from "../Animations/Styles"

export default function DoneComponent() {
  var secondsToClose = 2
  const [isMounted, setIsMounted] = useState(false)
  const { showDonecomponent, setShowDonecomponent } = useContext(Context)
  const [logoFadeIn, setLogoFadeIn] = useState(false)
  const [checkFadeIn, setCheckFadeIn] = useState(false)

  useEffect(() => {
    if (showDonecomponent) {
      setTimeout(() => {
        setLogoFadeIn(true)
      }, 400)

      setTimeout(() => {
        setCheckFadeIn(true)
      }, 800)

      setTimeout(() => {
        setShowDonecomponent(false)
      }, secondsToClose * 1000)
    }
  }, [showDonecomponent])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!showDonecomponent) return

  return (
    <>
      <div
        style={OVERLAY_STYLES}
        onClick={() => {
          setShowDonecomponent(false)
        }}
      ></div>

      <div
        className="done-component-container"
        style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
      >
        <div
          className={
            css(isMounted ? animationStyles.bounce : null) +
            ` done-component-circle-grid done-component-circle ${
              logoFadeIn ? "done-component-fade-in" : "done-component-fade-out"
            }`
          }
        >
          <img
            src={logo}
            className="done-component-logo"
            alt="done-component-logo"
          />
          <span
            className={`done-component-check ${
              checkFadeIn ? "done-component-fade-in" : "done-component-fade-out"
            }`}
          >
            <IoIcons.IoMdCheckmark />
          </span>
        </div>
      </div>
    </>
  )
}
