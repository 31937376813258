import React, { useState } from "react";
import Spinner from "react-spinkit";
import ErrorPopUp from "../Errors/ErrorPopUp";
import CustomConfirmation from "../ModalConfirm/CustomConfirmation";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import { SPINNER_STYLES_ORANGE } from "../Spinner/SpinnerStyles";
import "./ForgotPwd.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./ForgotPwd_ModalStyle";
import { SendPwdResetMsg } from "./SendPwdResetMsg";

export default function ForgotPwd({ open, onClose }) {
  const [username, setUsername] = useState("");
  const [showPwdResetConf, setShowPwdResetConf] = useState(false);
  const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
  const [isloading, setIsloading] = useState(false);

  const SendResetMessage = (e) => {
    e.preventDefault();
    SendPwdResetMsg({ username }).then((response) => {
      setShowPwdResetConf(true);
      return;
    });
  };

  const CloseConfirmationPopUp = () => {
    setShowPwdResetConf(false);
    onClose();
  };

  if (!open) return null;
  return (
    <>
      <div style={OVERLAY_STYLES} onClick={onClose}></div>

      <div style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}>
        <div className="container forgot-pwd-container">
          <div className="forgot-pwd-close-btn" onClick={onClose}>
            X
          </div>

          <form className="forgot-pwd-form">
            <div className="forgot-pwd-form-item">
              <label className="forgot-pwd-label">Tu email registrado:</label>

              <input
                className="forgot-pwd-input"
                type="text"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="forgot-pwd-send-btn-container">
              <button
                className="forgot-pwd-send-btn"
                onClick={SendResetMessage}
              >
                <label className="forgot-pwd-send-btn-text">
                  Enviar para restablecer
                </label>
              </button>
            </div>
          </form>
        </div>
      </div>

      <CustomConfirmation
        dlgOpen={showPwdResetConf}
        onClose={CloseConfirmationPopUp}
        confirmMessage={"Listo, por favor revisa tu correo registrado"}
      />

      <ErrorPopUp
        open={errorPopUpIsOpen}
        onClose={() => setErrorPopUpIsOpen(false)}
        errorMessage={
          "mmm, Parece que no pudimos enviar el mensaje para restablecer, " +
          "permitenos trabajar en resolver esto"
        }
      />

      <div>
        {isloading ? (
          <div style={SPINNER_STYLES_ORANGE}>
            <Spinner />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
