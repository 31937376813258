import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Languages.css";

export default function SpanishLanguage() {
  const ClickHandler = () => {
    sessionStorage.setItem("_lang", "Spanish")
  }

  return (
    <>
      <div
        className="lang-link-icon"
        onClick={() => {
          ClickHandler()
        }}
      >
        <FontAwesomeIcon icon={faLanguage} />{" "}
        <span className="sidebar-menu-item">Español</span>
      </div>
    </>
  )
}

export function EnglishLanguage() {
  const ClickHandler = () => {
    sessionStorage.setItem("_lang", "English")
  }

  return (
    <>
      <div
        className="lang-link-icon"
        onClick={() => {
          ClickHandler()
        }}
      >
        <FontAwesomeIcon icon={faLanguage} />
        <span className="sidebar-menu-item">English</span>
      </div>
    </>
  )
}
