import React, { useContext, useState } from "react";
import * as IoIcons from "react-icons/io";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { GetYearActivitiesTable_Alt } from "./Datatable/GetYearActivitiesTable";
import "./YearPlanner.css";
import { YearSelectorGrid } from "./YearSelector/YearSelectorGrid";
import { useEffect } from "react";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import { ErrorBoundary_PlannerYearStudents } from "../../ErrorBoundary/Settings/ErrorBoundary_PlannerYearStudents";
import { ErrorBoundary_PlannerYearActivities } from "../../ErrorBoundary/Settings/ErrorBoundary_PlannerYearActivities";
import { Context } from "../../../Context/Contexts";
export default function YearPlanner() {
	const [selectedChild, setSelectedChild] = useState("");
	const [selectedYear, setSelectedYear] = useState(0);
	const { selectedChildGlobal } = useContext(Context);
	const { setShowSelectAllChildrenOption } = useContext(Context);
	const { setShowChildrenSelectorPopUp } = useContext(Context);

	useEffect(() => {
		if (selectedChild != undefined && selectedChild != "") {
			setSelectedYear(
				selectedChildGlobal != undefined ? selectedChildGlobal.yearId : 0
			);
		}
	}, [selectedChild]);
	return (
		<>
			<div className="year-planner-container">
				<div className="year-planner-upper-container">
					<div className="year-planner-upper-form-row">
						<div className="year-planner-upper-form-label year-planner-upper-select-student-label base-title-normal-height">
							Escoge el estudiante
						</div>
						<div
							id="year-planner-upper-children-btn"
							className="year-planner-upper-form-label-input hover-brighten"
							onClick={() => {
								setShowSelectAllChildrenOption(false);
								setShowChildrenSelectorPopUp(true);
							}}
						>
							<div className="year-planner-upper-children-btn">
								<IoIcons.IoMdPeople />
							</div>
						</div>
						<dir className={"year-planner-upper-select-student"}>
							{selectedChildGlobal != undefined ? (
								<div className="year-planner-upper-select-student-selected">
									Estudiante Seleccionado: {selectedChildGlobal.firstName}{" "}
									{selectedChildGlobal.lastName}
								</div>
							) : (
								""
							)}
						</dir>
					</div>
				</div>
				<div className="year-planner-lower-container">
					<PerfectScrollbar className="year-planner-lower-activities">
						<ErrorBoundary
							errorMessage={ErrorBoundary_PlannerYearActivities.errorMessage}
						>
							<GetYearActivitiesTable_Alt
								selectedYear={
									selectedChildGlobal != undefined
										? selectedChildGlobal.yearId
										: 0
								}
								selectedChild={
									selectedChildGlobal != undefined ? selectedChildGlobal : ""
								}
								setSelectedYear={setSelectedYear}
							/>
						</ErrorBoundary>
					</PerfectScrollbar>
				</div>
			</div>
		</>
	);
}
