import DataTable from "react-data-table-component";
export function GetActivitiesTable({
  activitiesList,
  setActivityInEdition,
  setIsOpen,
}) {
  const columns = [
    {
      name: "Descripcion",
      selector: (row) => row.description,
      sortable: true,
      width: "300px",
    },
    {
      name: "Asignatura",
      selector: (row) => `${row.schoolSubject.description}`,
      sortable: true,
      width: "200px",
    },
    {
      name: "",
      cell: (row) => (
        <ActionComponent row={row} onClick={ActionComponent}>
          Action
        </ActionComponent>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        borderRadius: "20px",
      },
    },
    headCells: {
      style: {
        fontSize: "24px",
        backgroundColor: "#ffc04d",
      },
    },
    cells: {
      style: {
        fontSize: "18px",
      },
    },
  };

  const ActionComponent = ({ row, onClick }) => {
    const clickHandler = () => {
      onClick(row);
      setActivityInEdition(row);
      setIsOpen(true);
    };

    return (
      <div className="activity-edit-btn" onClick={clickHandler}>
        Editar
      </div>
    );
  };

  {
    return (
      <>
        <div className="container activities-datatable">
          <DataTable
            columns={columns}
            data={activitiesList}
            fixedHeader
            fixedHeaderScrollHeight="500px"
            customStyles={customStyles}
            responsive={true}
          />
        </div>
      </>
    );
  }
}
