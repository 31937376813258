import { faFilter, faPlus, faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Pagination from "../Pagination/Pagination";
import { GetSchoolSubjectsTable } from "./Datatable/GetSchoolSubjectsTable";
import { EmptySchoolSubject } from "./EmptySchoolSubject";
import { GetFilteredSchoolSubjects } from "./GetFilteredSchoolSubjects";
import { GetSchoolSubjects } from "./GetSchoolSubjects";
import SchoolSubjectCard from "./SchoolSubjectCard";
import SchoolSubjectCard_Edit from "./SchoolSubjectCard_Edit";

export default function SchoolSubjectsList() {
  const [userId, setUserId] = useState("");
  const [showDatatable, setShowDatatable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, SetTotalPages] = useState(0);

  const [showFilter, setShowFilter] = useState(false);
  const [filterBySchoolSubject, setFilterBySchoolSubject] = useState("");
  const [filterByFrequency, setfilterByFrequency] = useState("");
  const [filterByActivity, setFilterByActivity] = useState("");

  const [schoolSubjectInEdition, setSchoolSubjectInEdition] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [schoolSubjectsList, setSchoolSubjectsList] = useState([]);

  const NewSchoolSubjectClickHandler = () => {
    const emptySchoolSubject = EmptySchoolSubject;
    emptySchoolSubject.userId = userId;

    setSchoolSubjectInEdition(emptySchoolSubject);
    setIsOpen(true);
  };

  useEffect(() => {
    if (
      filterBySchoolSubject != "" ||
      filterByFrequency != "" ||
      filterByActivity != ""
    ) {
      FeedSchoolSubjectsFilteredList();
    } else {
      FeedSchoolSubjectsList();
    }
  }, [currentPage]);

  const FeedSchoolSubjectsList = () => {
    GetSchoolSubjects({ currentPage, itemsPerPage }).then(function (response) {
      setCurrentPage(response.currentPage);
      SetTotalPages(response.pages);

      setSchoolSubjectsList(response.result);
    });
  };
  const FeedSchoolSubjectsFilteredList = () => {
    GetFilteredSchoolSubjects({
      currentPage,
      itemsPerPage,
      filterBySchoolSubject,
    }).then(function (response) {
      setCurrentPage(response.currentPage);
      SetTotalPages(response.pages);

      setSchoolSubjectsList(response.result);
    });
  };

  function GetListCards() {
    {
      return schoolSubjectsList.map((schoolSubject) => {
        return SchoolSubjectCard({
          schoolSubject,
          setSchoolSubjectInEdition,
          setIsOpen,
        });
      });
    }
  }
  return (
    <>
      <div className="container">
        <div className="page-menu-container">
          <div
            className="show-filter-button-btn"
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            <FontAwesomeIcon icon={faFilter} />{" "}
            {showFilter ? "Ocultar filtros" : "Mostrar filtros"}
          </div>

          <div
            className="add-button-btn"
            onClick={NewSchoolSubjectClickHandler}
          >
            <FontAwesomeIcon icon={faPlus} /> Crear Asignatura
          </div>
        </div>

        {showFilter ? (
          <>
            <div className="schoolSubjects-filters-container">
              <div
                className="filter-button-btn"
                onClick={FeedSchoolSubjectsFilteredList}
              >
                <FontAwesomeIcon icon={faFilter} /> Filtrar
              </div>

              <div className="filter-assignment">
                Asignatura:{" "}
                <input
                  type="text"
                  className="filter-input"
                  onChange={(e) => setFilterBySchoolSubject(e.target.value)}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div
          className="change-view-btn"
          onClick={() => {
            setShowDatatable(!showDatatable);
          }}
        >
          <FontAwesomeIcon icon={faTable} />{" "}
          {showDatatable ? "Ver Iconos" : "Ver Tabla"}
        </div>

        <div
          className={
            showDatatable
              ? "container activities-container-datatable"
              : "container activities-container"
          }
        >
          {schoolSubjectsList ? (
            <>
              {showDatatable ? (
                <>
                  {GetSchoolSubjectsTable({
                    schoolSubjectsList,
                    setSchoolSubjectInEdition,
                    setIsOpen,
                  })}
                </>
              ) : (
                <>{GetListCards()}</>
              )}
            </>
          ) : (
            <></>
          )}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <SchoolSubjectCard_Edit
        open={isOpen}
        schoolSubject={schoolSubjectInEdition}
        onClose={() => setIsOpen(false)}
        getSchoolSubjects={FeedSchoolSubjectsList}
        setUserId={setUserId}
      />
    </>
  );
}
