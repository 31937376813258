import axios from "../../axios";
export async function GetCartSummary() {
  const url = "Cart/api/v1/GetUserCartSummary"
  try {
    const response = await axios.get(url, {
      params: {
        UserId: 0,
      },
    });
    return response.data.result;
  } catch (error) {}
}
