import axios from "../../axios";
export async function GetFilteredActivities({
  currentPage,
  itemsPerPage,
  filterBySchoolSubject,
  filterByActivity,
}) {
  try {
    const filteredUrl = "Activity/api/v1/GetFilteredActivitiesPaged"

    const response = await axios.post(filteredUrl, {
      CurrentPage: currentPage,
      ItemsPerPage: itemsPerPage,
      SchoolSubject: filterBySchoolSubject,
      Activity: filterByActivity,
    });

    return response.data;
  } catch (error) {}
}
