import React, { useState } from "react";
import ErrorPopUp from "../../../../Errors/ErrorPopUp";
import { RESPONSIVE_MODAL_STYLES } from "../../../../Modal_Styles/ResponsiveModalStyle";
import { CreateNewYearActivityNote } from "./CreateNewYearActivityNote";
import "./NewYearActivityNoteCard.css";
import {
  MODAL_STYLES,
  OVERLAY_STYLES,
} from "./NewYearActivityNoteCard_ModalStyle";

export default function NewYearActivityNoteCard({
  open,
  onClose,
  activityId,
  GetNotes,
}) {
  const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [note, setNote] = useState("");

  const CreateClickHandler = async () => {
    if (note == "") {
      setErrorMessage("Necesitamos el texto de la nota");
      setErrorPopUpIsOpen(true);
      return;
    }
    await CreateNewYearActivityNote(activityId, note).then((response) => {
      GetNotes();
      setNote("");
      onClose();
    });
  };

  const CloseClickHandler = () => {
    onClose();
  };

  if (!open) return null;

  return (
    <>
      <div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>
      <div
        className="new-year-activity-note-card"
        style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
      >
        <div
          className="new-year-activity-note-card-close-btn"
          onClick={CloseClickHandler}
        >
          X
        </div>

        <div className="year-activity-notes-card-ul">
          <li>
            <textarea
              autoFocus
              type="text"
              className="new-year-activity-note-card-text-area"
              placeholder={"Apunte"}
              onChange={(e) => setNote(e.target.value)}
            />
          </li>
        </div>

        <div
          className="new-year-activity-note-card-create-btn"
          onClick={CreateClickHandler}
        >
          Crear
        </div>
      </div>
      <ErrorPopUp
        open={errorPopUpIsOpen}
        onClose={() => setErrorPopUpIsOpen(false)}
        errorMessage={errorMessage}
      />
    </>
  );
}
