import customAxios from "../../../../axios";

export async function CreateSkipWeek({ calendarWeek }) {
	try {
		const url = `PlannerCalendarPeriod/api/v1/CreateSkipWeek`;

		const response = await customAxios.put(url, {
			UserId: 0,
			CalendarWeek: calendarWeek,
		});
		return response.data;
	} catch (error) {}
}
