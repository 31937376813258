import customAxios from "../../../axios";

export const MoveActivitiesOneWeekDown = async ({ calendarWeek }) => {
	try {
		const url = `Planner/api/v1/MoveActivitiesOneWeekDown`;

		const response = await customAxios.post(url, {
			UserId: 0,
			CalendarWeek: calendarWeek,
		});
		return response.data;
	} catch (error) {}
};
