import Moment from "moment";
import * as IoIcons from "react-icons/io";
import React, { useContext, useEffect, useState } from "react";
import "./PlanPerDayCustomReport.css";
import DatePickerPopUp from "../../../../DatePicker/DatePickerPopUp";
import { GetPrompts } from "../../../../Prompts/PromptsComponent";
import Sidebar from "../../../../Sidebar/Sidebar";
import { GetDateBasedPlanReport } from "../GetDateBasedPlanReport";
import { Context } from "../../../../../Context/Contexts";
import { MEMBERSHIP_UPGRADE_NEEDED } from "../../../../Constants/Constants";
import groupData from "./groupData";
import { WeekBlockHeaderData } from "./PlanPerDayCustomBlockData";
import { SafeHtmlComponent } from "../../../../RichTextEditor/SafeHtmlComponent";
import EditPlannedActivity from "../../../EditPlannedActivity/EditPlannedActivity";
import PlannedGroupedActivity from "../../../OldPlanner/PlannedGroupedActivity";

export default function PlanPerDayCustomReport() {
	var loadCount = 0;

	const { setShowMembershipNeedUpgrade } = useContext(Context);
	const { selectedChildGlobal } = useContext(Context);
	const { setShowSelectAllChildrenOption } = useContext(Context);
	const { setShowChildrenSelectorPopUp } = useContext(Context);

	const { setIsloading } = useContext(Context);
	const { setShowIsloading } = useContext(Context);
	const [daysList, setDaysList] = useState([]);
	const [fromDate, setFromDate] = useState(new Date());
	const [toDate, setToDate] = useState(new Date());

	const [showFromDatePopUp, setShowFromDatePopUp] = useState(false);
	const [showToDatePopUp, setShowToDatePopUp] = useState(false);
	const [groupBy, setGroupBy] = useState("day"); // State for grouping criteria
	const [editIsOpen, setEditIsOpen] = useState(false);
	const [editGroupIsOpen, setEditGroupIsOpen] = useState(false);
	const [plannedActivityInEdition, setPlannedActivityInEdition] = useState("");
	const [plannedGroupedActivitiesList, setPlannedGroupedActivitiesList] =
		useState([]);

	const GetReport = () => {
		var childId =
			selectedChildGlobal != undefined ? selectedChildGlobal.planChildId : 0;
		setShowIsloading(true);
		GetDateBasedPlanReport({
			currentPage: 1,
			itemsPerPage: 1000,
			fromDate: fromDate,
			toDate: toDate,
			childId: childId,
		}).then((response) => {
			setShowIsloading(false);

			if (response.errors === MEMBERSHIP_UPGRADE_NEEDED) {
				setShowMembershipNeedUpgrade(true);
				return;
			}

			setDaysList(response.result);
		});
	};

	const clickActivityHandler = (activity) => {
		if (activity.isGrouped === true) {
			setPlannedGroupedActivitiesList(activity.grouped);
			setEditGroupIsOpen(true);
		} else {
			setPlannedActivityInEdition(activity);
			setEditIsOpen(true);
		}
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	useEffect(() => {
		GetReport();
	}, [selectedChildGlobal]);

	const InitialLoad = () => {
		GetReport();
		loadCount = loadCount + 1;
	};

	const renderGroupedData = (data) => {
		if (groupBy === "day") {
			return data.map((day) => (
				<React.Fragment key={day.date}>
					{console.log(day)}
					<div className="plan-per-day-custom-report-table">
						<div className="report-table-section-title base-parafraph">
							<WeekBlockHeaderData
								periodTitle={day.periodTitle}
								periodNumber={day.periodNumber}
								weekNumber={day.weekNumber}
								weekTitle={day.weekTitle}
							/>
						</div>
						<tr className="">
							<td className="base-sub-title table-header-activity">
								Actividad
							</td>
							<td className="base-sub-title table-header-date">Dia</td>
						</tr>
						{day.activityList.map((item) => (
							<tr
								key={`${day.key}.${item.activity.activityId}.${item.plannerId}`}
								className={"plan-per-day-custom-report-table-row"}
							>
								<td
									className="plan-per-day-custom-report-table-field-activity"
									onClick={() => {
										clickActivityHandler(item);
									}}
								>
									<div className="plan-per-day-custom-report-table-field-activity-title">
										{item.activity.description}
									</div>
									<div className="plan-per-day-custom-report-table-field-activity-comment">
										{item.formattedComments !== null ? (
											<SafeHtmlComponent htmlContent={item.formattedComments} />
										) : (
											item.comments
										)}
									</div>
								</td>
								<td className="plan-per-day-custom-report-table-field-activity-date">
									{GetPrompts(`${Moment(item.date).format("dddd")} Text`)},{" "}
									{Moment(item.date).format("DD-MMM-YYYY")}
								</td>
							</tr>
						))}
					</div>
				</React.Fragment>
			));
		} else if (groupBy === "activity") {
			return data.map((activityGroup) => (
				<React.Fragment key={activityGroup.activity}>
					<div className="plan-per-day-custom-report-table">
						<div className="report-table-section-title base-parafraph">
							{activityGroup.activity}
						</div>
						<tr className="">
							<th className="base-sub-title table-header-activity">
								Actividad
							</th>
							<th className="base-sub-title table-header-date">Fecha</th>
							<th className="base-sub-title table-header-student">
								Estudiante
							</th>
						</tr>

						{activityGroup.activityList.map((item) => (
							<tr
								key={`${item.activityId}.${item.plannerId}`}
								className={"plan-per-day-custom-report-table-row"}
							>
								<td
									className="plan-per-day-custom-report-table-field-activity-comment"
									onClick={() => {
										clickActivityHandler(item);
									}}
								>
									<div className="base-parafraph">
										{item.formattedComments !== null ? (
											<SafeHtmlComponent htmlContent={item.formattedComments} />
										) : (
											item.comments
										)}
									</div>
								</td>
								<td className="plan-per-day-custom-report-table-field-activity-comment">
									{GetPrompts(`${Moment(item.date).format("dddd")} Text`)},{" "}
									{Moment(item.date).format("DD-MMM")}
								</td>
								<td className="plan-per-day-custom-report-table-field-activity-comment">
									{item.children.map((child) => (
										<div key={child.firstName}>{child.firstName}</div>
									))}
								</td>
							</tr>
						))}
					</div>
				</React.Fragment>
			));
		} else if (groupBy === "student") {
			return data.map((studentGroup) => (
				<React.Fragment key={studentGroup.student}>
					<div className="plan-per-day-custom-report-table">
						<div className="report-table-section-title base-parafraph">
							{studentGroup.student}
						</div>
						<tr className="">
							<td className="base-sub-title table-header-activity">
								Actividad
							</td>
							<td className="base-sub-title table-header-date">Fecha</td>
						</tr>

						{studentGroup.activityList.map((item) => (
							<tr
								key={`${item.activity.activityId}.${item.plannerId}`}
								className={"plan-per-day-custom-report-table-row"}
							>
								<td
									className="plan-per-day-custom-report-table-field-activity"
									onClick={() => {
										clickActivityHandler(item);
									}}
								>
									<div className="plan-per-day-custom-report-table-field-activity-title">
										{item.activity.description}
									</div>
									<div className="plan-per-day-custom-report-table-field-activity-comment">
										{item.formattedComments !== null ? (
											<SafeHtmlComponent htmlContent={item.formattedComments} />
										) : (
											item.comments
										)}
									</div>
								</td>
								<td className="plan-per-day-custom-report-table-field-activity-comment">
									{GetPrompts(`${Moment(item.date).format("dddd")} Text`)},{" "}
									{Moment(item.date).format("DD-MMM")}
								</td>
							</tr>
						))}
					</div>
				</React.Fragment>
			));
		} else if (groupBy === "week") {
			return data.map((weekGroup) => (
				<React.Fragment key={weekGroup.key}>
					{console.log(weekGroup.weekTitle)}

					<div className="plan-per-day-custom-report-table">
						<div className="report-table-section-title base-parafraph">
							<WeekBlockHeaderData
								periodTitle={weekGroup.periodTitle}
								periodNumber={weekGroup.periodNumber}
								weekNumber={weekGroup.weekNumber}
								weekTitle={weekGroup.weekTitle}
							/>
						</div>
						<tr className="">
							<td className="base-sub-title table-header-activity">
								Actividad
							</td>
							<td className="base-sub-title table-header-date">Fecha</td>
						</tr>

						{weekGroup.activityList.map((item) => (
							<tr
								key={`${weekGroup.key}.${item.activity.activityId}.${item.plannerId}`}
								className={"plan-per-day-custom-report-table-row"}
							>
								<td
									className="plan-per-day-custom-report-table-field-activity"
									onClick={() => {
										clickActivityHandler(item);
									}}
								>
									<div className="plan-per-day-custom-report-table-field-activity-title">
										{item.activity.description}
									</div>
									<div className="plan-per-day-custom-report-table-field-activity-comment">
										{item.formattedComments !== null ? (
											<SafeHtmlComponent htmlContent={item.formattedComments} />
										) : (
											item.comments
										)}
									</div>
								</td>
								<td className="plan-per-day-custom-report-table-field-activity-date">
									{GetPrompts(`${Moment(item.date).format("dddd")} Text`)},{" "}
									{Moment(item.date).format("DD-MMM-YYYY")}
								</td>
							</tr>
						))}
					</div>
				</React.Fragment>
			));
		}
	};

	return (
		<>
			<Sidebar />
			<div className="basic-page-title">{GetPrompts("Activities report")}</div>
			<div className="plan-per-day-custom-report-container">
				<div className="plan-per-day-custom-report-headers">
					<div className="plan-per-day-custom-report-date-input-container">
						<div
							className="plan-per-day-custom-report-date-input hover-brighten"
							onClick={() => {
								setShowFromDatePopUp(true);
							}}
						>
							<div className="child-edition-input-text">
								{GetPrompts("From Text")}{" "}
								{Moment(fromDate).format("DD-MMM-YYYY")}
							</div>
						</div>

						<div
							className="plan-per-day-custom-report-date-input hover-brighten"
							onClick={() => {
								setShowToDatePopUp(true);
							}}
						>
							<div className="child-edition-input-text">
								{GetPrompts("To Text")} {Moment(toDate).format("DD-MMM-YYYY")}
							</div>
						</div>
					</div>

					<div className="plan-per-day-custom-report-btn-container do-not-print">
						<div className="do-not-print plan-per-day-custom-report-group-by hover-brighten">
							<label>{GetPrompts("Group by")}:</label>
							<select
								onChange={(e) => setGroupBy(e.target.value)}
								value={groupBy}
							>
								<option value="day">{"Dia"}</option>
								<option value="activity">{"Actividad"}</option>
								<option value="student">{"Estudiante"}</option>
								<option value="week">{"Semana"}</option>
							</select>
						</div>
						<div
							className="do-not-print plan-per-day-custom-report-btn hover-brighten"
							onClick={() => {
								GetReport();
							}}
						>
							{GetPrompts("View report")}
						</div>

						<div
							className="do-not-print plan-per-day-report-print-item-btn hover-brighten"
							onClick={() => {
								window.print();
							}}
						>
							{GetPrompts("Print Text")}
						</div>

						<div
							onClick={() => {
								setShowSelectAllChildrenOption(true);
								setShowChildrenSelectorPopUp(true);
							}}
						>
							<div className="plan-per-day-custom-report-children-btn hover-brighten">
								<IoIcons.IoMdPeople />
							</div>
						</div>
					</div>
				</div>

				<div className="plan-per-day-custom-report-content">
					<table className="plan-per-day-custom-report-table">
						{renderGroupedData(groupData(daysList, groupBy))}
					</table>
				</div>
			</div>

			<DatePickerPopUp
				open={showFromDatePopUp}
				close={() => {
					setShowFromDatePopUp(false);
				}}
				onChange={setFromDate}
			/>

			<DatePickerPopUp
				open={showToDatePopUp}
				close={() => {
					setShowToDatePopUp(false);
				}}
				onChange={setToDate}
			/>

			<EditPlannedActivity
				open={editIsOpen}
				plannedActivity={plannedActivityInEdition}
				onClose={() => setEditIsOpen(false)}
				callback={() => {
					GetReport();
				}}
				setUserId={() => {
					return;
				}}
			/>

			<PlannedGroupedActivity
				open={editGroupIsOpen}
				plannedActivitiesList={plannedGroupedActivitiesList}
				onClose={() => setEditGroupIsOpen(false)}
				GetPlannedActivities={() => {
					GetReport();
				}}
			/>
		</>
	);
}
