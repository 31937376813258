import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import "./CustomConfirmation.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./CustomConfirmation_ModalStyle";
export default function CustomConfirmation({
  dlgOpen,
  onClose,
  confirmMessage,
  showContinueBtn = true,
}) {
  if (!dlgOpen) return null;
  return (
    <article>
      <div style={OVERLAY_STYLES}></div>
      <div
        className="custom-confirmation-container-card"
        style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
      >
        <div className="confirm-message">{confirmMessage}</div>
        <div className="custom-confirmation-btn-container">
          {showContinueBtn ? (
            <button className="custom-confirmation-continue" onClick={onClose}>
              continuar
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </article>
  );
}
