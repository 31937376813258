import axios from '../../axios'
export const AddMentorshipAppointment = async (slotId, message) => {
  try {
    const url = `Mentorship/api/v1/AddMentorshipAppointmentByAvailableSlot`

    const response = await axios.post(url, {
      AvailableSlotId: slotId,
      Message: message,
    })

    return response.data
  } catch (error) {}
}
