import axios from '../../axios'

export async function GetMentorshipCalendarAvailableSlots({ selectedDate }) {
  try {
    const url = `Mentorship/api/v1/GetMentorshipCalendarAvailableSlots`
    const response = await axios.get(url, {
      params: {
        SelectedDate: selectedDate,
      },
    })
    return response.data.result
  } catch (error) {}
}
