import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const INACTIVITY_TIME_LIMIT_IN_MILLISECONDS = 60 * 60 * 1000; // 60 minutes in milliseconds
const INACTIVITY_CHECK_TIME_MILLISECONDS = 10 * 60 * 1000; // 10 minutes in milliseconds

const useInactivityLogout = () => {
	const navigate = useNavigate();
	const handleLogout = () => {
		console.log("User has been logged out due to inactivity.");
		sessionStorage.removeItem("_x");
		sessionStorage.removeItem("_c");
		navigate("/landing");
	};

	const [lastActivityTime, setLastActivityTime] = useState(Date.now());

	useEffect(() => {
		const events = ["mousemove", "keydown", "click", "scroll"];

		const resetTimer = () => {
			setLastActivityTime(Date.now());
		};

		const checkInactivity = () => {
			if (
				Date.now() - lastActivityTime >=
				INACTIVITY_TIME_LIMIT_IN_MILLISECONDS
			) {
				handleLogout();
			}
		};

		events.forEach((event) => {
			window.addEventListener(event, resetTimer);
		});

		const interval = setInterval(
			checkInactivity,
			INACTIVITY_CHECK_TIME_MILLISECONDS
		);

		return () => {
			events.forEach((event) => window.removeEventListener(event, resetTimer));
			clearInterval(interval);
		};
	}, [lastActivityTime, handleLogout]);
};

export default useInactivityLogout;
