import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "react-spinkit";
import axios from "../../axios";
import { Context } from "../../Context/Contexts";
import { GetCartItemsCount } from "../Cart/GetCartItemsCount";
import CustomConfirmation from "../ModalConfirm/CustomConfirmation";
import { GetPrompts } from "../Prompts/PromptsComponent";
import { SPINNER_STYLES_ORANGE } from "../Spinner/SpinnerStyles";
import { GetProductsByUser } from "./GetProductsByUser";
import ProductCard from "./ProductCard";

export default function ProductList() {
  const navigate = useNavigate();
  const [addedToCartConfirmationDlg, setAddedToCartConfirmationDlg] =
    useState(false);
  const [productsListsResponse, setProductsListsResponse] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [purchasedProductsList, setPurchasedProductsList] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const { setShowIsloading } = useContext(Context)
  const { setShowFooter, cartItemCounter, setCartItemCounter } =
    useContext(Context)

  const url = "Product/api/v1/GetAllProductsPaged"

  const GetProductsLists = () => {
    GetProductsByUser().then(function (response) {
      setProductsListsResponse(response)
    })
  }

  const IsPurchasedProduct = (productId) => {
    var result = false
    purchasedProductsList.map((product) => {
      if (product.productId === productId) {
        result = true
      }
    })
    return result
  }

  const GetPRoducts = async (e) => {
    try {
      setShowIsloading(true)

      const response = await axios.get(url, {
        params: {
          CurrentPage: 1,
          ItemsPerPage: 10,
        },
      })
      setShowIsloading(false)

      setProductsList(response.data.result)
    } catch (error) {
      setShowIsloading(false)
    }
  }

  const UpdateCart = () => {
    GetCartItemsCount().then(function (response) {
      setCartItemCounter(response)
    })
  }

  useEffect(() => {
    GetProductsLists()
  }, [])

  useEffect(() => {
    setPurchasedProductsList(productsListsResponse.purchased)
  }, [productsListsResponse])

  useEffect(() => {
    setProductsList(productsListsResponse.available)
  }, [purchasedProductsList])

  return (
    <>
      <div className="container products-container">
        {productsList ? (
          productsList.map((product) => {
            return ProductCard(
              product,
              navigate,
              UpdateCart,
              IsPurchasedProduct,
              setAddedToCartConfirmationDlg,
              setShowIsloading,
              GetPrompts
            )
          })
        ) : (
          <></>
        )}
      </div>

      <CustomConfirmation
        dlgOpen={addedToCartConfirmationDlg}
        onClose={() => setAddedToCartConfirmationDlg(false)}
        confirmMessage={"Item incuido en tu carrito."}
      />
      <div>
        {isloading ? (
          <div style={SPINNER_STYLES_ORANGE}>
            <Spinner />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  )
}
