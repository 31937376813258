import axios from "../../../axios";
export const CreateNewUserYearPlannedItem = async (
  selectedYear,
  schoolSubjectDesripption
) => {
  try {
    const url = `PlannerYearActivities/api/v1/CreateNewYearPlanActivity`

    if (selectedYear == undefined) {
      selectedYear = 1;
    }
    const response = await axios.put(url, {
      UserId: 0,
      YearId: selectedYear,
      Description: schoolSubjectDesripption,
      SchoolSubjectId: 0,
    });
    return response.data;
  } catch (error) {}
};
