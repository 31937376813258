import { useEffect, useState } from "react";
import { GetPlannedActivities } from "../Actions/GetPlannedActivities";
import NewPlannedActivity from "../AddPlannedActivity/NewPlannedActivity";
import EditPlannedActivity from "../EditPlannedActivity/EditPlannedActivity";
import { EmptyPlannedActivity } from "./EmptyPlannedActivity";
import PlannedActivityCard from "./PlannedActivityCard";
import PlannedGroupedActivity from "./PlannedGroupedActivity";

export default function PlannerList({ plannedDateConsult }) {
	const [newIsOpen, setNewIsOpen] = useState(false);
	const [editIsOpen, setEditIsOpen] = useState(false);
	const [editGroupIsOpen, setEditGroupIsOpen] = useState(false);

	const [userId, setUserId] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [totalPages, SetTotalPages] = useState(0);

	const [plannedActivitiesList, setPlannedActivitiesList] = useState([]);
	const [plannedGroupedActivitiesList, setPlannedGroupedActivitiesList] =
		useState([]);

	const [plannedActivityInEdition, setPlannedActivityInEdition] = useState("");

	const AddPlannedActivityHandler = () => {
		const emptyPlannedActivity = EmptyPlannedActivity;
		emptyPlannedActivity.userId = userId;

		setPlannedActivityInEdition(emptyPlannedActivity);
		setNewIsOpen(true);
	};

	useEffect(() => {
		FeedPlannedActivitiesList();
	}, [plannedDateConsult]);

	const FeedPlannedActivitiesList = () => {
		GetPlannedActivities({
			currentPage,
			itemsPerPage,
			plannedDateConsult,
		}).then(function (response) {
			setCurrentPage(response.currentPage);
			SetTotalPages(response.pages);
			setPlannedActivitiesList(response.result);
		});
	};

	const EditPlannedActivityClickHandler = (activity) => {
		setPlannedActivityInEdition(activity);

		if (activity.grouped.length > 1) {
			setPlannedGroupedActivitiesList(activity.grouped);
			setEditGroupIsOpen(true);
		} else {
			setEditIsOpen(true);
		}
	};

	return (
		<>
			<div className="container planned-activities-container">
				{plannedActivitiesList.map((plannedActivity) => {
					return PlannedActivityCard({
						plannedActivity,
						setIsOpen: EditPlannedActivityClickHandler,
					});
				})}
			</div>

			<PlannedGroupedActivity
				open={editGroupIsOpen}
				plannedActivitiesList={plannedGroupedActivitiesList}
				onClose={() => setEditGroupIsOpen(false)}
				GetPlannedActivities={FeedPlannedActivitiesList}
			/>

			<NewPlannedActivity
				open={newIsOpen}
				plannedActivity={plannedActivityInEdition}
				onClose={() => setNewIsOpen(false)}
				GetPlannedActivities={FeedPlannedActivitiesList}
				setUserId={setUserId}
			/>

			<EditPlannedActivity
				open={editIsOpen}
				plannedActivity={plannedActivityInEdition}
				onClose={() => setEditIsOpen(false)}
				setUserId={setUserId}
			/>
		</>
	);
}
