import { GoogleLogin } from "@react-oauth/google";
import { HandleGoogleLogin } from "./HandleGoogleLogin";
import { useContext, useEffect, useState } from "react";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import ErrorPopUp from "../../Errors/ErrorPopUp";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../Context/Contexts";

const GoogleLoginComponent = () => {
	const navigate = useNavigate();
	const [showAuthError, setShowAuthError] = useState(false);
	const [isloading, setIsloading] = useState(false);
	const { setShowIsloading } = useContext(Context);

	const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const handleLogin = (response) => {
		setShowIsloading(true);

		HandleGoogleLogin({ credential: response.credential }).then((response) => {
			if (response == undefined || response == null) {
				setShowAuthError(true);
				setShowIsloading(false);
				return;
			}
			sessionStorage.setItem("_x", JSON.stringify(response.token));
			sessionStorage.setItem("_c", JSON.stringify(response.refreshToken));
			sessionStorage.setItem("_lang", response.language);

			setShowIsloading(false);

			if (response != "200") {
				navigate("/home");
			} else {
				sessionStorage.removeItem("_x");
				setShowAuthError(true);
			}
		});
	};

	useEffect(() => {
		if (showAuthError) {
			setErrorMessage(GetPrompts("Login Error"));
			setErrorPopUpIsOpen(true);
		}
	}, [showAuthError]);

	return (
		<>
			<GoogleLogin
				onSuccess={handleLogin}
				onError={() => console.log("Login Failed")}
			/>

			<ErrorPopUp
				open={errorPopUpIsOpen}
				onClose={() => setErrorPopUpIsOpen(false)}
				errorMessage={errorMessage}
			/>
		</>
	);
};

export default GoogleLoginComponent;
