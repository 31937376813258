import React from "react";
import ChildrenHome from "../../Components/Children/ChildrenHome/ChildrenHome";
import "./HomePageStudents.css";
export default function HomePageStudents() {
  return (
    <>
      <div className="home-page-students-container">
        <ChildrenHome />
      </div>
    </>
  );
}
