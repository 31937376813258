import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../Context/Contexts";
import ErrorPopUp from "../../Errors/ErrorPopUp";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import { CreateNewUserYearPlannedItem } from "./CreateNewUserYearPlannedItem";
import "./NewUserYearPlannedActivity.css";
import {
	MODAL_STYLES,
	OVERLAY_STYLES,
} from "./NewUserYearPlannedActivity_ModalStyle";
import { YearActivityDropdowns } from "../../GetDropdowns/YearActivityDropdowns";

export default function NewUserYearPlannedActivity({ open, onClose, yearId }) {
	const { refreshYearPlanner, setRefreshYearPlanner } = useContext(Context);
	const { yearPlanDropdowns, setYearPlanDropdowns } = useContext(Context);
	const { showIsloading, setShowIsloading } = useContext(Context);
	const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [selectedYear, setSelectedYear] = useState(yearId);
	const [yearsList, setYearsList] = useState([]);
	const [schoolSubjectDesripption, setSchoolSubjectDesripption] = useState("");

	const GetDropdowns = () => {
		YearActivityDropdowns(yearPlanDropdowns, setYearPlanDropdowns).then(
			function (result) {
				setYearsList(result.years);
			}
		);
	};

	const CreateClickHandler = async () => {
		if (schoolSubjectDesripption == "") {
			setErrorMessage("Necesitamos todos los campos");
			setErrorPopUpIsOpen(true);
			return;
		}
		setShowIsloading(true);
		CreateNewUserYearPlannedItem(selectedYear, schoolSubjectDesripption).then(
			(response) => {
				setRefreshYearPlanner(true);
				setShowIsloading(false);
				onClose();
				setSelectedYear(0);
				setSchoolSubjectDesripption("");
			}
		);
	};

	const CloseClickHandler = () => {
		onClose();
	};

	useEffect(() => {
		if (open == true) {
			setSelectedYear(yearId);
			GetDropdowns();
		}
	}, [open]);

	if (!open) return null;
	return (
		<>
			<div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>
			<div
				className="new-useryear-plannedactivity-card"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className="new-planned-year-activity-activity-close-btn"
					onClick={CloseClickHandler}
				>
					X
				</div>

				<div className="new-planned-year-activity-row-container">
					<input
						className="new-planned-year-activity-comment-content"
						autoFocus
						type="text"
						onChange={(e) => setSchoolSubjectDesripption(e.target.value)}
						placeholder={GetPrompts("School Subject text")}
					/>
				</div>

				<div className="new-planned-year-activity-footer-btn-container">
					<div
						className="new-planned-year-activity-create-btn"
						onClick={() => {
							CreateClickHandler();
						}}
					>
						{GetPrompts("Create text")}
					</div>
				</div>
			</div>
			<ErrorPopUp
				open={errorPopUpIsOpen}
				onClose={() => setErrorPopUpIsOpen(false)}
				errorMessage={errorMessage}
			/>
		</>
	);
}
