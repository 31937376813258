import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import * as IoIcons from "react-icons/io";
import React, { useContext, useState } from "react";
import CustomConfirmation from "../ModalConfirm/CustomConfirmation";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import "./Checkout.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./CheckOutWindow_ModalStyles";
import { ConfirmPayment } from "./ConfirmPayment";
import { CreatePaymentIntent } from "./CreatePaymentIntent";
import { Context } from "../../Context/Contexts";
import InfoPopup from "../InfoPopup/InfoPopup";
import { useNavigate } from "react-router-dom";

export default function CheckOutWindow({ UpdateCartPage, totalPrice }) {
	const navigate = useNavigate();
	const [openWindow, setOpenWindow] = useState(false);
	const { showIsloading, setShowIsloading } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const stripe = useStripe();
	const elements = useElements();
	const [clientSecret, setClientSecret] = useState("");
	const [billingDetails, setBillingDetails] = useState("");
	const [paymentIntentId, setPaymentIntentId] = useState("");
	const [paymentInitiated, setPaymentInitiated] = useState(false);
	const [paymentTerminated, setPaymentTerminated] = useState(false);

	const [showPreparingConfirmation, setShowPreparingConfirmation] =
		useState(false);

	const [showProcesingPayConfirmation, setShowProcesingPayConfirmation] =
		useState(false);

	const [infoPopUpIsOpen, setInfoPopUpIsOpen] = useState(false);
	const [infoMessage, setInfoMessage] = useState("");

	const CloseWindowAdShowError = () => {
		setOpenWindow(false);
		setInfoMessage("Payment was not processed");
		setInfoPopUpIsOpen(true);
		setTimeout(() => window.location.reload(), 1500);
	};
	const CreateIntent = () => {
		setShowIsloading(true);
		CreatePaymentIntent().then(function (response) {
			setClientSecret(response.clientSecret);
			setBillingDetails(response.billingDetails);
			setPaymentIntentId(response.paymentIntentId);
			setPaymentInitiated(true);

			setShowIsloading(false);
			setOpenWindow(true);
		});
	};

	const submitPayment = async (event) => {
		event.preventDefault();

		if (elements == null) {
			return;
		}

		setShowIsloading(true);

		stripe
			.confirmCardPayment(clientSecret, {
				payment_method: {
					card: elements.getElement(CardElement),
					billing_details: {
						name: billingDetails.name,
						email: billingDetails.email,
					},
				},
			})
			.then(function (result) {
				ConfirmPayment({ paymentIntentId }).then(function (response) {
					setShowIsloading(false);
					setPaymentTerminated(true);
					if (response == true) {
						UpdateCartPage();
						setOpenWindow(false);

						setShowDonecomponent(true);

						setTimeout(() => navigate("/home"), 1500);
					} else {
						setOpenWindow(false);
						//Todo: Error message and redirection
						alert("Eror");
					}
				});
			});
	};

	return (
		<>
			<div>
				{!paymentInitiated ? (
					<div className="check-out-pay-btn-container">
						<div
							id="check-out-initiate-btn"
							className="check-out-initiate-btn"
							onClick={CreateIntent}
						>
							<span className="base-title-normal-height">Pagar</span>
						</div>
					</div>
				) : (
					""
				)}
			</div>

			{paymentInitiated & openWindow ? (
				<div>
					<div style={OVERLAY_STYLES}></div>

					<div
						style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
						className="container check-out-window-container"
					>
						<div
							className="check-out-close-btn"
							onClick={CloseWindowAdShowError}
						>
							X
						</div>

						<div className="check-out-card-element">
							<div className="check-out-card-element-user-details">
								<div className="check-out-card-element-name check-out-card-row">
									<div className="check-out-card-element-label base-title-normal-height">
										Nombre:
									</div>
									<input
										id="check-out-card-element-name-input"
										type="text"
										className="check-out-input check-out-card-element-name-input"
										placeholder="Como aparece en tu tarjeta"
									/>
								</div>
								<div className="check-out-card-element-address check-out-card-row">
									<div className="check-out-card-element-label base-title-normal-height">
										Direccion:
									</div>
									<input
										id="check-out-card-element-address-input"
										type="text"
										className="check-out-input check-out-card-element-name-input"
										placeholder="Tu direccion"
									/>
								</div>
							</div>

							<div className="check-out-card-element-stripe">
								<CardElement />
							</div>
						</div>

						<div className="check-out-confirm-pay-btn-container">
							<div
								id="check-out-confirm-pay-btn"
								className="check-out-confirm-pay-btn base-sub-title"
								onClick={submitPayment}
							>
								Confirmar Pago
							</div>
						</div>

						<div className="check-out-sumary-details">
							<div className="cart-summary-total-price base-title-normal-height">
								Total:{" "}
								<span className="cart-summary-total-price-number">
									${totalPrice}
								</span>
							</div>
						</div>
					</div>
				</div>
			) : (
				""
			)}

			<CustomConfirmation
				dlgOpen={showPreparingConfirmation}
				onClose={() => setShowPreparingConfirmation(false)}
				confirmMessage={"Estamos preparando el pago..."}
				showContinueBtn={false}
			/>
			<CustomConfirmation
				dlgOpen={showProcesingPayConfirmation}
				onClose={() => showProcesingPayConfirmation(false)}
				confirmMessage={"Ahora estamos procesando tu pago..."}
				showContinueBtn={false}
			/>

			<InfoPopup
				icon={<IoIcons.IoMdAlert />}
				open={infoPopUpIsOpen}
				onClose={() => {
					setInfoPopUpIsOpen(false);
				}}
				errorMessage={infoMessage}
			/>
		</>
	);
}
