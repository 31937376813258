import moment from "moment";
import React, { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import * as IoIcons from "react-icons/io";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import "./TimePickerPopUp.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./TimePickerPopUp_ModalStyle";

export default function TimePickerPopUp({
  open,
  close,
  defaultValue,
  onChange,
}) {
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [isAm, setIsAm] = useState(false);
  const DefineAmOrPm = (e) => {
    var time = moment(e).format("h:mm a");
    if (time.toLowerCase().endsWith("am")) {
      setIsAm(true);
    } else {
      setIsAm(false);
    }
  };
  const OnChangeHandler = (e) => {
    DefineAmOrPm(e);
    onChange(e);
    // close();
  };

  const CloseClickHandler = () => {
    close();
  };

  const HandleKeyDown = (e) => {
    if (e.key == "Enter") {
      close();
    }
  };

  useEffect(() => {
    DefineAmOrPm(defaultValue);
  }, [defaultValue]);
  if (!open) return null;
  return (
    <>
      <article>
        <div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>

        <div
          className="time-picker-pop-up-container"
          style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
        >
          <div
            className="time-picker-pop-up-container-close-btn"
            onClick={CloseClickHandler}
          >
            X
          </div>

          <DateTimePicker
            onChange={(e) => OnChangeHandler(e)}
            format={"h:mm a"}
            value={defaultValue}
            calendarIcon={null}
            clearIcon={null}
            disableClock={true}
            onKeyDown={HandleKeyDown}
          />
          <span>
            {isAm ? (
              <span className="time-picker-pop-ampm time-picker-pop-up-am">
                <IoIcons.IoMdSunny />
              </span>
            ) : (
              <span className="time-picker-pop-ampm time-picker-pop-up-pm">
                <IoIcons.IoMdMoon />
              </span>
            )}
          </span>
        </div>
      </article>
    </>
  );
}
