import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Context } from "../../Context/Contexts";
import Sidebar from "../Sidebar/Sidebar";
import YouTubeVideoPlayer from "../Video/YouTubeVideoPlayer";
import { GetPlaylist } from "./GetPlaylist";
import PlaylistVideoCard from "./PlaylistVideoCard";
import "./PlaylistVideoPlayer.css";

export default function PlaylistVideoPlayer() {
  const [videoId, setVideoId] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [playlist, setPlaylist] = useState([]);
  const [playlistId, setPlaylistId] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const { setShowFooter } = useContext(Context);
  const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);

  useEffect(() => {
    setShowFooter(false);
    setShowNavbarItems(false);
    setShowSidebarToggle(true);
  });

  useEffect(() => {
    if (playlistId > 0) {
      GetPlaylist({ playlistId }).then(function (response) {
        setPlaylist(response);

        if (videoId == 0) {
          setVideoId(response[0].video.videoId);
          setVideoUrl(response[0].video.url);
        }
      });
    }
  }, [playlistId]);

  useEffect(() => {
    setPlaylistId(searchParams.get("plid"));
  });

  return (
    <>
      <Sidebar />
      <div className="playlist-video-player-container">
        <div className="playlist-video-player-video">
          {YouTubeVideoPlayer(videoUrl)}
        </div>
        <div className="playlist-video-player-playlist">
          {playlist.map((item) => {
            return PlaylistVideoCard(item.video, setVideoUrl, setVideoId);
          })}
        </div>
      </div>
    </>
  );
}
