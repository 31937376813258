import { useContext, useEffect, useState } from "react";
import { GetChildren } from "../GetChildren";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import { Avatar } from "@mui/material";
import moment from "moment";
import ChildCard_Edit from "../ChildCard_Edit";
import PlannedActivitiesPerChildPerYear from "../../Planner/PlannedActivitiesPerChildPerYear/PlannedActivitiesPerChildPerYear";
import { EmptyChild } from "../EmptyChild";
import { green } from "@mui/material/colors";
import "./ChildreAdminGrid.css";
import { Context } from "../../../Context/Contexts";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

export function ChildrenAdminGrid({
	selectionEnabled,
	selectedItem,
	setSelectedItem,
}) {
	var loadCount = 0;
	const [isOpen, setIsOpen] = useState(false);
	const { setShowIsloading } = useContext(Context);
	const [childrenList, setChildrenList] = useState([]);
	const [childInEdition, setChildInEdition] = useState("");
	const [showActivitiesPerYear, setShowActivitiesPerYear] = useState(false);

	const FeedChildrenList = () => {
		setShowIsloading(true);
		GetChildren({ currentPage: 0, itemsPerPage: 100 }).then(function (
			response
		) {
			setShowIsloading(false);
			setChildrenList(response.result);
		});
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		FeedChildrenList();
		loadCount = loadCount + 1;
	};

	return (
		<>
			<div className="children-admin-container">
				<div className="base-title">{GetPrompts("My Students Text")}</div>
				<>
					<PerfectScrollbar>
						<table className="children-home-table-admin">
							<tr className="children-home-table-header table-row-header">
								<th className="children-home-table-header-name">
									{GetPrompts("Name text")}/{GetPrompts("Last Name text")}
								</th>
								<th>{GetPrompts("Date Of Birth Text")}</th>
								<th>{GetPrompts("Current year")}</th>
							</tr>

							{childrenList.map((child) => {
								var selectedchildId =
									selectedItem == undefined ? 0 : selectedItem.planChildId;
								return (
									<>
										<tr
											className={`children-admin-card-container ${
												selectionEnabled ? "selection-enabled" : ""
											} ${
												child.planChildId == selectedchildId
													? "selected-child"
													: ""
											}`}
											key={`children-home-card-container-key${child.planChildId}`}
											onClick={() => {
												setSelectedItem(child);
											}}
										>
											<td className="children-home-card-name table-row-item">
												<div className="children-home-card-name-avatar">
													<Avatar sx={{ bgcolor: green[200] }}>
														<span className="children-home-card-name-avatar-text">
															{child.firstName.charAt(0)}
														</span>
													</Avatar>
												</div>
												<div className="children-home-card-name-text table-row-item">
													{child.firstName} {child.lastName}
												</div>
											</td>
											<td className="children-home-card-dob table-row-item">
												{moment(child.dateOfBirth).format("DD/MM/YYYY")}
											</td>
											<td className="children-home-card-year-text table-row-item">
												{child.planYear.yearId}
											</td>
										</tr>

										<div className="children-home-card-btn-container">
											<div
												className="children-home-card-btn-edit"
												onClick={() => {
													setChildInEdition(child);
													setIsOpen(true);
												}}
											>
												<div className="children-home-card-btn-text base-button-text">
													{GetPrompts("Edit text")}
												</div>
											</div>

											<div
												className=" children-home-card-btn-text children-home-card-btn-edit-view-plan"
												onClick={() => {
													setChildInEdition(child);
													setShowActivitiesPerYear(true);
												}}
											>
												<div className="base-button-text">
													{GetPrompts("View plan")}
												</div>
											</div>
										</div>
									</>
								);
							})}

							<tr className={"children-home-card-container"}>
								<td className="children-home-card-name">
									<div className="children-home-card-name-avatar"></div>
									<div className="children-home-card-name-text"></div>
								</td>
								<td className="children-home-card-dob">...</td>
								<td>
									<span
										id="children-home-card-add"
										className="children-home-card-edit"
										onClick={() => {
											const emptyChild = EmptyChild;
											setChildInEdition(emptyChild);
											setIsOpen(true);
										}}
									>
										{GetPrompts("Add")}
									</span>
								</td>
							</tr>
						</table>
					</PerfectScrollbar>
				</>
			</div>

			<ChildCard_Edit
				open={isOpen}
				child={childInEdition}
				onClose={() => setIsOpen(false)}
				getChildren={FeedChildrenList}
				setUserId={0}
			/>

			<PlannedActivitiesPerChildPerYear
				isOpen={showActivitiesPerYear}
				close={() => {
					setShowActivitiesPerYear(false);
				}}
				child={childInEdition}
			/>
		</>
	);
}
