import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Islanding } from "../CurrentPageValidations/IsLanding";

const useTokenCheck = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (Islanding() === true) {
			return;
		}
		const currentToken = JSON.parse(sessionStorage.getItem("_x"));
		if (!currentToken) {
			navigate("/");
		}
	}, [location, navigate]);
};

export default useTokenCheck;
