import React from "react";
import "./PlaylistVideoCard.css";

export default function PlaylistVideoCard(video, setVideoUrl, setVideoId) {
  const SetCurrentVideoData = () => {
    setVideoUrl(video.url);
    setVideoId(video.videoId);
  };

  return (
    <article key={`playlistvideocard${video.videoId}`}>
      <div
        className="playlist-video-card-container"
        onClick={() => {
          SetCurrentVideoData();
        }}
      >
        <div className="playlist-video-card-thumbnail">
          <img src={video.thumbnailUrl} alt="" />
        </div>

        <div className="playlist-video-card-details">
          <div className="playlist-video-card-title">{video.title}</div>
          <div className="playlist-video-card-description">
            {video.description}
          </div>
        </div>
      </div>
    </article>
  );
}
