import Moment from "moment";
import customAxios from "../../../axios";

export async function GetHomeCalendarActivities({
  weeklyScheduleDate = "08-10-2022 07:00:00",
}) {
  const url = "Planner/api/v1/GetHomeCalendarActivities"
  try {
    const response = await customAxios.get(url, {
      params: {
        UserId: 0,
        CurrentDate: Moment(weeklyScheduleDate).format("MM/DD/YYYY"),
      },
    });

    return response.data.result;
  } catch (error) {}
}
