export const MODAL_STYLES = {
  position: "fixed",
  width: "350px",
  height: "200px",
  borderRadius: "30px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  paddingLeft: "50px",
  zIndex: 1000,
  overflow: "auto",
};

export const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, .7)",
  zIndex: 1000,
};
