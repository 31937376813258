import React, { useContext, useState } from "react";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context/Contexts";
import { GetPrompts } from "../Prompts/PromptsComponent";
import "./PlannerSidebarSubmenu.css";

export default function PlannerSidebarSubmenu({ isOverallMenu = false }) {
	const [displaySubitems, setDisplaySubitems] = useState(false);
	const { isSidebarOpen, setIsSidebarOpen } = useContext(Context);
	const navigate = useNavigate();
	return (
		<article className="planner-sidebar-links-container">
			<ul
				className="planner-sidebar-links"
				onClick={() => {
					setDisplaySubitems(!displaySubitems);
				}}
			>
				<div className="planner-sidebar-item">
					<span className="sidebar-item-icon">
						<IoIcons.IoMdCalendar />
					</span>
					<span
						className={`sidebar-item-text ${
							isOverallMenu ? "overall-sidebar-menu-item" : "sidebar-menu-item"
						}`}
					>
						{GetPrompts("Sidebar Planner")}{" "}
						<span className="planner-sidebar-display-sub-items">
							<RiIcons.RiArrowDownSFill />
						</span>
					</span>
				</div>
			</ul>

			{displaySubitems ? (
				<>
					<ul
						className="planner-sidebar-links"
						onClick={() => {
							navigate("/Planner-Year");
							setIsSidebarOpen(false);
						}}
					>
						<div className="planner-sidebar-sub-item">
							<span className="sidebar-item-icon">
								<IoIcons.IoMdCalendar />
							</span>
							<span
								className={`sidebar-item-text ${
									isOverallMenu
										? "overall-sidebar-menu-item"
										: "sidebar-menu-item"
								}`}
							>
								{GetPrompts("Sidebar Plan")}
							</span>
						</div>
					</ul>
					<ul
						className="planner-sidebar-links"
						onClick={() => {
							navigate("/WeeklyScheduleOrdered");
							setIsSidebarOpen(false);
						}}
					>
						<div className="planner-sidebar-sub-item">
							<span className="sidebar-item-icon">
								<IoIcons.IoMdCalendar />
							</span>
							<span
								className={`sidebar-item-text ${
									isOverallMenu
										? "overall-sidebar-menu-item"
										: "sidebar-menu-item"
								}`}
							>
								{GetPrompts("Sidebar Schedule")}
							</span>
						</div>
					</ul>
					<ul
						className="planner-sidebar-links"
						onClick={() => {
							navigate("/PlanPerDayCustomReport");
							setIsSidebarOpen(false);
						}}
					>
						<div className="planner-sidebar-sub-item">
							<span className="sidebar-item-icon">
								<IoIcons.IoMdList />
							</span>
							<span
								className={`sidebar-item-text ${
									isOverallMenu
										? "overall-sidebar-menu-item"
										: "sidebar-menu-item"
								}`}
							>
								{GetPrompts("Sidebar Activities Report")}
							</span>
						</div>
					</ul>
					<ul
						className="planner-sidebar-links"
						onClick={() => {
							navigate("/WeeklySchedule");
							setIsSidebarOpen(false);
						}}
					>
						<div className="planner-sidebar-sub-item">
							<span className="sidebar-item-icon">
								<IoIcons.IoMdCalendar />
							</span>
							<span
								className={`sidebar-item-text ${
									isOverallMenu
										? "overall-sidebar-menu-item"
										: "sidebar-menu-item"
								}`}
							>
								{GetPrompts("Sidebar Schedule With Time")}
							</span>
						</div>
					</ul>
					{/* <ul
						className="planner-sidebar-links"
						onClick={() => {
							navigate("/Planner");
							setIsSidebarOpen(false);
						}}
					>
						<div className="planner-sidebar-sub-item">
							<span className="sidebar-item-icon">
								<IoIcons.IoMdReturnRight />
							</span>
							<span
								className={`sidebar-item-text ${
									isOverallMenu
										? "overall-sidebar-menu-item"
										: "sidebar-menu-item"
								}`}
							>
								{GetPrompts("Sidebar Monthly Calendar")}
							</span>
						</div>
					</ul> */}
				</>
			) : (
				""
			)}
		</article>
	);
}
