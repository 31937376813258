import { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context/Contexts";
import { CustomerSupportIcon } from "../../Pages/CustomerSupport/CustomerSupport";
import { UserProfileIcon } from "../../Pages/UserProfile/UserProfile";
import BlogLink from "../BlogLink/BlogLink";
import LogOutLink from "../LogOutLink/LogOutLink";
import { GetPrompts } from "../Prompts/PromptsComponent";
import PlannerSidebarSubmenu from "./PlannerSidebarSubmenu";
import "./Sidebar.css";
import { SidebarShareLinks_Home } from "./SidebarShareLinks";
import { OVERLAY_STYLES, OVERLAY_STYLES_NA } from "./SidebarStyles";
import "./Sidebar_Alt.css";

export default function Sidebar() {
	const { isSidebarOpen, setIsSidebarOpen } = useContext(Context);
	const { userProfileIsOpen, setUserProfileIsOpen } = useContext(Context);
	const navigate = useNavigate();
	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	return (
		<>
			<div
				style={isSidebarOpen ? OVERLAY_STYLES : OVERLAY_STYLES_NA}
				onClick={toggleSidebar}
			></div>
			<div>
				<aside
					className={`${isSidebarOpen ? "sidebar show-sidebar" : "sidebar"} `}
				>
					<div className="sidebar-header">
						<div></div>
						<button className="close-btn" onClick={toggleSidebar}>
							<FaTimes />
						</button>
					</div>

					<div className="sidebar-items-container-main">
						<div className="sidebar-items-links-main">
							<ul
								className="sidebar-links"
								onClick={() => {
									navigate("/home");
									setIsSidebarOpen(false);
								}}
							>
								<div className="sidebar-item ">
									<span className="sidebar-item-icon">
										<IoIcons.IoMdHome />
									</span>
									<span className="sidebar-item-text overall-sidebar-menu-item">
										{GetPrompts("Home Sidebar Home")}
									</span>
								</div>
							</ul>

							<ul
								className="sidebar-links sidebar-planner-item"
								onClick={() => {
									navigate("/Planner-Year");
									setIsSidebarOpen(false);
								}}
							>
								<div className="sidebar-item ">
									<span className="sidebar-item-icon">
										<IoIcons.IoMdCalendar />
									</span>
									<span className="sidebar-item-text overall-sidebar-menu-item">
										{GetPrompts("Sidebar Planner")}
									</span>
								</div>
							</ul>

							<PlannerSidebarSubmenu isOverallMenu={true} />

							<ul
								className="sidebar-links"
								onClick={() => {
									setIsSidebarOpen(false);
								}}
							>
								<UserProfileIcon isOverallMenu={true} />
							</ul>

							<ul
								className="sidebar-links"
								onClick={() => {
									setIsSidebarOpen(false);
								}}
							>
								<CustomerSupportIcon isOverallMenu={true} />
							</ul>

							<ul
								className="sidebar-links"
								onClick={() => {
									setIsSidebarOpen(false);
								}}
							>
								<BlogLink isOverallMenu={true} />
							</ul>

							<SidebarShareLinks_Home isOverallMenu={true} />
						</div>
						<div className="sidebar-items-footer-main">
							<div
								className="sidebar-links"
								onClick={() => {
									setIsSidebarOpen(false);
								}}
							>
								<LogOutLink isOverallMenu={true} />
							</div>
						</div>
					</div>
				</aside>
			</div>
		</>
	);
}
