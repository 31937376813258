import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react"
import Spinner from "react-spinkit"
import YesNoConfirm from "../ModalConfirm/YesNoConfirm"
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle"
import { GetPrompts } from "../Prompts/PromptsComponent"
import { SPINNER_STYLES_NOTEXT } from "../Spinner/SpinnerStyles"
import { CreateSchoolSubject } from "./CreateSchoolSubject"
import { DeleteSchoolSubject } from "./DeleteSchoolSubject"
import "./SchoolSubjectCard_Edit.css"
import {
  MODAL_STYLES,
  OVERLAY_STYLES,
} from "./SchoolSubjectCard_Edit_ModalStyle"
import { UpdateSchoolSubject } from "./UpdateSchoolSubject"
import { Context } from "../../Context/Contexts"

export default function SchoolSubjectCard_Edit({
  open,
  schoolSubject,
  onClose,
  getSchoolSubjects,
  setUserId,
}) {
  const userId = schoolSubject.userId
  const [isNew, setIsNew] = useState(false)

  const [isloading, setIsloading] = useState(false)
  const { setShowIsloading } = useContext(Context)
  const [dlgOpen, setDlgOpen] = useState(false)

  const [schoolSubjectDescription, setSchoolSubjectDescription] = useState()
  const [schoolSubjectId, setSchoolSubjectId] = useState()

  const FeedEditionVariables = async (e) => {
    if (open) {
      if (schoolSubject.description == "") {
        setIsNew(true)
      } else {
        setIsNew(false)
      }

      setUserId(schoolSubject.userId)

      setSchoolSubjectId(schoolSubject.schoolSubjectId)
      setSchoolSubjectDescription(schoolSubject.description)
    }
  }

  useEffect(() => {
    FeedEditionVariables()
  }, [open])

  const SchoolSubjectEditionHandler = async (e) => {
    setSchoolSubjectId(e)
  }

  const SaveButtonClickHandler = async () => {
    await UpdateSchoolSubject(userId, schoolSubjectId, schoolSubjectDescription)
    getSchoolSubjects()
    onClose()
  }

  const CreateButtonClickHandler = async () => {
    setShowIsloading(true)
    await CreateSchoolSubject(schoolSubjectDescription)
    getSchoolSubjects()
    onClose()
    setShowIsloading(false)
  }

  const DeleteButtonClickHandler = async () => {
    setShowIsloading(true)
    await DeleteSchoolSubject(userId, schoolSubjectId)
    getSchoolSubjects()
    onClose()
    setShowIsloading(false)
    setDlgOpen(false)
  }

  if (!open) return null
  return (
    <article key={schoolSubjectId}>
      <div style={OVERLAY_STYLES}></div>

      <div
        className="edit-schoolSubject-card"
        style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
      >
        {isNew ? (
          <>
            <div className="edit-schoolSubject-label edit-schoolSubject-schoolSubject">
              Asignatura:
            </div>
            <input
              type="text"
              className="edit-new-schoolSubject-title"
              placeholder={schoolSubjectDescription}
              onChange={(e) => setSchoolSubjectDescription(e.target.value)}
            />
          </>
        ) : (
          <div className="edit-schoolSubject-title">
            {schoolSubjectDescription}
          </div>
        )}

        <button
          className="basic-edition-btn edit-schoolSubject-close-btn"
          onClick={onClose}
        >
          Cerrar
        </button>

        {!isNew ? (
          <>
            <button
              className="basic-edition-btn edit-schoolSubject-save-btn"
              onClick={SaveButtonClickHandler}
            >
              Guardar
            </button>

            <FontAwesomeIcon
              icon={faTrash}
              className="edit-schoolSubject-delete-btn"
              onClick={() => setDlgOpen(true)}
            />
          </>
        ) : (
          <>
            <button
              className="basic-edition-btn edit-schoolSubject-create-btn"
              onClick={CreateButtonClickHandler}
            >
              Crear
            </button>
          </>
        )}

        {isloading ? (
          <div style={SPINNER_STYLES_NOTEXT}>
            <Spinner />
          </div>
        ) : (
          ""
        )}

        <YesNoConfirm
          dlgOpen={dlgOpen}
          callBackFunction={DeleteButtonClickHandler}
          onClose={() => setDlgOpen(false)}
          confirmMessage={GetPrompts("Delete Confirmation Text")}
        />
      </div>
    </article>
  )
}
