import React from "react";

import "./PlaylistCard.css";

export default function PlaylistCard(playlist, navigate) {
  return (
    <article key={`playlistcard${playlist.playListId}`}>
      <div className="playlist-card-container">
        <div className="playlist-card-thumbnail">
          <img src={playlist.thumbnailUrl} alt="" />
        </div>
        <div className="playlist-card-title">{playlist.title}</div>
        <div className="playlist-card-description">{playlist.description}</div>
        {!playlist.isPurchased ? (
          <button className="playlist-btn playlist-card-cart">
            Al carrito
          </button>
        ) : (
          <></>
        )}

        {playlist.isPurchased ? (
          <button
            className="playlist-btn playlist-card-watch"
            onClick={() => {
              navigate(`/PlaylistVideoPlayer?plid=${playlist.playListId}`);
            }}
          >
            Ver
          </button>
        ) : (
          <></>
        )}
      </div>
    </article>
  );
}
