import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import Spinner from "react-spinkit";
import { Context } from "../../Context/Contexts";
import DatePickerPopUp from "../DatePicker/DatePickerPopUp";
import ErrorPopUp from "../Errors/ErrorPopUp";
import YesNoConfirm from "../ModalConfirm/YesNoConfirm";
import {
  MODAL_STYLES_BACKGROUND,
  RESPONSIVE_MODAL_STYLES,
} from "../Modal_Styles/ResponsiveModalStyle";
import "../Modal_Styles/ResponsiveModalStyle.css";
import { GetPrompts } from "../Prompts/PromptsComponent";
import { SPINNER_STYLES_NOTEXT } from "../Spinner/SpinnerStyles";
import "./ChildEdition.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./ChildEdition_ModalStyle";
import { CreateChild } from "./CreateChild";
import { DeleteChild } from "./DeleteChild";
import { UpdateChild } from "./UpdateChild";
import { NewChildIsValidRequest } from "./Validations/NewChildIsValidRequest";
import YearSelector from '../Planner/YearPlan/YearSelector/YearSelector'
import IsLoadingComponent from "../Isloading/IsLoadingComponent"
import { MEMBERSHIP_UPGRADE_NEEDED } from "../Constants/Constants";

export default function ChildEdition({
	open,
	child,
	onClose,
	getChildren,
	setUserId,
}) {
	const CloseClickHandler = () => {
		onClose();
	};

	const [showDateOfBirthDateTimePicker, setShowDateOfBirthDateTimePicker] =
		useState(false);

	const [showYearSelector, setShowYearSelector] = useState(false);

	const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [dlgOpen, setDlgOpen] = useState(false);
	const { showIsloading, setShowIsloading } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const { setShowMembershipNeedUpgrade } = useContext(Context);
	const { setUserProfileIsOpen } = useContext(Context);

	const [isNew, setIsNew] = useState(false);

	const [childFirstName, setChildFirstName] = useState();
	const [childLastName, setChildLastName] = useState();
	const [childId, setChildId] = useState();
	const [yearId, setYearId] = useState(0);
	const [selectedYear, setSelectedYear] = useState();

	const [childDateOfBirth, setChildDateOfBirth] = useState(new Date());

	const [dateOfBirthDatePicker, setDateOfBirthDatePicker] = useState(
		new Date()
	);
	const { setUpdateChildrenList } = useContext(Context);

	useEffect(() => {
		FeedEditionVariables();
	}, [open]);

	const FeedEditionVariables = async (e) => {
		if (open) {
			setYearId(0);
			if (child.firstName == "") {
				setIsNew(true);
				DateOfBirthEditionHandler(new Date());
			} else {
				setIsNew(false);
				DateOfBirthEditionHandler(new Date(child.dateOfBirth));
			}

			setChildFirstName(child.firstName);
			setChildLastName(child.lastName);
			setChildId(child.identification);
			setYearId(child.yearId);
		}
	};

	const CreateButtonClickHandler = async () => {
		if (
			!NewChildIsValidRequest({
				childFirstName,
				childLastName,
				childId,
				childDateOfBirth,
				yearId,
			})
		) {
			setErrorMessage(
				"Necesitamos todos los campos, para procesar esta operacion"
			);
			setErrorPopUpIsOpen(true);
			return;
		}

		setShowIsloading(true);
		await CreateChild(
			childFirstName,
			childLastName,
			childId,
			childDateOfBirth,
			yearId
		).then((response) => {
			setShowIsloading(false);

			if (response.errors === MEMBERSHIP_UPGRADE_NEEDED) {
				setShowMembershipNeedUpgrade(true);
				onClose();
				setUserProfileIsOpen(false);
				return;
			}
			setUpdateChildrenList(true);
			onClose();
		});
		getChildren();
	};

	const SaveButtonClickHandler = async () => {
		setShowIsloading(true);
		await UpdateChild(
			0,
			child.planChildId,
			childFirstName,
			childLastName,
			childId,
			childDateOfBirth,
			yearId
		);
		setShowDonecomponent(true);
		getChildren();
		setUpdateChildrenList(true);
		onClose();
		setShowIsloading(false);
	};

	const DeleteButtonClickHandler = async () => {
		setShowIsloading(true);
		await DeleteChild(0, child.planChildId);
		getChildren();
		setUpdateChildrenList(true);
		onClose();
		setDlgOpen(false);
		setShowIsloading(false);
	};

	const DateOfBirthEditionHandler = (e) => {
		setDateOfBirthDatePicker(e);
		setChildDateOfBirth(Moment(e).format("YYYY-MM-DDTHH:mm:ss"));
	};

	if (!open) return null;

	return (
		<>
			<div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>

			<div
				className="modal-container-image4"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES_BACKGROUND)}
			></div>

			<div
				className="child-edition-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div className="child-edition-close-btn" onClick={CloseClickHandler}>
					X
				</div>

				<div className="child-edition-title">
					{GetPrompts("My Students Text")}
				</div>

				<div className="child-edition-picture-container">
					<IoIcons.IoMdPerson className="child-edition-picture-image-person" />
				</div>

				<div className="child-edition-form-container">
					<div className="child-edition-form-row">
						<div className="child-edition-form-label">
							{GetPrompts("Name text")}
						</div>

						<input
							id="child-edition-form-input-name"
							type="text"
							className="child-edition-form-input"
							placeholder={child.firstName}
							defaultValue={child.firstName}
							onChange={(e) => setChildFirstName(e.target.value)}
						/>
					</div>

					<div className="child-edition-form-row">
						<div className="child-edition-form-label">
							{GetPrompts("Last Name text")}
						</div>

						<input
							id="child-edition-form-input-last-name"
							type="text"
							className="child-edition-form-input"
							placeholder={child.lastName}
							defaultValue={child.lastName}
							onChange={(e) => setChildLastName(e.target.value)}
						/>
					</div>

					<div className="child-edition-form-row">
						<div className="child-edition-form-label">
							{GetPrompts("Id Text")}:
						</div>

						<input
							id="child-edition-form-input-id"
							type="text"
							className="child-edition-form-input"
							placeholder={child.identification}
							defaultValue={child.identification}
							onChange={(e) => setChildId(e.target.value)}
						/>
					</div>

					<div className="child-edition-form-row">
						<div className="child-edition-form-label">
							{GetPrompts("Date Of Birth Text")}:
						</div>

						<div
							id="child-edition-form-input-dob"
							className="child-edition-form-input"
							onClick={() => {
								setShowDateOfBirthDateTimePicker(true);
							}}
						>
							<div className="child-edition-form-input-text">
								{childDateOfBirth != undefined
									? Moment(childDateOfBirth).format("DD-MM-YYYY")
									: Moment(child.dateOfBirth).format("DD-MM-YYYY")}
							</div>
						</div>
					</div>

					<div className="child-edition-form-row">
						<div className="child-edition-form-label">
							{GetPrompts("Current year")}:
						</div>

						<div
							id="child-edition-form-input-year-btn"
							className="child-edition-form-input"
							onClick={() => {
								setShowYearSelector(true);
							}}
						>
							<div className="child-edition-form-input-text">
								{yearId > 0 ? yearId : child.planYear.yearId}
							</div>
						</div>
					</div>
				</div>

				<div
					className="child-edition-back-btn"
					onClick={() => {
						CloseClickHandler();
					}}
				>
					{GetPrompts("Back")}
				</div>
				<div className="child-edition-delete-btn">
					<FontAwesomeIcon
						icon={faTrash}
						className="child-edition-trash-btn"
						onClick={() => setDlgOpen(true)}
					/>
				</div>
				{isNew ? (
					<div
						id="child-edition-save-btn"
						className="child-edition-save-btn"
						onClick={() => {
							CreateButtonClickHandler();
						}}
					>
						{GetPrompts("Create text")}
					</div>
				) : (
					<div
						className="child-edition-save-btn"
						onClick={() => {
							SaveButtonClickHandler();
						}}
					>
						{GetPrompts("Save text")}
					</div>
				)}
			</div>

			<DatePickerPopUp
				open={showDateOfBirthDateTimePicker}
				close={() => {
					setShowDateOfBirthDateTimePicker(false);
				}}
				onChange={DateOfBirthEditionHandler}
			/>

			<YearSelector
				isopen={showYearSelector}
				close={() => setShowYearSelector(false)}
				selectedYear={
					child.planYear.yearId != undefined ? child.planYear.yearId : ""
				}
				setSelectedYear={(e) => {
					setYearId(e.yearId);
				}}
			/>

			<YesNoConfirm
				dlgOpen={dlgOpen}
				callBackFunction={DeleteButtonClickHandler}
				onClose={() => setDlgOpen(false)}
				confirmMessage={GetPrompts("Delete Confirmation Text")}
			/>

			<ErrorPopUp
				open={errorPopUpIsOpen}
				onClose={() => setErrorPopUpIsOpen(false)}
				errorMessage={errorMessage}
			/>
		</>
	);
}
