import customAxios from "../../../axios";

export async function GetPlanPeriodsByUser({ requestedDate }) {
	const url = "PlannerCalendarPeriod/api/v1/GetPlanPeriodsByUser";
	try {
		const response = await customAxios.get(url, {
			params: {
				UserId: 0,
				RequestedDate: requestedDate,
			},
		});

		return response.data;
	} catch (error) {}
}
