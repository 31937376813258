import axios from "../../../../axios";
export async function GetYearActivitiesALL({
  currentPage = 1,
  itemsPerPage = 10,
  selectedYear = 1,
  selectedChild = 0,
}) {
  var parseYear = parseInt(selectedYear);
  if (parseYear == 0 || isNaN(parseYear)) {
    selectedYear = 1;
  }
  const url = "PlannerYearActivities/api/v1/GetAllYearPlanActivities"
  try {
    const response = await axios.get(url, {
      params: {
        CurrentPage: currentPage,
        ItemsPerPage: itemsPerPage,
        YearId: selectedYear,
        ChildId: selectedChild,
      },
    });

    return response.data;
  } catch (error) {}
}
