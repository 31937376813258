import moment from "moment";
import React, { useState } from "react";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import EditPlannedActivity from "../EditPlannedActivity/EditPlannedActivity";
import PlannedActivityBriefCard from "./PlannedActivityBriefCard";
import "./PlannedGroupedActivity.css";
import {
	MODAL_STYLES_EDIT,
	OVERLAY_STYLES,
} from "./PlannedGroupedActivity_ModalStyle";

export default function PlannedGroupedActivity({
	plannedActivitiesList,
	open,
	onClose,
	GetPlannedActivities,
}) {
	const [editIsOpen, setEditIsOpen] = useState(false);
	const [plannedActivityInEdition, setPlannedActivityInEdition] = useState("");

	const EditPlannedActivityClickHandler = (activity) => {
		setPlannedActivityInEdition(activity);

		setEditIsOpen(true);
	};

	const CloseWindow = () => {
		onClose();
		GetPlannedActivities();
	};

	if (!open) return null;

	return (
		<article>
			<div style={OVERLAY_STYLES}></div>

			<div
				className="container grouped-activities-modal-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES_EDIT)}
			>
				<div className="grouped-activities-modal-close-btn-container">
					<div className="grouped-activities-modal-close-btn" onClick={onClose}>
						X
					</div>
				</div>

				<div>
					<div className="planned-activities-container">
						<>
							<div className="grouped-planned-activity-title-container">
								<div className="grouped-planned-activity-title-description base-title">
									{plannedActivitiesList[0].activity.description}
								</div>
								<div className="grouped-planned-activity-title-date base-sub-title">
									{moment(plannedActivitiesList[0].date).format("DD-MMM-YY")}
								</div>
								{plannedActivitiesList[0].activityType !== "Order" ? (
									<div className="grouped-planned-activity-title-fromto">
										{moment(plannedActivitiesList[0].fromTime).format(
											"hh:mm a"
										)}{" "}
										-{" "}
										{moment(plannedActivitiesList[0].toTime).format("hh:mm a")}
									</div>
								) : (
									""
								)}
							</div>
							{plannedActivitiesList.map((plannedActivity) => {
								return PlannedActivityBriefCard({
									plannedActivity,
									setIsOpen: EditPlannedActivityClickHandler,
								});
							})}
						</>
					</div>
				</div>
			</div>

			<EditPlannedActivity
				open={editIsOpen}
				plannedActivity={plannedActivityInEdition}
				onClose={() => setEditIsOpen(false)}
				setUserId={() => {
					return null;
				}}
			/>
		</article>
	);
}
