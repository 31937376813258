import axios from "../../axios";
export const DeleteSchoolSubject = async (userId, schoolSubjectId) => {
  try {
    const url = `SchoolSubject/api/v1/DeleteSchoolSubject`

    const response = await axios.post(url, {
      UserId: userId,
      SchoolSubjectId: schoolSubjectId,
    });
  } catch (error) {}
};
