import React, { useContext, useState } from "react";
import "./Impersonator.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./Impersonator_ModalStyle";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import { ImpersonateUser } from "./ImpersonateUser";
import { Context } from "../../Context/Contexts";
import ErrorPopUp from "../Errors/ErrorPopUp";

export default function Impersonator({ isOpen, closeHandler }) {
	const { setShowIsloading } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [username, setUsername] = useState("");
	const [userId, setUserId] = useState(0);

	const handleSend = () => {
		if (username === undefined || username === "") {
			return;
		}
		if (userId === undefined || userId === 0) {
			return;
		}
		setShowIsloading(true);
		ImpersonateUser({ userName: username, userId: userId }).then((response) => {
			if (response === undefined) {
				setShowIsloading(false);
				setErrorMessage("Impersonation unsucessful");
				setErrorPopUpIsOpen(true);
				return;
			}

			sessionStorage.setItem("_x", JSON.stringify(response.token));
			sessionStorage.setItem("_c", JSON.stringify(response.refreshToken));
			sessionStorage.setItem("_lang", response.language);

			setShowDonecomponent(true);
			setUsername("");
			setUserId(0);
			const timerId = setTimeout(triggerRefreshFunction, 3000);
		});
	};
	const triggerRefreshFunction = () => {
		window.location.reload();
		closeHandler();
		setShowIsloading(false);
	};

	if (!isOpen) return null;
	return (
		<>
			<div
				style={OVERLAY_STYLES}
				onClick={() => {
					closeHandler();
				}}
			></div>
			<div
				className="impersonator-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className="impersonator-close-btn"
					onClick={() => {
						closeHandler();
					}}
				>
					X
				</div>

				<div className="impersonator-row">
					<div className="impersonator-label">Username</div>
					<div className="impersonator-input-container">
						<input
							id="impersonator-input-username"
							type="text"
							className="impersonator-input"
							placeholder={"Username"}
							onChange={(e) => setUsername(e.target.value)}
						/>
					</div>
				</div>
				<div className="impersonator-row">
					<div className="impersonator-label">User ID</div>
					<div className="impersonator-input-container">
						<input
							id="impersonator-input-userId"
							type="text"
							className="impersonator-input"
							placeholder={"User Id"}
							onChange={(e) => setUserId(e.target.value)}
						/>
					</div>
				</div>
				<div className="impersonator-btn-container">
					<div
						className="impersonator-btn-send"
						onClick={() => {
							handleSend();
						}}
					>
						IMPERSONATE
					</div>
				</div>
			</div>
			<ErrorPopUp
				open={errorPopUpIsOpen}
				onClose={() => setErrorPopUpIsOpen(false)}
				errorMessage={errorMessage}
			/>
		</>
	);
}
