import React, { useState } from "react";
import {
	MODAL_STYLES_EDIT,
	OVERLAY_STYLES,
} from "./EditPlannedActivityMultiple_ModalStyle";
import { RESPONSIVE_MODAL_STYLES } from "../../../Modal_Styles/ResponsiveModalStyle";
import { useContext } from "react";
import { Context } from "../../../../Context/Contexts";
import ReactQuill from "react-quill";
import {
	quillEditorExtractTextFromHtml,
	quillEditorFormats,
	quillEditorSimpleModules,
} from "../../../RichTextEditor/RichTextEditor";
import "./EditPlannedActivityMultiple.css";
import { GetPrompts } from "../../../Prompts/PromptsComponent";
import YesNoConfirm from "../../../ModalConfirm/YesNoConfirm";
import { EditMultiplePlannedActivitiesComment } from "./EditMultiplePlannedActivitiesComment";
import { DeletedMultiplePlannedActivities } from "./DeletedMultiplePlannedActivities";

export default function EditPlannedActivityMultiple({
	open,
	onClose,
	getActivities,
}) {
	const { activitiesInEditionList, setActivitiesInEditionList } =
		useContext(Context);
	const { setRefreshWeeklySchedule } = useContext(Context);

	const [editionComment, setEditionComment] = useState("");
	const [dlgOpen, setDlgOpen] = useState(false);
	const { setShowIsloading } = useContext(Context);

	const DeleteButtonClickHandler = async () => {
		setShowIsloading(true);

		await DeletedMultiplePlannedActivities({
			plannedActivitiesIdList: GetActivitiesIdsToEditList(),
		}).then((response) => {
			setActivitiesInEditionList([]);
			setEditionComment("");
		});
		setRefreshWeeklySchedule(true);

		onClose();
		setDlgOpen(false);
		setShowIsloading(false);
	};
	const SaveButtonClickHandler = async () => {
		setShowIsloading(true);

		var noFormatComment = quillEditorExtractTextFromHtml(editionComment);

		await EditMultiplePlannedActivitiesComment({
			plannedActivitiesIdList: GetActivitiesIdsToEditList(),
			comments: noFormatComment,
			formattedComments: editionComment,
		}).then((response) => {
			setActivitiesInEditionList([]);
			setEditionComment("");
		});

		setRefreshWeeklySchedule(true);

		onClose();
		setShowIsloading(false);
	};

	const CloseClickHandler = () => {
		onClose();
	};

	const GetActivitiesIdsToEditList = () => {
		return activitiesInEditionList.flatMap((item) => {
			if (item.grouped && item.grouped.length > 0) {
				return item.grouped.map((grouped) => grouped.plannerId);
			}
			return item.plannerId;
		});
	};

	if (!open) return null;
	return (
		<>
			<div
				style={OVERLAY_STYLES}
				onClick={() => {
					onClose();
				}}
			></div>

			<div
				className="edit-planned-activity-multiple-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES_EDIT)}
			>
				<div
					className="edit-planned-activity-close-btn"
					onClick={CloseClickHandler}
				>
					X
				</div>

				<div className="edit-planned-activity-multiple-comment">
					<ReactQuill
						className="edit-planned-activity-comment-input base-parafraph borderless-quill"
						value={editionComment}
						onChange={setEditionComment}
						placeholder={GetPrompts("Details about the activity")}
						theme="snow"
						modules={quillEditorSimpleModules}
						formats={quillEditorFormats}
					/>
				</div>
				<div className="edit-planned-activity-multiple-btns-container">
					<div
						className="edit-planned-activity-multiple-btns-delete base-button-text"
						onClick={() => setDlgOpen(true)}
					>
						Borrar actividades seleccionadas
					</div>

					<div
						className="edit-planned-activity-multiple-btns-save base-button-text"
						onClick={() => {
							SaveButtonClickHandler();
						}}
					>
						Guardar descripcion
					</div>
				</div>
			</div>

			<YesNoConfirm
				dlgOpen={dlgOpen}
				callBackFunction={DeleteButtonClickHandler}
				onClose={() => setDlgOpen(false)}
				confirmMessage={GetPrompts("Delete Confirmation Text")}
			/>
		</>
	);
}
