import {
  PLANNER_SETTING_FROM_DATE,
  PLANNER_SETTING_FROM_TIME,
  PLANNER_SETTING_TO_DATE,
  PLANNER_SETTING_TO_TIME,
} from "./Actions";
import {
  EditPlannerSettingFromDate,
  EditPlannerSettingFromTime,
  EditPlannerSettingToDate,
  EditPlannerSettingToTime,
} from "./ReducerPlannerSettings";

export default function reducer(state, action) {
  if (action.type === PLANNER_SETTING_FROM_DATE) {
    return {
      ...state,
      plannerSettings: EditPlannerSettingFromDate(state, action),
    };
  }

  if (action.type === PLANNER_SETTING_TO_DATE) {
    return {
      ...state,
      plannerSettings: EditPlannerSettingToDate(state, action),
    };
  }

  if (action.type === PLANNER_SETTING_FROM_TIME) {
    return {
      ...state,
      plannerSettings: EditPlannerSettingFromTime(state, action),
    };
  }

  if (action.type === PLANNER_SETTING_TO_TIME) {
    return {
      ...state,
      plannerSettings: EditPlannerSettingToTime(state, action),
    };
  }

  throw new Error(`No reducer action matches action.type: "${action.type}"`);
}
