export const quillModules = {
	toolbar: false,
};
export const quillEditorModules = {
	toolbar: [
		// [{ header: "1" }, { header: "2" }, { font: [] }],
		// [{ size: [] }],
		["bold", "italic", "underline", "strike", "blockquote"],
		[
			{ list: "ordered" },
			{ list: "bullet" },
			{ indent: "-1" },
			{ indent: "+1" },
		],
		["link", "image"],
		[{ color: [] }],
		["clean"],
	],
};
export const quillEditorSimpleModules = {
	toolbar: [
		["bold", "italic"],
		[
			{ list: "ordered" },
			{ list: "bullet" },
			{ indent: "-1" },
			{ indent: "+1" },
		],
		[{ color: [] }],
	],
};

export const quillEditorFormats = [
	"header",
	"font",
	"size",
	"bold",
	"italic",
	"underline",
	"strike",
	"blockquote",
	"list",
	"bullet",
	"indent",
	"link",
	"image",
	"color",
	"align",
	"color",
	"background",
];
export function quillEditorExtractTextFromHtml(htmlString) {
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, "text/html");
	return doc.body.textContent || "";
}
