import axios from "../../../../axios";
export async function GetAllYearActivityNotesByActivity({ activityId = 0 }) {
  const url =
    "PlannerYearActivitiesNotes/api/v1/GetAllYearActivityNotesByActivity"
  try {
    const response = await axios.get(url, {
      params: {
        UserId: 0,
        ActivityId: activityId,
      },
    });
    return response.data.result;
  } catch (error) {}
}
