import customAxios from "../../../../axios";

export async function DeleteSkipWeek({ calendarWeek }) {
	try {
		const url = `PlannerCalendarPeriod/api/v1/DeleteSkipWeek`;

		const response = await customAxios.post(url, {
			UserId: 0,
			CalendarWeek: calendarWeek,
		});
		return response.data;
	} catch (error) {}
}
