import moment from "moment";
import React from "react";
import { GetPrompts } from "../../../Prompts/PromptsComponent";
import "./PlanPerDayCard.css";
export default function PlanPerDayCard({ dayItem }) {


  var lastActivityId = 0;
  var sameActivity = false;
  var dayText = GetPrompts(`${moment(dayItem.date).format("dddd")} Text`);
  dayText = dayText.substring(0, 3);
  return (
    <>
      <div className="plan-per-day-card-container">
        <div className="plan-per-day-card-day-container">
          <div className="plan-per-day-card-day-date-number-text">
            {dayText}
          </div>

          <div className="plan-per-day-card-day-date-number">
            <div className="plan-per-day-card-day-date-number-number">
              {moment(dayItem.date).format("DD")}
            </div>
          </div>

          <div className="plan-per-day-card-day-date-text">
            {moment(dayItem.date).format("MMM/YYYY")}
          </div>
        </div>
        <div className="plan-per-day-card-day-content-container">
          {dayItem.activityList.map((activity) => {
            return (
              <div className="plan-per-day-report-content-card-description">
                <div className="plan-per-day-report-content-card-description-title base-sub-title">
                  {activity.activity.description}
                </div>
                <div className="plan-per-day-report-content-card-description-description base-parafraph">
                  {activity.comments}
                </div>
                <div className="plan-per-day-report-content-card-description-children base-parafraph">
                  {activity.children.map((child) => {
                    return (
                      <>
                        <div>{child.firstName}</div>
                      </>
                    )
                  })}
                </div>
              </div>
            )
          })}

          {/* <div className="plan-per-day-card-day-content-activity-container"></div> */}
        </div>
      </div>
    </>
  );
}
