import axios from "../../axios";
import HashingComponent from "../HashingComponent/HashingComponent ";
export async function SendPwdReset({ newPwd }) {
  var hashedPwd = await HashingComponent(newPwd);
  const url = "User/api/v1/UserPwdReset"
  try {
    const response = await axios.put(url, {
      UserId: 0,
      NewPassword: hashedPwd,
    });
    return response.data.result;
  } catch (error) {}
}
