import axios from "../../../../axios";
export const UpdatePlannedActivityOrder = async (orderedList, childId = 0) => {
	try {
		const url = `Planner/api/v1/UpdatePlannedActivityOrder`;

		const response = await axios.put(url, {
			UserId: 0,
			OrderedList: orderedList,
			OrderChildId: childId,
		});
	} catch (error) {}
};
