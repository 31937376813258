import React from "react";
import TestDoc from "../Documentation/Docs/Basics_Planning.pdf";

export default function Basics_Planning() {
  return (
    <>
      <div className="test-documentation-container">
        <object
          data={TestDoc}
          type="application/pdf"
          width="100%"
          height={window.innerHeight + 200}
        >
          <p>
            <a href={TestDoc}></a>
          </p>
        </object>
      </div>
    </>
  );
}
