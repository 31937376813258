import Moment from "moment"
import axios from "../../../axios"
export const UpdateActivityYear = async ({ plannerId, yearId }) => {
  try {
    const url = `Planner/api/v1/UpdateActivityYear`

    const response = await axios.post(url, {
      UserId: 0,
      PlannerId: plannerId,
      YearId: yearId,
    })
  } catch (error) {}
}
