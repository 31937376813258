import axios from "../../../axios";
export async function GetPlannedActivities({
  currentPage = 1,
  itemsPerPage = 10,
  plannedDateConsult,
}) {
  const url = "Planner/api/v1/GetAllPlannedActivitiesPaged"
  try {
    const response = await axios.get(url, {
      params: {
        CurrentPage: currentPage,
        ItemsPerPage: itemsPerPage,
        UserId: 1,
        PlannedDate: plannedDateConsult,
      },
    });

    return response.data;
  } catch (error) {}
}
