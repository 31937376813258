import React from "react";
import * as IoIcons from "react-icons/io";
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";
import { GetPrompts } from "../Prompts/PromptsComponent";
import "./SidebarShareLinks.css";

export default function SidebarShareLinks() {
	return (
		<div className="sidebar-links share-btns-div">
			<span className="share-btns-div-text">
				<span className="share-btns-div-text-icon">
					<IoIcons.IoMdShare />
				</span>
				<span className="share-btns-div-text-share sidebar-menu-item">
					{GetPrompts("Share")}
				</span>
			</span>

			<div className="share-btns-div-btns">
				<div>
					<FacebookShareButton
						url={"https://charlottemasonalamano.com/"}
						quote={"Charlotte Mason a la mano!"}
						hashtag="#homeschool"
					>
						<FacebookIcon size={32} round="true" />
					</FacebookShareButton>
				</div>

				<div>
					<LinkedinShareButton
						url={"https://charlottemasonalamano.com/"}
						quote={"Charlotte Mason a la mano!"}
						hashtag="#homeschool"
					>
						<LinkedinIcon size={32} round="true" />
					</LinkedinShareButton>
				</div>

				<div>
					<WhatsappShareButton
						url={"https://charlottemasonalamano.com/"}
						quote={"Charlotte Mason a la mano!"}
						hashtag="#homeschool"
					>
						<WhatsappIcon size={32} round="true" />
					</WhatsappShareButton>
				</div>
			</div>
		</div>
	);
}

export function SidebarShareLinks_Home({ isOverallMenu = false }) {
	return (
		<div className="sidebar-links share-btns-div">
			<span className="share-btns-div-text">
				<span className="share-btns-div-text-icon">
					<IoIcons.IoMdShare />
				</span>
				<span
					className={`share-btns-div-text-share ${
						isOverallMenu ? "overall-sidebar-menu-item" : "sidebar-menu-item"
					}`}
				>
					{GetPrompts("Share")}
				</span>
			</span>

			<div className="share-btns-div-btns">
				<div>
					<FacebookShareButton
						url={"https://charlottemasonalamano.com/"}
						quote={"Charlotte Mason a la mano!"}
						hashtag="#homeschool"
					>
						<FacebookIcon size={32} round="true" />
					</FacebookShareButton>
				</div>

				<div>
					<LinkedinShareButton
						url={"https://charlottemasonalamano.com/"}
						quote={"Charlotte Mason a la mano!"}
						hashtag="#homeschool"
					>
						<LinkedinIcon size={32} round="true" />
					</LinkedinShareButton>
				</div>

				<div>
					<WhatsappShareButton
						url={"https://charlottemasonalamano.com/"}
						quote={"Charlotte Mason a la mano!"}
						hashtag="#homeschool"
					>
						<WhatsappIcon size={32} round="true" />
					</WhatsappShareButton>
				</div>
				<div>
					<EmailShareButton
						url={"https://charlottemasonalamano.com/"}
						quote={"Charlotte Mason a la mano!"}
						hashtag="#homeschool"
					>
						<EmailIcon size={32} round="true" />
					</EmailShareButton>
				</div>
			</div>
		</div>
	);
}
