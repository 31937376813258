import HashingComponent from "../../Components/HashingComponent/HashingComponent ";
import axios from "../../axios";

export const authenticate = async (
  username,
  pwd,
  setShowAuthError,
  setAuthenticated,
  setShowIsloading
) => {
  if (username == "" || pwd == "") {
    setShowIsloading(false)
    setShowAuthError(true)
    setAuthenticated(false)
    return
  }
  try {
    try {
      sessionStorage.removeItem("_x")
      AuthenticateCall().then((response) => {
        if (response == undefined || response == null) {
          setShowAuthError(true)
          setShowIsloading(false)
          return
        }
        sessionStorage.setItem("_x", JSON.stringify(response.token))
        sessionStorage.setItem("_c", JSON.stringify(response.refreshToken))
        sessionStorage.setItem("_lang", response.language)

        setShowIsloading(false)

        if (response != "200") {
          setAuthenticated(true)
        } else {
          sessionStorage.removeItem("_x")
          setShowAuthError(true)
          setAuthenticated(false)
        }
      })
    } catch (error) {
      sessionStorage.removeItem("_x")
      setShowIsloading(false)
      setShowAuthError(true)
      setAuthenticated(false)
    }
  } catch (error) {}

  async function AuthenticateCall() {
    var hashedPwd = await HashingComponent(pwd)
    const url = "UserAuth/api/v1/Authenticate"
    try {
      const response = await axios.post(url, {
        Username: username,
        Password: hashedPwd,
      })
      return response.data
    } catch (error) {}
  }
}
