import { useContext, useEffect } from "react";
import SchoolSubjectsList from "../../Components/SchoolSubject/SchoolSubjectsList";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Context } from "../../Context/Contexts";
import "./SchoolSubjects.css";

export default function SchoolSubjects() {
  const { setShowFooter } = useContext(Context);
  const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);

  useEffect(() => {
    setShowFooter(false);
    setShowNavbarItems(false);
    setShowSidebarToggle(true);
  });

  return (
    <>
      <Sidebar />
      <div className="basic-page-title">Mis grupos para actividades</div>
      <SchoolSubjectsList />
    </>
  );
}
