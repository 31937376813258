import { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context/Contexts";
import BlogLink from "../BlogLink/BlogLink";
import OrlandoLink from "../OrlandoLink/OrlandoLink";
import SpanishLanguage, { EnglishLanguage } from "../Prompts/Languages";
import { GetPrompts } from "../Prompts/PromptsComponent";
import SidebarShareLinks from "../Sidebar/SidebarShareLinks";
import "../Sidebar/Sidebar_Alt.css";
import "./Sidebar.css";
import { OVERLAY_STYLES, OVERLAY_STYLES_NA } from "./SidebarStyles";

export default function SidebarHome() {
	const { isSidebarOpen, setIsSidebarOpen } = useContext(Context);
	const { loinIsOpen, setLoinIsOpen } = useContext(Context);
	const { userProfileIsOpen, setUserProfileIsOpen } = useContext(Context);

	const navigate = useNavigate();
	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	return (
		<>
			<div
				style={isSidebarOpen ? OVERLAY_STYLES : OVERLAY_STYLES_NA}
				onClick={toggleSidebar}
			></div>
			<div>
				<aside
					className={`${isSidebarOpen ? "sidebar show-sidebar" : "sidebar"} `}
				>
					<div className="sidebar-header">
						<div></div>
						<button className="close-btn" onClick={toggleSidebar}>
							<FaTimes />
						</button>
					</div>
					<div className="sidebar-items-container">
						<div className="sidebar-items-links">
							<ul
								className="sidebar-links sidebar-planner-item"
								onClick={() => {
									setIsSidebarOpen(false);
								}}
							>
								<div className="sidebar-item ">
									<span className="sidebar-item-icon">
										<IoIcons.IoMdPerson />
									</span>
									<span className="sidebar-item-text sidebar-menu-item">
										{GetPrompts("Home Sidebar Login")}
									</span>
								</div>
							</ul>

							<ul
								className="sidebar-links"
								onClick={() => {
									setIsSidebarOpen(false);
								}}
							>
								<BlogLink />
							</ul>

							<ul
								className="sidebar-links"
								onClick={() => {
									navigate("/Landing");
									setIsSidebarOpen(false);
								}}
							>
								<div className="sidebar-item">
									<span className="sidebar-item-icon">
										<IoIcons.IoMdHome />
									</span>
									<span className="sidebar-item-text sidebar-menu-item ">
										{GetPrompts("Home Sidebar Home")}
									</span>
								</div>
							</ul>

							<ul
								className="sidebar-links"
								onClick={() => {
									setIsSidebarOpen(false);
								}}
							>
								<SpanishLanguage />
							</ul>

							<ul
								className="sidebar-links"
								onClick={() => {
									setIsSidebarOpen(false);
								}}
							>
								<EnglishLanguage />
							</ul>

							<SidebarShareLinks />
						</div>
						<div className="sidebar-items-footer">
							<ul
								className="sidebar-links"
								onClick={() => {
									setIsSidebarOpen(false);
								}}
							>
								<OrlandoLink />
							</ul>
						</div>
					</div>
				</aside>
			</div>
		</>
	);
}
