import React, { useContext, useEffect, useState } from 'react'
import { GetMentorAvailableSlots } from './GetMentorAvailableSlots'
import {
  MODAL_STYLES,
  OVERLAY_STYLES,
} from './MentorshipAvailableSlots_ModalStyles'
import { RESPONSIVE_MODAL_STYLES } from '../../Components/Modal_Styles/ResponsiveModalStyle'
import moment from 'moment'
import './MentorshipAvailableSlots.css'
import { AddMentorshipAppointment } from './AddMentorshipAppointment'
import YesNoConfirm from '../../Components/ModalConfirm/YesNoConfirm'
import MentorshipCalendar from './MentorshipCalendar'
import { BookSlot } from './BookSlot'
import { Context } from '../../Context/Contexts'
import Spinner from 'react-spinkit'
import {
  SPINNER_STYLES,
  SPINNER_STYLES_NOTEXT,
} from '../../Components/Spinner/SpinnerStyles'
import { GetPrompts } from '../../Components/Prompts/PromptsComponent'

export default function MentorshipAvailableSlots({
  isOpen,
  close,
  defaultMessage,
}) {
  var lasDayProcessed
  const [slotsList, setSlotsList] = useState([])
  const [selectedSlotId, setSelectedSlotId] = useState(0)
  const [confirmation1, setConfirmation1] = useState(false)
  const [confirmation2, setConfirmation2] = useState(false)
  const { isloading, setIsloading } = useContext(Context)
  const { setShowIsloading } = useContext(Context)
  const {
    setUpdateMentorshipAvailableSlots,
    setUpdateMentorshipAppointmentsList,
  } = useContext(Context)

  const CloseClickHandler = () => {
    close()
  }

  if (!isOpen) return

  return (
    <>
      <div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>
      <div
        className="mentorship-available-slots-container"
        style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
      >
        <div
          className="mentorship-available-slots-card-close-btn"
          onClick={CloseClickHandler}
        >
          X
        </div>
        <div className="mentorship-available-slots-calendar">
          <MentorshipCalendar
            setConfirmation1={setConfirmation1}
            setSelectedSlotId={setSelectedSlotId}
          />
        </div>
      </div>

      <YesNoConfirm
        dlgOpen={confirmation1}
        callBackFunction={() => setConfirmation2(true)}
        onClose={() => setConfirmation1(false)}
        confirmMessage={
          "Luego de apartar la mentoria. Te contactaremos por correo para acordar el pago"
        }
      />
      <YesNoConfirm
        dlgOpen={confirmation2}
        callBackFunction={() => {
          setConfirmation1(false)
          setConfirmation2(false)
          BookSlot(selectedSlotId, defaultMessage)
          setShowIsloading(true)
          setTimeout(() => {
            setUpdateMentorshipAvailableSlots(true)
            setUpdateMentorshipAppointmentsList(true)
            setShowIsloading(false)
          }, 2000)
        }}
        onClose={() => {
          setConfirmation2(false)
          setConfirmation1(false)
        }}
        confirmMessage={"Seguro que quieres apartar esta mentoria?"}
      />
      {isloading ? (
        <div style={SPINNER_STYLES} className="loading-label">
          <div>{GetPrompts("Login we are confirming")}</div>
          <Spinner />
        </div>
      ) : (
        ""
      )}
    </>
  )
}
