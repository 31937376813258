import axios from "../../axios";
export const CreateSchoolSubject = async (schoolSubjectDescription) => {
  try {
    const url = `SchoolSubject/api/v1/CreateSchoolSubject`

    const response = await axios.put(url, {
      UserId: 0,
      Description: schoolSubjectDescription,
    });
  } catch (error) {}
};
