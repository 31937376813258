import axios from "../../axios";
export const CreateNewClient = async (
  email,
  firstName,
  lastName,
  password,
  lang
) => {
  try {
    const url = `User/api/v1/CreateNewClient`

    const response = await axios.put(url, {
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
      Language: lang,
    });
  } catch (error) {}
};
