import React from "react";
import { MODAL_STYLES, OVERLAY_STYLES } from "./Children_admin_ModalStyles";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import { ChildrenAdminGrid } from "./ChildreAdminGrid";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

export default function ChildrenAdmin({ open, close }) {
	const CloseClickHandler = () => {
		close();
	};

	if (!open) return null;

	return (
		<>
			<div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>
			<PerfectScrollbar
				className="children-admin-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div className="children-admin-close-btn" onClick={CloseClickHandler}>
					X
				</div>

				<ChildrenAdminGrid />
			</PerfectScrollbar>
		</>
	);
}
