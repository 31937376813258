import customAxios from "../../../axios";

export async function GetStripePublicKey() {
	const url = "Cart/api/v1/GetStripePublicKey";
	try {
		const response = await customAxios.get(url);

		return response.data;
	} catch (error) {}
}
