import customAxios from "../../../../axios";

export async function UpdatePlanPeriod({
	periodId,
	startFromDate,
	numberOfWeeks,
	periodTitle,
}) {
	try {
		const url = `PlannerCalendarPeriod/api/v1/UpdatePlanPeriod`;

		const response = await customAxios.post(url, {
			UserId: 0,
			PlanPeriodId: periodId,
			StartFromDate: startFromDate,
			NumberOfWeeks: numberOfWeeks,
			PeriodTitle: periodTitle,
		});
		return response.data;
	} catch (error) {}
}
