export const MODAL_STYLES_EDIT = {
	position: "fixed",
	width: "400px",
	height: "300px",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	backgroundColor: "#FFF",
	paddingLeft: "30px",
	zIndex: 1000,
};

export const OVERLAY_STYLES = {
	position: "fixed",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	backgroundColor: "rgba(0, 0, 0, .7)",
	zIndex: 1000,
};
