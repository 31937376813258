export default async function HashingComponent(textToHash) {
  const combinedString = textToHash + process.env.REACT_APP_HASH_SALT;
  let hash = 0;
  for (let i = 0; i < combinedString.length; i++) {
    const charCode = combinedString.charCodeAt(i);
    hash = (hash << 5) - hash + charCode;
  }
  const hashString = hash.toString();

  return hashString;
}
