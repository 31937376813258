import React, { useEffect, useState } from "react";
import { GetActivitiesDropdowns } from "../Planner/Actions/GetActivitiesDropdowns";
import "./FrequencySelector.css";

export default function FrequencySelector({
	selectedFrequency,
	setSelectedFrequency,
}) {
	var loadCount = 0;
	const lang = sessionStorage.getItem("_lang");
	const [frequencyList, setFrequencyList] = useState([]);

	const GetDropdowns = async (e) => {
		GetActivitiesDropdowns().then((response) => {
			setFrequencyList(response.frequencies);
		});
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		GetDropdowns();
		loadCount = loadCount + 1;
	};

	return (
		<>
			<div className="frequency-selector-container">
				{frequencyList.map((frequency) => {
					return (
						<article key={`frequency-selector-key${frequency.frequencyId}`}>
							<div
								id={`frequency-selector-key${frequency.frequencyId}`}
								className={`frequency-selector-item-container ${
									selectedFrequency == frequency.frequencyId
										? "hover-brighten selected-frequency"
										: "hover-brighten"
								}`}
								onClick={() => {
									setSelectedFrequency(frequency.frequencyId);
								}}
							>
								<div className={`frequency-selector-item-text`}>
									{`${frequency[`description${lang}`]}`}
								</div>
							</div>
						</article>
					);
				})}
			</div>
		</>
	);
}
