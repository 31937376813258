import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { IoIosAlert, IoIosEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import HomePicture from "../../Assets/HomePicture.png";
import logo from "../../Assets/Logo.svg";
import ErrorPopUp from "../../Components/Errors/ErrorPopUp";
import ForgotPwd from "../../Components/ForgotPwd/ForgotPwd";
import {
	GetPrompts,
	InitiatePrompts,
} from "../../Components/Prompts/PromptsComponent";
import SidebarHome from "../../Components/SidebarHome/SidebarHome";
import {
	SPINNER_STYLES,
	SPINNER_STYLES_ORANGE,
} from "../../Components/Spinner/SpinnerStyles";
import { Context } from "../../Context/Contexts";
import { authenticate } from "../Login/Authenticate";
import SignUpStep1 from "../SignUp/SignUpStep1";
import SignUpStep2 from "../SignUp/SignUpStep2";
import "./Landing.css";
import Landing_Mobile from "./Landing_Mobile";

import { css } from "aphrodite";
import { animationStyles } from "../../Components/Animations/Styles";
import IsLoadingComponent from "../../Components/Isloading/IsLoadingComponent";
import GoogleLoginComponent from "../../Components/Oauth/GoogleAuthentication/LoginComponent";

export default function Landing() {
	const [isMounted, setIsMounted] = useState(false);
	const { showIsloading, setShowIsloading } = useContext(Context);
	useEffect(() => {
		setIsMounted(true);
	}, []);

	const { prompts } = useContext(Context);
	const [textLoaded, setTextLoaded] = useState(false);
	const { showForgotPwd, setShowForgotPwd } = useContext(Context);
	const navigate = useNavigate();
	const [username, setUsername] = useState("");
	const [pwd, setPwd] = useState("");
	const [showPwd, setShowPwd] = useState(false);
	const [showAuthError, setShowAuthError] = useState(false);
	const [isloading, setIsloading] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);
	const [openStep1, setOpenStep1] = useState(false);
	const [openStep2, setOpenStep2] = useState(false);

	const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [isSmallWindow, setIsSmallWindow] = useState(false);

	const STYLES_LEFT = {
		height: window.innerHeight - 50,
	};
	const STYLES_HR = {
		width: "10%",
	};

	const HandleKeyDown = (e) => {
		if (e.key == "Enter") {
			setShowIsloading(true);
			authenticate(
				username,
				pwd,
				setShowAuthError,
				setAuthenticated,
				setShowIsloading
			);
		}
	};

	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});
	useEffect(() => {
		if (authenticated) {
			navigate("/home");
		}
	}, [authenticated]);

	useEffect(() => {
		if (prompts.length > 0) {
			setTextLoaded(true);
		}
	}, [prompts]);

	useEffect(() => {
		if (showAuthError) {
			setErrorMessage(GetPrompts("Login Error"));
			setErrorPopUpIsOpen(true);
		}
	}, [showAuthError]);

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		if (windowSize.width <= 600) {
			setIsSmallWindow(true);
		} else {
			setIsSmallWindow(false);
		}

		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, [windowSize.height]);

	if (isSmallWindow || windowSize.width <= 600) {
		return <Landing_Mobile />;
	}

	if (textLoaded === false) {
		return;
	}
	if (isMounted === false) {
		return;
	}

	return (
		<>
			<SidebarHome />
			{textLoaded ? (
				<div className=" container landing-container">
					<div
						className={
							css(isMounted ? animationStyles.bounceInDown : null) +
							" landing-left-container"
						}
						style={STYLES_LEFT}
					>
						<div className="landing-left-background-picture">
							<img
								id="home-pictures-landing"
								src={HomePicture}
								alt="{home-pictures-landing}"
							/>
						</div>

						<div className="landing-left-text">
							<p className="landing-left-text-title">
								<span>{GetPrompts("Landing paragraph titleLine1")}</span>
								<br />
								<span>{GetPrompts("Landing paragraph titleLine2")}</span>
							</p>
							<p className="landing-left-text-paragraph">
								{GetPrompts("Home page paragraph text")}
							</p>
						</div>

						<div className="landing-left-menu">
							<div
								id="landing-left-menu-register-btn"
								className="landing-left-menu-btn hover-brighten"
								onClick={() => {
									setOpenStep1(true);
								}}
							>
								<p className="landing-left-menu-btn-text base-title">
									{GetPrompts("Home Sidebar Register")}
								</p>
							</div>
						</div>
					</div>
					<div
						className={
							css(isMounted ? animationStyles.bounceInUp : null) +
							" landing-right-container"
						}
					>
						<div className="landing-right-logo">
							<a href="Landing" className="brand-logo">
								<img
									src={logo}
									className="landing-right-brand-logo"
									alt="logo"
								/>
							</a>
						</div>
						<div className="landing-right-welcome">
							{GetPrompts("Home Sidebar Login")}
						</div>

						<div className="landing-right-signin-container">
							<div className="landing-right-signin-username-container">
								<div className="landing-right-signin-text">
									{GetPrompts("Login User tag")}
									{showAuthError ? <IoIosAlert className="error-Item" /> : ""}
								</div>
								<div className="landing-right-signin-input-container">
									<input
										id="landing-username-input"
										className="landing-right-signin-input login-input"
										type="text"
										onChange={(e) => {
											setUsername(e.target.value.toLowerCase());
											setShowAuthError(false);
										}}
									/>
								</div>
							</div>
							<div className="landing-right-signin-username-cotnainer">
								<div className="landing-right-signin-text">
									{GetPrompts("Login Pwd tag")}
									<a
										className="show-pwd-btn"
										onClick={() => {
											setShowPwd(!showPwd);
										}}
									>
										<IoIosEye />
									</a>
									{showAuthError ? <IoIosAlert className="error-Item" /> : ""}
								</div>
								<div className="landing-right-signin-input-container">
									<input
										id="landing-password-input"
										className="landing-right-signin-input login-input"
										type={showPwd ? "text" : "password"}
										onChange={(e) => {
											setPwd(e.target.value);
											setShowAuthError(false);
										}}
										onKeyDown={HandleKeyDown}
									/>
								</div>
							</div>

							<div
								className="landing-right-signin-forgot-pwd"
								onClick={() => {
									setShowForgotPwd(true);
								}}
							>
								{GetPrompts("Login forgot Pwd tag")}
							</div>
						</div>

						{!showIsloading ? (
							<>
								<div className="landing-right-google-login-btn">
									<GoogleLoginComponent />
								</div>
								<br />
								<div
									id="landing-login-btn"
									className="landing-right-login-btn hover-brighten"
									onClick={() => {
										setShowIsloading(true);
										authenticate(
											username,
											pwd,
											setShowAuthError,
											setAuthenticated,
											setShowIsloading
										);
									}}
								>
									<p className="landing-right-login-btn-text">
										{GetPrompts("Home Sidebar Login")}
									</p>
								</div>
							</>
						) : (
							""
						)}

						<div className="landing-right-dont-have-account">
							<span className="landing-right-dont-have-account-text">
								{GetPrompts("Dont have an account")}
							</span>
							<span
								className="landing-right-dont-have-account-signup"
								onClick={() => {
									setOpenStep1(true);
								}}
							>
								{GetPrompts("Home Sidebar Register")}
							</span>
						</div>
					</div>
					<ForgotPwd
						open={showForgotPwd}
						onClose={() => setShowForgotPwd(false)}
					/>
				</div>
			) : (
				""
			)}

			<SignUpStep1
				open={openStep1}
				onClose={() => setOpenStep1(false)}
				prevStep={() => {
					return;
				}}
				nextStep={() => setOpenStep2(true)}
			/>
			<SignUpStep2
				open={openStep2}
				prevStep={() => setOpenStep1(true)}
				onClose={() => setOpenStep2(false)}
				nextStep={() => {
					return;
				}}
			/>
			<ErrorPopUp
				open={errorPopUpIsOpen}
				onClose={() => setErrorPopUpIsOpen(false)}
				errorMessage={errorMessage}
			/>
		</>
	);
}
