import React, { useContext, useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { IoIosAlert, IoIosEye, IoMdArrowBack } from "react-icons/io"
import { useNavigate } from "react-router-dom"
import HomePicture from "../../Assets/HomePicture.png"
import ErrorPopUp from "../../Components/Errors/ErrorPopUp"
import { GetPrompts } from "../../Components/Prompts/PromptsComponent"
import SidebarHome from "../../Components/SidebarHome/SidebarHome"
import { SPINNER_STYLES } from "../../Components/Spinner/SpinnerStyles"
import SignUpStep1 from "../SignUp/SignUpStep1"
import SignUpStep2 from "../SignUp/SignUpStep2"
import { authenticate } from "./Authenticate"
import "./Login_Mobile.css"
import { Context } from "../../Context/Contexts"
import IsLoadingComponent from "../../Components/Isloading/IsLoadingComponent"
import GoogleLoginComponent from "../../Components/Oauth/GoogleAuthentication/LoginComponent"
export default function Login_Mobile() {
  const navigate = useNavigate()
  const [openStep1, setOpenStep1] = useState(false)
  const [openStep2, setOpenStep2] = useState(false)
  const { showIsloading, setShowIsloading } = useContext(Context)
  const [authenticated, setAuthenticated] = useState(false)
  const [showAuthError, setShowAuthError] = useState(false)
  const [username, setUsername] = useState("")
  const [pwd, setPwd] = useState("")
  const [showPwd, setShowPwd] = useState(false)

  const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const handleUsernameChange = (e) => {
    setUsername(e)
  }
  const handlePwdChange = (e) => {
    setPwd(e)
  }

  const HandleKeyDown = (e) => {
    if (e.key == "Enter") {
      setShowIsloading(true)
      authenticate(
        username,
        pwd,
        setShowAuthError,
        setAuthenticated,
        setShowIsloading
      )
    }
  }

  const STYLES = {
    height: window.innerHeight,
  }

  useEffect(() => {
    if (showAuthError) {
      setErrorMessage(GetPrompts("Login Error"))
      setErrorPopUpIsOpen(true)
    }
  }, [showAuthError])

  useEffect(() => {
    if (authenticated) {
      navigate("/home")
    }
  }, [authenticated])

  return (
    <>
      <SidebarHome />
      <div className="login-mobile-container" style={STYLES}>
        <div className="landing-mobile-background-picture">
          <img
            id="home-pictures-landing"
            src={HomePicture}
            alt="{home-pictures-landing}"
          />
        </div>

        <div className="login-mobile-text">
          {GetPrompts("SignUp Title Text")} {GetPrompts("SignUp Pararaph Text")}
        </div>
        <div
          className="login-mobile-back"
          onClick={() => {
            navigate("/landing_mobile")
          }}
        >
          <IoMdArrowBack />
        </div>
        <div className="login-mobile-title-text">
          {GetPrompts("Enter your information")}
        </div>

        <div className="login-mobile-form-container">
          <div className="login-mobile-form-item">
            <span className="login-mobile-label">
              {GetPrompts("Login User tag")}
              {showAuthError ? <IoIosAlert className="error-Item" /> : ""}
            </span>
            <input
              type="text"
              className="login-mobile-input"
              onChange={(e) => {
                setShowAuthError(false)
                handleUsernameChange(e.target.value)
              }}
            />
          </div>
          <div className="login-mobile-form-item">
            <span className="login-mobile-label">
              {GetPrompts("Login Pwd tag")}
              {showAuthError ? <IoIosAlert className="error-Item" /> : ""}
              <a
                className="show-pwd-btn-black"
                onClick={() => {
                  setShowPwd(!showPwd)
                }}
              >
                <IoIosEye />
              </a>
            </span>
            <input
              type={showPwd ? "text" : "password"}
              className="login-mobile-input"
              onChange={(e) => {
                setShowAuthError(false)
                handlePwdChange(e.target.value)
              }}
              onKeyDown={HandleKeyDown}
            />
          </div>

          <br />
          <div className="landing-right-google-login-btn">
            <GoogleLoginComponent />
          </div>
        </div>

        <div className="login-mobile-btn-container">
          <div className="login-mobile-has-account-container">
            <span className="login-mobile-has-account">
              {GetPrompts("Dont have an account")}
            </span>{" "}
            <span
              className="login-mobile-login"
              onClick={() => {
                setOpenStep1(true)
              }}
            >
              {GetPrompts("Home Sidebar Register")}
            </span>
          </div>

          <div className="login-mobile-btn-container-background">
            <div
              className="login-mobile-btn-login"
              onClick={() => {
                setShowIsloading(true)
                authenticate(
                  username,
                  pwd,
                  setShowAuthError,
                  setAuthenticated,
                  setShowIsloading
                )
              }}
            >
              {GetPrompts("Login enter tag")}
            </div>
          </div>
        </div>
      </div>

      <ErrorPopUp
        open={errorPopUpIsOpen}
        onClose={() => setErrorPopUpIsOpen(false)}
        errorMessage={errorMessage}
      />
      <SignUpStep1
        open={openStep1}
        onClose={() => setOpenStep1(false)}
        prevStep={() => {
          return
        }}
        nextStep={() => setOpenStep2(true)}
      />
      <SignUpStep2
        open={openStep2}
        prevStep={() => setOpenStep1(true)}
        onClose={() => setOpenStep2(false)}
        nextStep={() => {
          return
        }}
      />
    </>
  )
}
