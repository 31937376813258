import moment from "moment";
import React from "react";
import "./PlannerHome_Card.css";

export function PlannerHome_Card({ activityItem }) {
  const keyProp = activityItem.activityList[0].plannerId
  return (
    <>
      <div
        key={`planner-home-card-container-key-${keyProp}`}
        className="planner-home-card-container"
      >
        <div
          key={`planner-home-card-activity-date-key-${keyProp}`}
          className="planner-home-card-activity-date planner-home-card-activity-date-container"
        >
          <span className="planner-home-card-activity-date-text">
            {moment(activityItem.date).format("DD")}
          </span>
          <span className="planner-home-card-activity-date-line"></span>
        </div>
        <div className="planner-home-card-activity-details-container">
          {activityItem.activityList.map((activityDetail) => {
            return (
              <>
                <div
                  key={`planner-home-card-activity-description-key-${keyProp}`}
                  className="planner-home-card-activity-description table-row-header"
                >
                  {activityDetail.activity.description}
                  <br />
                  <p className="planner-home-card-activity-description-comment table-row-item">
                    {activityDetail.comments}
                  </p>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}
