import axios from "../../axios";
export const CreateActivity = async (activityDescription, schoolSubjectId) => {
  try {
    const url = `Activity/api/v1/CreateNewActivity`

    const response = await axios.put(url, {
      UserId: 0,
      Description: activityDescription,
      SchoolSubjectId: schoolSubjectId,
    });
  } catch (error) {}
};
