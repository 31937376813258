import React from "react";
import * as IoIcons from "react-icons/io";
import "./TutorialLink.css";
import { GetPrompts } from "../Prompts/PromptsComponent";

export function TutorialLink_Home() {
	const ClickHandler = () => {
		window.open("https://www.youtube.com/watch?v=1dzU3erWgY8");
	};
	return (
		<>
			<div
				className="tutorial-link-home-container"
				onClick={() => {
					ClickHandler();
				}}
			>
				<div className="tutorial-link-home-icon">
					<IoIcons.IoMdPlay />
				</div>
				<div className="tutorial-link-home-text sidebar-menu-item">
					{GetPrompts("Planning tutorial")}
				</div>
			</div>
		</>
	);
}
