import React, { useEffect, useState } from "react";
import "./ProgressBarSlider.css";
export default function ProgressBarSlider({ progressBarPositionChange, url }) {
  const [progress, setProgress] = useState(0);
  const [muted, setMuted] = useState(false);

  const finalProgress = muted ? 0 : progress ** 2;

  useEffect(() => {
    setProgress(0);
  }, [url]);

  useEffect(() => {
    progressBarPositionChange(progress);
  }, [progress]);

  return (
    <article>
      <div>
        <div className="progress-bar-slider">
          <input
            className="progress-bar-slider-input"
            type="range"
            min={0}
            max={1}
            step={0.02}
            value={progress}
            onChange={(event) => {
              setProgress(event.target.valueAsNumber);
            }}
          />
          <div>
            <p>Position: {finalProgress.toFixed(3)}</p>
          </div>
        </div>
      </div>
    </article>
  );
}
