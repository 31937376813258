import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import * as IoIcons from "react-icons/io";
import { IoIosAlert, IoIosEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ForgotPwd from "../../Components/ForgotPwd/ForgotPwd";
import InfoPopup from "../../Components/InfoPopup/InfoPopup";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import SidebarHome from "../../Components/SidebarHome/SidebarHome";
import { SPINNER_STYLES } from "../../Components/Spinner/SpinnerStyles";
import { Context } from "../../Context/Contexts";
import Login from "../Login/Login";
import "./SignUp.css";
import { CreateNewClient } from "./signUpUser";

const SignUp = () => {
  const lang = sessionStorage.getItem("_lang");
  const { showForgotPwd, setShowForgotPwd } = useContext(Context);
  const { setShowFooter } = useContext(Context);
  const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);
  const { loinIsOpen, setLoinIsOpen } = useContext(Context);
  const [showAuthError, setShowAuthError] = useState(false);
  const [showPwd, setShowPwd] = useState(false);

  const [promptMessage, setPromptMessage] = useState("");
  const [promptMessageIsOpen, setPromptMessageIsOpen] = useState(false);
  const [completedPromptIsOpen, setCompletedPromptIsOpen] = useState(false);

  useEffect(() => {
    setShowFooter(false);
    setShowNavbarItems(false);
    setShowSidebarToggle(true);
  });

  useEffect(() => {
    setShowNavbarItems(false);
  });

  const ShowPwdClickHandler = () => {
    setShowPwd(!showPwd);
  };

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [pwdNotMatching, setPwdNotMatching] = useState(false);
  const [pwdTooShort, setPwdTooShort] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const { setShowIsloading } = useContext(Context)

  const signUpUser = async (e) => {
    if ((name == "" || lastName) == "" || email == "") {
      setPromptMessage(GetPrompts("We need name and last name"))
      setPromptMessageIsOpen(true)
      return
    }

    if (pwdNotMatching) {
      setPromptMessage(GetPrompts("Pwd and confirmation not matching"))
      setPromptMessageIsOpen(true)
      return
    } else if (pwd.length < 8) {
      setPromptMessage(GetPrompts("Pwd too short popup Text"))
      setPromptMessageIsOpen(true)
      return
    }

    setShowIsloading(true)
    CreateNewClient(email, name, lastName, pwd, lang).then((response) => {
      setShowIsloading(false)
      setPromptMessage(GetPrompts("Sign up sent Text"))
      setCompletedPromptIsOpen(true)
    })
  }

  const GoToHome = () => {
    setCompletedPromptIsOpen(false);
    setPromptMessageIsOpen(false);
    navigate("/Landing");
  };
  useEffect(() => {
    setPwdNotMatching(false);
    if ((confirmPwd != "") & (confirmPwd != pwd)) {
      setPwdNotMatching(true);
    }
  }, [confirmPwd]);

  useEffect(() => {
    setPwdTooShort(false);
    if (pwd.length < 8 && pwd != "") {
      setPwdTooShort(true);
    }
  }, [pwd]);

  return (
    <>
      <SidebarHome />
      <div className="container ">
        <div className="signup-grid-container">
          <div className="signup-title-text">
            {GetPrompts("SignUp Title Text")}
          </div>

          <p className="signup-paragraph-text">
            {GetPrompts("SignUp Pararaph Text")}
          </p>

          <div className="signup_form">
            <label className="form-label">{GetPrompts("Name text")}:</label>
            <input
              className="form-input"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <label className="form-label">
              {GetPrompts("Last Name text")}:
            </label>
            <input
              className="form-input"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />

            <label className="form-label">{GetPrompts("Email text")}:</label>
            <input
              className="form-input"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <label className="form-label">
              {GetPrompts("Login Pwd tag")}:
              {showAuthError ? <IoIosAlert className="error-Item" /> : ""}
              <a className="show-pwd-btn" onClick={ShowPwdClickHandler}>
                <IoIosEye />
              </a>
              {pwdTooShort ? (
                <IoIosAlert
                  data-tip
                  data-for="pwd-input"
                  className="error-Item"
                />
              ) : (
                ""
              )}
            </label>
            <input
              className="form-input"
              type={showPwd ? "text" : "password"}
              value={pwd}
              onChange={(e) => setPwd(e.target.value)}
            />

            <label className="form-label">
              {GetPrompts("SignUp Confirm Pwd Text")}:
              {pwdNotMatching ? (
                <IoIosAlert
                  data-tip
                  data-for="pwd-confirm-input"
                  className="error-Item"
                />
              ) : (
                ""
              )}
            </label>
            <input
              className="form-input"
              type={showPwd ? "text" : "password"}
              value={confirmPwd}
              onChange={(e) => setConfirmPwd(e.target.value)}
            />

            <>
              {pwdNotMatching && (
                <ReactTooltip id="pwd-confirm-input" place="top" effect="solid">
                  {GetPrompts("Pwd confirmation must match Text")}
                </ReactTooltip>
              )}

              {pwdTooShort && (
                <ReactTooltip id="pwd-input" place="top" effect="solid">
                  {GetPrompts("Pwd too short Text")}
                </ReactTooltip>
              )}
            </>

            {isloading ? (
              <div style={SPINNER_STYLES} className="loading-label">
                {GetPrompts("Sending Text")}
                <Spinner />
              </div>
            ) : (
              ""
            )}

            <div className="signup-btn-container">
              <button
                className="signup-btn"
                onClick={() => {
                  signUpUser();
                }}
              >
                {GetPrompts("Home page Register")}
              </button>
            </div>
          </div>
        </div>

        <InfoPopup
          icon={<IoIcons.IoMdAlert />}
          open={promptMessageIsOpen}
          onClose={() => {
            setPromptMessageIsOpen(false);
          }}
          errorMessage={promptMessage}
        />

        <InfoPopup
          icon={<IoIcons.IoMdAlert />}
          open={completedPromptIsOpen}
          onClose={() => {
            GoToHome();
          }}
          errorMessage={promptMessage}
        />
      </div>

      <Login open={loinIsOpen} onClose={() => setLoinIsOpen(false)} />
      <ForgotPwd open={showForgotPwd} onClose={() => setShowForgotPwd(false)} />
    </>
  );
};

export default SignUp;
