import { useContext } from "react";
import { Context } from "../../../../../Context/Contexts";
import { GetPrompts } from "../../../../Prompts/PromptsComponent";
import "./PlanPerDayCustomBlockData.css";

export function WeekBlockHeaderData({
	periodTitle,
	periodNumber,
	weekNumber,
	weekTitle = undefined,
}) {
	var weekTitleToShow =
		weekTitle !== undefined && weekTitle !== null
			? weekTitle
			: "Semana de receso";

	const { selectedChildGlobal } = useContext(Context);
	const lang = sessionStorage.getItem("_lang");
	return (
		<>
			<div className="WeekBlockHeaderData  base-parafraph">
				{selectedChildGlobal !== undefined
					? `${selectedChildGlobal.firstName} ${
							selectedChildGlobal.lastName
					  }, ${selectedChildGlobal.planYear[`description${lang}`]},
					  
					   ${
								weekNumber === 0
									? weekTitleToShow
									: `${periodTitle} ${periodNumber}, ${GetPrompts(
											"Week"
									  )} ${weekNumber}`
							}`
					: ""}
			</div>
		</>
	);
}
