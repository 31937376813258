import React, { useContext, useState } from "react";
import "./CalendarPeriodWeeks.css";
import moment from "moment";
import { CreateSkipWeek } from "./SkipWeek/CreateSkipWeek";
import { DeleteSkipWeek } from "./SkipWeek/DeleteSkipWeek";
import { Context } from "../../../Context/Contexts";
import SkipWeekUpdate from "./SkipWeek/UpdateSkipWeek/SkipWeekUpdate";
import * as IoIcons from "react-icons/io";

export default function CalendarPeriodWeeks({
	periodDataWeeks,
	getPeriodData,
}) {
	const { setShowIsloading } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const [showUpdateSkipWeek, setShowUpdateSkipWeek] = useState(false);
	const [skipWeekInEdition, setSkipWeekInEdition] = useState("");
	const [currentSkipTextToShow, setCurrentSkipTextToShow] = useState("");

	if (periodDataWeeks === undefined || periodDataWeeks === "") {
		return;
	}

	const skipWeek = (calendarWeek) => {
		setShowIsloading(true);
		CreateSkipWeek({ calendarWeek: calendarWeek }).then((response) => {
			getPeriodData();
			setShowIsloading(false);
			setShowDonecomponent(true);
		});
	};

	const deleteSkippedWeek = (calendarWeek) => {
		setShowIsloading(true);
		DeleteSkipWeek({ calendarWeek: calendarWeek }).then((response) => {
			getPeriodData();
			setShowIsloading(false);
			setShowDonecomponent(true);
		});
	};

	const skippedWeeks = periodDataWeeks.filter((weekItem) => weekItem.isSkipped);

	return (
		<>
			<div className="calendar-periods-label table-row-header">Semanas</div>
			<div className="calendar-period-weeks-container">
				{periodDataWeeks.map((weekItem) => {
					return (
						<div
							className={`calendar-period-weeks-card ${
								weekItem.isSkipped ? "calendar-period-weeks-skip-card" : ""
							}`}
							onClick={() => {
								if (weekItem.isSkipped == false) {
									skipWeek(weekItem.periodWeek);
								}
							}}
						>
							<div className={`base-parafraph`}>
								{moment(weekItem.mondayOfWeek).format("DD-MMM")}
							</div>
							{weekItem.isSkipped ? (
								<>
									<div className="calendar-periods-skiped-week-container">
										<span className="calendar-periods-skiped-week-undo hover-brighten">
											<IoIcons.IoMdUndo
												onClick={(event) => {
													event.stopPropagation();
													deleteSkippedWeek(weekItem.periodWeek);
												}}
											></IoIcons.IoMdUndo>
										</span>
										<span className="calendar-periods-skiped-week-edit hover-brighten">
											<IoIcons.IoMdCreate
												onClick={(event) => {
													event.stopPropagation();
													setSkipWeekInEdition(weekItem);
													setCurrentSkipTextToShow(weekItem.skipTitle);
													setShowUpdateSkipWeek(true);
												}}
											></IoIcons.IoMdCreate>
										</span>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					);
				})}
			</div>
			{skippedWeeks.length > 0 && (
				<div className="calendar-period-weeks-remarks">
					<div className="calendar-period-weeks-remarks-title base-parafraph">
						Semanas marcadas como receso:
					</div>
					<div className="calendar-period-weeks-remarks-weeks base-parafraph">
						{skippedWeeks.map((weekItem, index) => {
							return (
								<span className={`base-parafraph`}>
									{moment(weekItem.mondayOfWeek).format("DD-MMM")}
									{index < skippedWeeks.length - 1 ? ", " : "."}
								</span>
							);
						})}
					</div>
				</div>
			)}

			<SkipWeekUpdate
				skipWeekInEdition={skipWeekInEdition}
				isOpen={showUpdateSkipWeek}
				closeHandler={() => {
					setShowUpdateSkipWeek(false);
				}}
				currentSkipTextToShow={currentSkipTextToShow}
				callBack={() => {
					getPeriodData();
					setShowUpdateSkipWeek(false);
				}}
			/>
		</>
	);
}
