import React from "react"
import "./PrivacyPolicy.css"
import SidebarHome from "../../Components/SidebarHome/SidebarHome"

const PrivacyPolicy = () => {
  return (
    <>
      <SidebarHome />
      <div className="privacy-policy">
        {/* <h1>Privacy Policy</h1> */}
        <p>
          This Privacy Policy explains how we collect, use, and disclose your
          personal information when you use our app.
        </p>

        <h2>Information We Collect</h2>
        <p>
          We may collect certain information that you voluntarily provide to us,
          such as your name, email address, and other contact information when
          you use our app.
        </p>

        <h2>How We Use Your Information</h2>
        <p>
          We may use the information we collect from you to provide and maintain
          our app, improve our services, and communicate with you.
        </p>

        <h2>Disclosure of Your Information</h2>
        <p>
          We may disclose your personal information to third parties only as
          required by law or to comply with legal obligations.
        </p>

        <h2>Security</h2>
        <p>
          We take reasonable measures to protect your personal information, but
          we cannot guarantee its absolute security.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. You are advised to
          review this Privacy Policy periodically for any changes.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at support@charlottemasonalamano.com.
        </p>
      </div>
    </>
  )
}

export default PrivacyPolicy
