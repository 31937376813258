import { faPerson } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import * as IoIcons from "react-icons/io";

export default function ChildrenSelectorCard({
  child,
  SelectedChild,
  showAsSelected = false,
  iconToShow = faPerson,
  iconClasssname,
}) {
  return (
    <>
      <div
        className="children-selector-card-container"
        onClick={() => SelectedChild(child.planChildId)}
      >
        <div className="children-selector-card">
          <p className="children-selector-card-icon">
            <FontAwesomeIcon icon={iconToShow} className={iconClasssname} />
          </p>

          <p className="children-selector-card-name base-sub-title">
            {child.firstName} {showAsSelected ? <IoIcons.IoMdCheckmark /> : ""}
          </p>
        </div>
      </div>
    </>
  )
}
