import React from "react";
import * as IoIcons from "react-icons/io";
import "./YearPlanActivitiesSelectorCard.css";

export default function YearPlanActivitiesSelectorCard(
  activity,
  SelectActivity,
  showAsSelected = false
) {
  return (
    <>
      <div
        className="year-plan-activities-selector-card-container"
        onClick={() => {
          SelectActivity(activity.activity)
        }}
      >
        <div className="year-plan-activities-selector-card-description base-sub-title">
          {activity.activity.description}{" "}
          {showAsSelected ? <IoIcons.IoMdCheckmark /> : ""}
        </div>
        <div className="year-plan-activities-selector-card-description-hint base-parafraph">
          {activity.activity.descriptionHint}
        </div>
      </div>
    </>
  )
}
