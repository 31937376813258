import Moment from "moment";
import React, { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import Pagination from "../../../Pagination/Pagination";
import { GetOverallPlannedReport } from "../GetOverallPlannedReport";
import "../PlannerReportTable.css";
import "./ActivityChildReport.css";

export default function ActivityChildReport() {
  const [activitiesList, serActivitiesList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, SetTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(1000);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const GetActivitiesList = () => {
    GetOverallPlannedReport({
      currentPage,
      itemsPerPage,
      fromDate,
      toDate,
    }).then(function (response) {
      serActivitiesList(response.result);
      SetTotalPages(response.pages);
    });
  };

  const FromDateEditionHandler = (e) => {
    setFromDate(new Date(e));
  };
  const ToDateEditionHandler = (e) => {
    setToDate(new Date(e));
  };

  useEffect(() => {
    GetActivitiesList();
  }, [currentPage]);

  return (
    <>
      <div className="container activity-child-report-container">
        <div className="report-param-container">
          <div className="report-param-from-to-container">
            <div className="report-from-date-item">
              <div className="report-from-date-label">Desde</div>
              <div className="report-from-date">
                <DateTimePicker
                  className={"picker-report-from-date"}
                  onChange={(e) => FromDateEditionHandler(e)}
                  format={"dd/MMM/yy"}
                  value={fromDate}
                  clearIcon={null}
                  calendarIcon={null}
                />
              </div>
            </div>

            <div className="report-to-date-item">
              <div className="picker-report-to-date-label">Hasta</div>
              <div className="report-to-date">
                <DateTimePicker
                  className={"picker-report-to-date"}
                  onChange={(e) => ToDateEditionHandler(e)}
                  format={"dd/MMM/yyyy"}
                  value={toDate}
                  clearIcon={null}
                  calendarIcon={null}
                />
              </div>
            </div>
          </div>

          <div className="activity-child-report-btn">
            <div className="report-getreport-item">
              <div
                className="do-not-print report-getreport-btn"
                onClick={GetActivitiesList}
              >
                Ver reporte
              </div>
            </div>
            <div className="report-print-item">
              <div
                className="do-not-print report-print-item-btn"
                onClick={() => {
                  window.print();
                }}
              >
                Imprimir
              </div>
            </div>
          </div>
        </div>
        <div className="activity-child-report-table">
          <table>
            <thead>
              <tr>
                <th>Actividad</th>
                <th>Estudiante</th>
                <th>Fecha</th>
              </tr>
            </thead>

            <tbody>
              {activitiesList.map((activity) => {
                return (
                  <tr className="activity-child-report-row">
                    <td>
                      <div className="activity-child-report-row-activity">
                        {activity.activity.description}
                      </div>
                      <div className="activity-child-report-row-comments">
                        {activity.comments}
                      </div>
                    </td>
                    <td>
                      <div className="activity-child-report-row-name">
                        {activity.planerChild.firstName}{" "}
                        {activity.planerChild.lastName}
                      </div>
                    </td>
                    <td>{Moment(activity.date).format("MMM-DD-YY")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="do-not-print">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
}
