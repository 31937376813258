import * as IoIcons from "react-icons/io";
import { AddItemToCart } from "../Cart/AddItemToCart";
function ProductCard(
  product,
  navigate,
  UpdateCart,
  IsPurchasedProduct,
  setAddedToCartConfirmationDlg,
  setShowIsloading,
  GetPrompts
) {
  const lang = sessionStorage.getItem("_lang")

  const { productId, productCode, description, picturePath, navPath } = product

  const AddToCart = () => {
    setShowIsloading(true)
    AddItemToCart({ productId }).then((response) => {
      UpdateCart()
      setShowIsloading(false)
      setAddedToCartConfirmationDlg(true)
    })
  }

  const SoonMsg = () => {}

  const GoToModule = (productId) => {
    if (IsActiveDuringLaunching(productId)) {
      navigate(`/${navPath}`)
    }
  }

  return (
    <article key={productId}>
      <div
        className="product"
        onClick={() => {
          GoToModule(productId)
        }}
      >
        <div className="product-header">{`${
          product[`productTitle${lang}`]
        }`}</div>
        <div className="product-picture">
          <img src={picturePath} alt="" />
        </div>
        <div className="product-body">{`${product[`description${lang}`]}`}</div>

        <div className="product-footer">
          {IsPurchasedProduct(productId) ||
          IsActiveDuringLaunching(productId) ? (
            <>
              <div
                className="active-product-status"
                onClick={() => {
                  navigate(`/${navPath}`)
                }}
              >
                <span>
                  <IoIcons.IoMdCheckmark />
                </span>
                {GetPrompts("Product Active")}
              </div>
            </>
          ) : (
            <>
              {!IsActiveDuringLaunching(productId) ? (
                <div className="available-product-status" onClick={SoonMsg}>
                  {GetPrompts("Product Soon")}
                </div>
              ) : (
                <div className="available-product-status" onClick={AddToCart}>
                  {GetPrompts("Product To Cart")}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </article>
  )
}

const IsActiveDuringLaunching = (productId) => {
  if (productId == 1 || productId == 2) {
    return true
  }
  return false;
};
export default ProductCard;
