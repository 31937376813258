import Moment from "moment";
import axios from "../../../../axios";
export async function GetWeeklyScheduleList({
  weeklyScheduleDate = "",
  childId = 0,
}) {
  const url = "Planner/api/v1/GetWeeklySchedule"
  try {
    const response = await axios.get(url, {
      params: {
        UserId: 0,
        CurrentDate: Moment(weeklyScheduleDate).format("MM/DD/YYYY"),
        ChildId: childId,
      },
    })

    return response.data.result
  } catch (error) {}
}
