import React from "react";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import "./InfoPopup.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./InfoPopup_ModalStyle";
export default function InfoPopup({ icon, open, onClose, errorMessage }) {
  if (!open) return null;
  return (
		<article>
			<div style={OVERLAY_STYLES} onClick={onClose}></div>
			<div
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
				className="infopopup-container"
			>
				<div className="infopopup-row-content">
					<button
						id="infopopup-close-btn"
						className="infopopup-close-btn"
						onClick={onClose}
					>
						X
					</button>
				</div>
				<div className="infopopup-icon">{icon}</div>
				<div className="infopopup-message base-sub-title">{errorMessage}</div>
			</div>
		</article>
	);
}
