import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetAllPlaylistsByUser } from "../../Components/Playlist/GetAllPlaylistsByUser";
import PlaylistCard from "../../Components/Playlist/PlaylistCard";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Context } from "../../Context/Contexts";
import "./Courses.css";

export default function Courses() {
  const navigate = useNavigate();
  const [playlists, setPlaylists] = useState([]);

  const { setShowFooter } = useContext(Context);
  const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);

  useEffect(() => {
    setShowFooter(false);
    setShowNavbarItems(false);
    setShowSidebarToggle(true);
  });

  useEffect(() => {
    GetAllPlaylistsByUser().then(function (response) {
      setPlaylists(response);
    });
  }, [1]);

  return (
    <>
      <Sidebar />
      <div className="container courses-container">
        {playlists.map((playlist) => {
          return PlaylistCard(playlist, navigate);
        })}
      </div>
    </>
  );
}
