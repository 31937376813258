import axios from "../../axios"
export async function AddMembershipToCart(membershipId) {
  const url = "Membership/api/v1/AddMembershipToCart"
  try {
    const response = await axios.put(url, {
      UserId: 0,
      MembershipId: membershipId,
    })
    return response.data;
  } catch (error) {}
}
