import axios from "../../axios";
export async function GetFilteredSchoolSubjects({
  currentPage = 1,
  itemsPerPage = 10,
  filterBySchoolSubject,
}) {
  const filteredUrl = "SchoolSubject/api/v1/GetFilteredSchoolSubjectsPaged"
  try {
    const response = await axios.post(filteredUrl, {
      CurrentPage: currentPage,
      ItemsPerPage: itemsPerPage,
      Description: filterBySchoolSubject,
    });
    return response.data;
  } catch (error) {}
}
