import { useContext, useEffect } from "react";
import ChildrenList from "../../Components/Children/ChildrenList";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Context } from "../../Context/Contexts";
import "./Children.css";

export default function Children() {
  const { setShowFooter } = useContext(Context);
  const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);
  useEffect(() => {
    setShowFooter(false);
    setShowNavbarItems(false);
    setShowSidebarToggle(true);
  });

  return (
    <>
      <Sidebar />
      <div className="basic-page-title">{GetPrompts("My Students Text")}</div>
      <ChildrenList />
    </>
  );
}
