import React, { useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import YesNoConfirm from "../../../ModalConfirm/YesNoConfirm";
import { RESPONSIVE_MODAL_STYLES } from "../../../Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../../../Prompts/PromptsComponent";
import { GetAllYearActivityNotesByActivity } from "./GetAllYearActivityNotesByActivity";
import NewYearActivityNoteCard from "./NewYearActivityNoteCard/NewYearActivityNoteCard";
import UpdateYearActivityNoteCard from "./UpdateYearActivityNoteCard/UpdateYearActivityNoteCard";
import { DeleteNewYearActivityNote } from "./YearActivityNoteCard/DeleteNewYearActivityNote";
import YearActivityNotesCard from "./YearActivityNoteCard/YearActivityNoteCard";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./YearActivityNotes.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./YearActivityNotes_ModalStyle";
export default function YearActivityNotes({ open, onClose, activityId }) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [notesList, setNotesList] = useState([]);
	const [noteToDelete, setNoteToDelete] = useState("");
	const [noteToEdit, setNoteToEdit] = useState("");

	const [newYearActivityNoteCardIsOpen, setNewYearActivityNoteCardIsOpen] =
		useState(false);

	const [
		updateYearActivityNoteCardIsOpen,
		setUpdateYearActivityNoteCardIsOpen,
	] = useState(false);

	const GetNotes = () => {
		setIsLoaded(false);
		GetAllYearActivityNotesByActivity({ activityId }).then((response) => {
			setNotesList(response);
			setIsLoaded(true);
		});
	};

	const CloseClickHandler = () => {
		onClose();
	};

	const [yesNoOpen, setYesNoOpen] = useState(false);

	const DeleteNote = (note) => {
		setNoteToDelete(note);
		setYesNoOpen(true);
	};

	const EditNote = (note) => {
		setNoteToEdit(note);
		setUpdateYearActivityNoteCardIsOpen(true);
	};

	const DeleteClickHandler = async () => {
		DeleteNewYearActivityNote(noteToDelete.yearActivityNoteId).then(
			(response) => {
				GetNotes();
			}
		);

		setYesNoOpen(false);
	};

	useEffect(() => {
		if (open) {
			GetNotes();
		}
	}, [activityId]);

	if (!open) return null;
	return (
		<>
			<div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>
			<PerfectScrollbar
				className="year-activity-notes-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className="year-activity-notes-close-btn"
					onClick={CloseClickHandler}
				>
					X
				</div>
				<div
					className="year-activity-notes-add-icon"
					onClick={() => {
						setNewYearActivityNoteCardIsOpen(true);
					}}
				>
					<IoIcons.IoMdAddCircle />
				</div>
				<div>
					{notesList.length > 0 ? (
						notesList.map((note) => {
							return (
								<>{YearActivityNotesCard({ note, DeleteNote, EditNote })}</>
							);
						})
					) : isLoaded ? (
						<div className="year-activity-notes-empty-notes-message base-title">
							Aun no tienes apuntes para esta actividad
						</div>
					) : (
						""
					)}
				</div>
			</PerfectScrollbar>

			{newYearActivityNoteCardIsOpen ? (
				<NewYearActivityNoteCard
					open={newYearActivityNoteCardIsOpen}
					onClose={() => {
						setNewYearActivityNoteCardIsOpen(false);
					}}
					activityId={activityId}
					GetNotes={GetNotes}
				/>
			) : (
				""
			)}

			<UpdateYearActivityNoteCard
				open={updateYearActivityNoteCardIsOpen}
				onClose={() => {
					setUpdateYearActivityNoteCardIsOpen(false);
				}}
				noteToEdit={noteToEdit}
				GetNotes={GetNotes}
			/>

			<YesNoConfirm
				dlgOpen={yesNoOpen}
				callBackFunction={DeleteClickHandler}
				onClose={() => setYesNoOpen(false)}
				confirmMessage={GetPrompts("Delete Confirmation Text")}
				centered={true}
			/>
		</>
	);
}
