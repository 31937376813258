import customAxios from "../../../axios";

export async function GetActivitiesDropdowns() {
  const url = "Planner/api/v1/GetPlannedActivitiesDropdowns"
  try {
    const response = await customAxios.get(url);

    return response.data.result;
  } catch (error) {}
}
