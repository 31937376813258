import { useContext } from "react"
import axios from "../../../axios"

export default async function Refresh() {
  try {
    const url = "UserAuth/api/v1/refresh"
    const token = JSON.parse(sessionStorage.getItem("_x"))
    const refreshToken = JSON.parse(sessionStorage.getItem("_c"))

    if (token === null || token === undefined) {
      return
    }
    if (refreshToken === null || refreshToken === undefined) {
      return
    }

    try {
      const response = await axios.post(url, {
        UserId: 0,
        Token: token,
        RefreshToken: refreshToken,
      })

      sessionStorage.setItem("_x", JSON.stringify(response.data.token))
      sessionStorage.setItem("_c", JSON.stringify(response.data.refreshToken))
    } catch (error) {}
  } catch (error) {}
}
