import React, { useContext } from "react";
import * as IoIcons from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { BlogLink_Home } from "../../Components/BlogLink/BlogLink";
import { LogOutLink_Home } from "../../Components/LogOutLink/LogOutLink";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import { Context } from "../../Context/Contexts";
import { UserProfileIcon_Home } from "../UserProfile/UserProfile";
import "./HomePageSidebar.css";
import CartIcon from "../../Components/Cart/CartIcon";
import {
	CustomerSupportIcon,
	CustomerSupportIcon_Home,
} from "../CustomerSupport/CustomerSupport";
import { TutorialLink_Home } from "../../Components/TutorialLink/TutorialLink";
export default function HomePageSidebar() {
	const { setHomeNavBarIsOpen } = useContext(Context);
	const { showUsersInTheWorld, setShowUsersInTheWorld } = useContext(Context);
	const navigate = useNavigate();
	const GotoModule = (module) => {
		navigate(module);
	};
	return (
		<>
			<div className="home-page-sidebar-container">
				<div className="home-page-sidebar-upper">
					<div className="home-page-sidebar-item">
						<div className="home-page-sidebar-item-icon">
							<IoIcons.IoMdCalendar />
						</div>
						<div
							id="home-page-sidebar-planner-btn"
							className="home-page-sidebar-item-text sidebar-menu-item"
							onClick={() => {
								GotoModule("/planner-Year");
							}}
						>
							{GetPrompts("Sidebar Planner")}
						</div>
					</div>

					<div className="home-page-sidebar-item">
						<div className="home-page-sidebar-item-icon">
							<IoIcons.IoMdPeople />
						</div>
						<div
							className="home-page-sidebar-item-text sidebar-menu-item"
							onClick={() => {
								setShowUsersInTheWorld(true);
							}}
						>
							{GetPrompts("Educators in the world")}
						</div>
					</div>

					<div className="home-page-sidebar-item">
						<TutorialLink_Home />
					</div>

					<div className="home-page-sidebar-item">
						<BlogLink_Home />
					</div>

					<div className="home-page-sidebar-item">
						<CustomerSupportIcon_Home />
					</div>

					<div
						className="home-page-sidebar-item"
						onClick={() => {
							GotoModule("/Cart");
						}}
					>
						<CartIcon />
					</div>
				</div>

				<div className="home-page-sidebar-lower">
					<UserProfileIcon_Home />
					<LogOutLink_Home />
				</div>
			</div>
		</>
	);
}
