import React, { useEffect, useState } from "react";
import CalendarPeriodWeeks from "./CalendarPeriodWeeks";
import "./CalendarPeriods.css";
import * as IoIcons from "react-icons/io";
import { GetPrompts } from "../../Prompts/PromptsComponent";

export default function CalendarPeriods({
	periodData,
	setShowAddPeriod,
	setPeriodInEdition,
	getPeriodData,
}) {
	const [weeksData, setWeeksData] = useState("");
	const [periodIdInView, setPeriodIdInView] = useState(0);

	const updateWeeksData = () => {
		const periodItem = periodData.find(
			(item) => item.planCalendarPeriod.calendarPeriodId === periodIdInView
		);
		if (periodItem) {
			setWeeksData(periodItem.periodWeeksResponse);
		} else {
			setWeeksData("");
		}
	};

	useEffect(() => {
		updateWeeksData();
	}, [periodData]);

	return (
		<>
			<div>
				<div>
					<div className="calendar-periods-label table-row-header">
						{GetPrompts("Periods")}:
					</div>
					<div className="calendar-periods-container">
						<div
							className="calendar-periods-card calendar-periods-card-add"
							onClick={() => {
								setShowAddPeriod(true);
							}}
						>
							<div className="calendar-periods-card+add">
								<IoIcons.IoMdAddCircle />
							</div>
						</div>
						{periodData.map((periodItem) => {
							return (
								<div
									className="calendar-periods-card"
									onClick={() => {
										setWeeksData(periodItem.periodWeeksResponse);
										setPeriodIdInView(
											periodItem.planCalendarPeriod.calendarPeriodId
										);
									}}
								>
									<div className="base-parafraph">
										{periodItem.periodNumber}
									</div>
									<div className="calendar-periods-card-edit-icon">
										<IoIcons.IoMdCreate
											onClick={(event) => {
												event.stopPropagation();
												setPeriodInEdition(periodItem);
											}}
										/>
									</div>
								</div>
							);
						})}
					</div>

					<div>
						<CalendarPeriodWeeks
							periodDataWeeks={weeksData}
							getPeriodData={getPeriodData}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
