import React, { useContext } from "react";
import * as IoIcons from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import { Context } from "../../Context/Contexts";
import "./HomePageActivities.css";
export default function HomePageActivities() {
	const navigate = useNavigate();
	const { setHomeNavBarIsOpen } = useContext(Context);

	return (
		<>
			<div className="home-page-activities-container">
				<div className="base-title">{GetPrompts("My Plan")}</div>
				<div className="home-page-activities-card-container">
					<div className="home-page-activities-card-text base-sub-title">
						{GetPrompts("Home plan link description")}
					</div>
					<div className="home-page-activities-card-btn-container">
						<div
							className="home-page-activities-card-btn base-sub-title hover-brighten"
							onClick={() => {
								navigate("/planner-Year");
							}}
						>
							{GetPrompts("View Planner")} <IoIcons.IoMdArrowDropright />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
