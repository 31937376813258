import axios from "../../../../../axios";
export const CreateNewYearActivityNote = async (activityId, note) => {
  try {
    const url = `PlannerYearActivitiesNotes/api/v1/CreateNewYearActivityNote`

    const response = await axios.put(url, {
      UserId: 0,
      Note: note,
      ActivityId: activityId,
    });

    return response.data;
  } catch (error) {}
};
