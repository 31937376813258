import axios from "../../../../../axios";
export const DeleteYearPlanActivity = async (activityId) => {
  try {
    const url = `PlannerYearActivities/api/v1/DeleteYearPlanActivity`

    const response = await axios.post(url, {
      UserId: 0,
      YearActivityId: activityId,
    });
  } catch (error) {}
};
