import courses from "../../../Assets/Products/Courses.jpg";
import mentorship from "../../../Assets/Products/Mentorship.jpg";
import planner from "../../../Assets/Products/Planner.jpg";

export const GetProductPicture = (id) => {
  let picturesList = [
    { id: 2, picture: planner },
    { id: 1, picture: mentorship },
    { id: 4, picture: courses },
  ];

  var item;
  picturesList.filter((val) => {
    if (val.id == id) {
      item = val;
    }
  });

  return item.picture;
};
