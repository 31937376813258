import customAxios from "../../../../axios";

export async function GetPeriodForOneWeek({ requestedDate }) {
	const url = "PlannerCalendarPeriod/api/v1/GetPeriodForOneWeek";
	try {
		const response = await customAxios.get(url, {
			params: {
				UserId: 0,
				RequestedDate: requestedDate,
			},
		});

		return response.data;
	} catch (error) {}
}
