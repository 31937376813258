import customAxios from "../../../../axios";

export async function GetWeekInfo({ requestedDate }) {
	const url = "PlannerCalendarPeriod/api/v1/GetWeekInfo";
	try {
		const response = await customAxios.get(url, {
			params: {
				UserId: 0,
				RequestedDate: requestedDate,
			},
		});

		return response.data.result;
	} catch (error) {}
}
