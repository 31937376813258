import Moment from "moment";

export default function PlannedActivityCard({ plannedActivity, setIsOpen }) {
  const comments = plannedActivity.comments;
  const child = plannedActivity.planerChild.firstName;

  const description = plannedActivity.activity.description;
  const plannedDate = Moment(plannedActivity.date).format("DD/MM/YYYY");
  const fromTime = Moment(plannedActivity.fromTime).format("h:mm a");
  const toTime = Moment(plannedActivity.toTime).format("h:mm a");

  const EditionClickHandler = () => {
    setIsOpen(plannedActivity);
  };

  const GetGroupedChildren = () => {
    var grouped = plannedActivity.grouped;

    if (grouped != null) {
      if (grouped.length > 1) {
        return (
          <>
            <div>
              {grouped.map((activity) => {
                return (
                  <div
                    key={`grouped-child-${activity.planerChild.planChildId}`}
                  >
                    {activity.planerChild.firstName}
                  </div>
                );
              })}
            </div>
          </>
        );
      } else {
        return <>{child}</>;
      }
    } else {
      return <>{child}</>;
    }
  };

  return (
    <article key={plannedActivity.plannerId}>
      <div className="planned-activity-card">
        <div className="planned-activity-title">{description}</div>
        <div className="planned-child-title">{GetGroupedChildren()}</div>
        <div className="planned-comment-title">{comments}</div>

        <div className="planned-card-label planned-activity-date-label">
          Fecha:
        </div>
        <div className="planned-activity-date">{plannedDate}</div>

        <div className="planned-card-label planned-activity-from-time-label">
          Desde:
        </div>
        <div className="planned-activity-from-time">{fromTime}</div>

        <div className="planned-card-label planned-activity-to-time-label">
          Hasta:
        </div>
        <div className="planned-activity-to-time">{toTime}</div>

        <div
          className="basic-edition-btn planned-activity-edit-btn"
          onClick={EditionClickHandler}
        >
          Editar
        </div>
      </div>
    </article>
  );
}
