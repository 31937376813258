import axios from "../../axios";
export async function GetCartItems({ currentPage = 1, itemsPerPage = 10 }) {
  const url = "Cart/api/v1/GetAllUserCartItemsPaged"
  try {
    const response = await axios.get(url, {
      params: {
        UserId: 0,
        CurrentPage: currentPage,
        ItemsPerPage: itemsPerPage,
      },
    });
    return response.data.result;
  } catch (error) {}
}
