import Moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../Context/Contexts";
import { GetPrompts } from "../../../Prompts/PromptsComponent";
import PlanFromSchedule from "../../PlanFromSchedule/PlanFromSchedule";
import { GetWeeklyScheduleListAll } from "../Actions/GetWeeklyScheduleListAll";
import DayOrderList from "../DayOrderList/DayOrderList";
import "./WeeklyScheduleDatatableAll.css";
import { WeeklyScheduleDayHeader } from "./WeeklyScheduleDayHeader";

export function WeeklyScheduleDatatableAll({ weeklyScheduleDate }) {
	const { selectedChildGlobal } = useContext(Context);
	const { setShowIsloading } = useContext(Context);
	const { hideWeekendInCalendar } = useContext(Context);

	const { refreshWeeklySchedule, setRefreshWeeklySchedule } =
		useContext(Context);

	const { showNewPlannedActivity, setShowNewPlannedActivity } =
		useContext(Context);

	const [weeklyScheduleList, setWeeklyScheduleList] = useState([]);
	const [monday, setMonday] = useState([]);
	const [tuesday, setTuesday] = useState([]);
	const [wednesday, setWednesday] = useState([]);
	const [thursday, setThursday] = useState([]);
	const [friday, setFriday] = useState([]);
	const [saturday, setSaturday] = useState([]);
	const [sunday, setSunday] = useState([]);

	const Monday = Moment(weeklyScheduleDate).day(1);
	const Tuesday = Moment(weeklyScheduleDate).day(2);
	const Wednesday = Moment(weeklyScheduleDate).day(3);
	const Thursday = Moment(weeklyScheduleDate).day(4);
	const Friday = Moment(weeklyScheduleDate).day(5);
	const Saturday = Moment(weeklyScheduleDate).day(6);
	const Sunday = Moment(weeklyScheduleDate).day(7);

	useEffect(() => {
		setMonday(weeklyScheduleList.monday);
		setTuesday(weeklyScheduleList.tuesday);
		setWednesday(weeklyScheduleList.wednesday);
		setThursday(weeklyScheduleList.thursday);
		setFriday(weeklyScheduleList.friday);
		setSaturday(weeklyScheduleList.saturday);
		setSunday(weeklyScheduleList.sunday);
	}, [weeklyScheduleList]);

	const GetActivities = () => {
		setShowIsloading(true);
		var childId =
			selectedChildGlobal != undefined ? selectedChildGlobal.planChildId : 0;
		GetWeeklyScheduleListAll({ weeklyScheduleDate, childId }).then(function (
			response
		) {
			setWeeklyScheduleList(response);
			setShowIsloading(false);
		});
	};

	useEffect(() => {
		if (refreshWeeklySchedule == true) {
			setRefreshWeeklySchedule(false);
			GetActivities();
		}
	}, [refreshWeeklySchedule]);

	useEffect(() => {
		setRefreshWeeklySchedule(true);
	}, [selectedChildGlobal]);

	useEffect(() => {
		GetActivities();
	}, [weeklyScheduleDate]);

	return (
		<>
			<div className="weekly-schedule-ordered">
				<div className="weekly-schedule-ordered-day-column">
					<WeeklyScheduleDayHeader
						dayText={GetPrompts("Monday Text")}
						currentDate={Monday}
					/>
					<div>{DayOrderList(monday, GetActivities)}</div>
				</div>
				<div className="weekly-schedule-ordered-day-column">
					<WeeklyScheduleDayHeader
						dayText={GetPrompts("Tuesday Text")}
						currentDate={Tuesday}
					/>

					<div>{DayOrderList(tuesday, GetActivities)}</div>
				</div>
				<div className="weekly-schedule-ordered-day-column">
					<WeeklyScheduleDayHeader
						dayText={GetPrompts("Wednesday Text")}
						currentDate={Wednesday}
					/>
					<div>{DayOrderList(wednesday, GetActivities)}</div>
				</div>
				<div className="weekly-schedule-ordered-day-column">
					<WeeklyScheduleDayHeader
						dayText={GetPrompts("Thursday Text")}
						currentDate={Thursday}
					/>
					<div>{DayOrderList(thursday, GetActivities)}</div>
				</div>
				<div className="weekly-schedule-ordered-day-column">
					<WeeklyScheduleDayHeader
						dayText={GetPrompts("Friday Text")}
						currentDate={Friday}
					/>
					<div>{DayOrderList(friday, GetActivities)}</div>
				</div>
				<>
					<div
						className={`weekly-schedule-ordered-day-column ${
							hideWeekendInCalendar === true ? "hide-weekend" : ""
						}`}
					>
						<WeeklyScheduleDayHeader
							dayText={GetPrompts("Saturday Text")}
							currentDate={Saturday}
						/>
						<div>{DayOrderList(saturday, GetActivities)}</div>
					</div>
					<div
						className={`weekly-schedule-ordered-day-column ${
							hideWeekendInCalendar === true ? "hide-weekend" : ""
						}`}
					>
						<WeeklyScheduleDayHeader
							dayText={GetPrompts("Sunday Text")}
							currentDate={Sunday}
						/>
						<div>{DayOrderList(sunday, GetActivities)}</div>
					</div>
				</>
			</div>

			<PlanFromSchedule
				open={showNewPlannedActivity}
				close={() => {
					setShowNewPlannedActivity(false);
				}}
				defaultDate={weeklyScheduleDate}
			/>
		</>
	);
}
