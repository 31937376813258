import Moment from "moment";
import axios from "../../../axios";
export const DeletePlannedActivityByDateRangeOmitComment = async ({
  activity,
  deleteFromDate,
  deleteToDate,
}) => {
  try {
    const url = `Planner/api/v1/DeletePlannedActivityByDateRangeOmitComment`

    const response = await axios.post(url, {
      UserId: 0,
      ActivityId: activity.activityId,
      DeleteFromDate: Moment(deleteFromDate).format("YYYY-MM-DDT00:00:10"),
      DeleteToDate: Moment(deleteToDate).format("YYYY-MM-DDT11:59:00"),
    });
  } catch (error) {}
};
