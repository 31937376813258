import React, { useRef, useState } from "react";
import { GetStyleToAdjustHeight } from "../../../Components/Helpers/GetStyleToAdjustHeight";
import { ProcessWindowResize } from "../../../Components/Helpers/ProcessWindowResize";

import { YearPlannerSidebar } from "../../../Components/Planner/YearPlan/Sidebar/YearPlannerSidebar";
import YearPlanner from "../../../Components/Planner/YearPlan/YearPlanner";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import "./PlannerYear_Redesign.css";
import { PlannerHome } from '../../../Components/Planner/PlannerHome/PlannerHome'

export default function PlannerYear_Redesign() {
  return (
    <>
      <Sidebar />
      <div className="planner-year-main-component-container">
        <div className="planner-year-main-component-left-container sticky-side-container">
          <YearPlannerSidebar />
        </div>
        <div className="planner-year-main-component-middle-container">
          <YearPlanner />
        </div>
        <div className="planner-year-main-component-right-container">
          <PlannerHome />
        </div>
      </div>
    </>
  )
}
