import "bootstrap/dist/css/bootstrap.min.css";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import ReactPlayer from "react-player";
import ProgressBarSlider from "./ProgressBarSlider";
import "./YouTubeVideoPlayer.css";

export default function YouTubeVideoPlayer(url) {
  const [urlWithTime, setUrlWithTime] = useState("");
  const [duration, setDuration] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [playerWidth, setPlayerWidth] = useState("600px");
  const [playerHeight, setPlayerHeight] = useState("400px");

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    setUrlWithTime(url);
  }, [url]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    switch (true) {
      case windowSize.width < 640:
        setPlayerWidth(`${windowSize.width - 60}px`);
        setPlayerHeight(`${windowSize.width * 0.5}px`);
        break;

      case windowSize.width > 640 && windowSize.width < 1000:
        setPlayerWidth("600px");
        setPlayerHeight(`${windowSize.width * 0.4}px`);
        break;

      case windowSize.width > 1000:
        setPlayerWidth("800px");
        setPlayerHeight(`${windowSize.width * 0.3}px`);
        break;

      default:
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize.width]);

  const ProgressTracker = (e) => {
    setCurrentPosition(e.playedSeconds);
  };

  const OnPause = (e) => {};
  const OnPlay = (e) => {};
  const FinishedVideo = () => {};

  const PlayVideo = () => {
    setPlaying(!playing);
  };

  const BackwardClick = (event) => {
    var setTime = currentPosition;
    setTime = currentPosition - 5;

    if (setTime <= 0) {
      setTime = 1;
    }

    setUrlWithTime(`${url}&t=${parseInt(setTime)}s`);
    setPlaying(true);
  };

  const ForwardClick = (event) => {
    var setTime = currentPosition;
    setTime = currentPosition + 5;

    if (setTime >= duration) {
      setTime = duration - 1;
    }
    setUrlWithTime(`${url}&t=${parseInt(setTime)}s`);
    setPlaying(true);
  };

  const ProgressBarPositionChange = (percent) => {
    if (percent > 0) {
      var newPosition = duration * percent;

      setUrlWithTime(`${url}&t=${parseInt(newPosition)}s`);
      setPlaying(true);
    }
  };

  return (
    <div className="player-wrapper">
      <div
        className="player-overlay-all"
        onClick={() => {
          PlayVideo();
        }}
      ></div>

      <div className="container player-overlay-all-btn">
        <div
          className="player-overlay-all-btn-back"
          onClick={BackwardClick}
        >{`<<`}</div>

        <div
          className="player-overlay-all-btn-play"
          onClick={PlayVideo}
        >{`Play`}</div>

        <div
          className="player-overlay-all-btn-fwd"
          onClick={ForwardClick}
        >{`>>`}</div>
      </div>

      <div className=" container youtube-player">
        <ReactPlayer
          onDuration={(e) => {
            setDuration(e);
          }}
          onEnded={() => FinishedVideo()}
          playing={playing}
          url={urlWithTime == "" ? url : urlWithTime}
          width={playerWidth}
          height={playerHeight}
          controls={false}
          onProgress={(e) => ProgressTracker(e)}
          onPlay={(e) => OnPlay(e)}
          onPause={(e) => {
            OnPause(e);
          }}
        />
        <div className="progressbar-section">
          <div className="progressbar-section-time">
            {Moment.utc(currentPosition * 1000).format("mm:ss")} de{" "}
            {Moment.utc(duration * 1000).format("mm:ss")}
          </div>
          <div className="progressbar-section-tracker">
            <ProgressBar now={(currentPosition / duration) * 100} />
          </div>

          <div className="progressbar-section-controller">
            <ProgressBarSlider
              progressBarPositionChange={ProgressBarPositionChange}
              url={url}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
