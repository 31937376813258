import { Route, Routes } from "react-router-dom";

import PlaylistVideoPlayer from "../Components/Playlist/PlaylistVideoPlayer";
import YouTubeVideoPlayer from "../Components/Video/YouTubeVideoPlayer";
import Activities from "../Pages/Activities/Activities";
import Cart from "../Pages/Cart/Cart";
import Children from "../Pages/Children/Children";
import Courses from "../Pages/Courses/Courses";

import Login from "../Pages/Login/Login";
import Planner from "../Pages/Planner/Planner";
import PlannerYear from "../Pages/Planner/PlannerYear/PlannerYear";
import ActivityChildReportPage from "../Pages/Planner/Reports/ActivityChildReport/ActivityChildReport";

import PlanPerDayReport from "../Components/Planner/Reports/PlanPerDayReport.js/PlanPerDayReport";
import DocumentationMain from "../Pages/Documentation/DocumentationMain";
import HomePage from "../Pages/Home/HomePage";
import Landing from "../Pages/Landing/Landing";
import Landing_Mobile from "../Pages/Landing/Landing_Mobile";
import Login_Mobile from "../Pages/Login/Login_Mobile";
import PlannerReports from "../Pages/Planner/Reports/PlannerReports";
import Products from "../Pages/Products/Products";
import PwdReset from "../Pages/PwdReset/PwdReset";
import SchoolSubjects from "../Pages/SchoolSubjects/SchoolSubjects";
import SignUp from "../Pages/SignUp/SignUp";
import WeeklyScheduleTime_Redesign from "../Pages/WeeklySchedule/WeeklyScheduleTime_Redesign";
import WeeklySchedule_Redesign from "../Pages/WeeklySchedule/WeeklySchedule_Redesign";
import WorldMapUsers_Modal from '../Components/WorldMapUsers/WorldMapUsers_Modal'
import Mentorship from '../Pages/Mentorship/Mentorship'
import PrivacyPolicy from "../Pages/Privacy/PrivacyPolicy"
import PlanPerDayCustomReport from "../Components/Planner/Reports/PlanPerDayReport.js/PlanPerDayCustom/PlanPerDayCustomReport"
import Membership from "../Pages/Membership/Membership"

export function GetContextAppRoutes() {
  return (
		<Routes>
			<Route path="/cgi-sys/defaultwebpage.cgi" element={<Landing />} />
			<Route path="/" element={<Landing />} />
			<Route path="Landing" element={<Landing />} />
			<Route path="Landing_Mobile" element={<Landing_Mobile />} />
			<Route path="login_Mobile" element={<Login_Mobile />} />
			<Route path="Home" element={<HomePage />} />
			<Route path="Products" element={<Products />} />
			<Route path="Cart" element={<Cart />} />
			<Route path="Children" element={<Children />} />
			<Route path="Activities" element={<Activities />} />
			<Route path="SchoolSubjects" element={<SchoolSubjects />} />
			<Route path="Planner" element={<Planner />} />
			<Route path="Planner-Year" element={<PlannerYear />} />
			<Route path="WeeklySchedule" element={<WeeklyScheduleTime_Redesign />} />
			<Route
				path="WeeklyScheduleOrdered"
				element={<WeeklySchedule_Redesign />}
			/>
			<Route path="PlannerReports" element={<PlannerReports />} />
			<Route path="PlanPerDayReport" element={<PlanPerDayReport />} />
			<Route
				path="PlanPerDayCustomReport"
				element={<PlanPerDayCustomReport />}
			/>
			<Route path="ActivityChildReport" element={<ActivityChildReportPage />} />
			<Route path="Login" element={<Login />} />
			<Route path="SignUp" element={<SignUp />} />
			<Route path="PwdReset" element={<PwdReset />} />
			<Route path="YouTubeVideoPlayer" element={<YouTubeVideoPlayer />} />
			<Route path="Courses" element={<Courses />} />
			<Route path="PlaylistVideoPlayer" element={<PlaylistVideoPlayer />} />
			<Route path="Documentation" element={<DocumentationMain />} />
			<Route path="WorldMapUsers" element={<WorldMapUsers_Modal />} />
			<Route path="Mentorship" element={<Mentorship />} />
			<Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
			<Route path="Membership" element={<Membership />} />
		</Routes>
	);
}
