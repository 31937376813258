import React from "react"
import "./ErrorBoundary.css"

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    //We can send this to an external logging service
    console.error("Uncaught error:", error, errorInfo)
  }

  render() {
    const defaultErrorMessage = "Something went wrong."

    if (this.state.hasError) {
      return (
        <div className="error-boundary-container">
          <div>{this.props.errorMessage || defaultErrorMessage}</div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
