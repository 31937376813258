import axios from "../../axios";
export async function GetFilteredChildren({
  currentPage = 1,
  itemsPerPage = 10,
  filterByFirstName,
  filterByLastName,
  filterByIdentification,
}) {
  const filteredUrl = "Child/GetFilteredChildrenPaged";
  try {
    const response = await axios.post(filteredUrl, {
      CurrentPage: currentPage,
      ItemsPerPage: itemsPerPage,
      FirstName: filterByFirstName,
      LastName: filterByLastName,
      Identification: filterByIdentification,
    });
    return response.data;
  } catch (error) {}
}
