import { React, useContext, useEffect, useState } from "react";
import PlannerReportTable from "../../../Components/Planner/Reports/PlannerReportTable";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { Context } from "../../../Context/Contexts";
import "./PlannerReports.css";

export default function PlannerReports() {
  const { setShowFooter } = useContext(Context);
  const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);

  const [plannedDateConsult, setPlannedDateConsult] = useState(new Date());

  useEffect(() => {
    setShowFooter(false);
    setShowNavbarItems(false);
    setShowSidebarToggle(true);
  });

  return (
    <>
      <Sidebar />
      <div className="basic-page-title">Reporte de actividades</div>
      <PlannerReportTable />
    </>
  );
}
