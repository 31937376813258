import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Context } from "../../../Context/Contexts";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import "./ChildrenSelector.css";
import ChildrenSelectorCard from "./ChildrenSelectorCard";
import { MODAL_STYLES, OVERLAY_STYLES } from "./ChildrenSelector_ModalStyle";
import { GetChildren } from "../GetChildren";
import { GetChildrenAll } from "../GetChildrenAll";

export default function ChildrenSelector_Custom({
	tooltipMsg,
	iconClasssname,
	iconToShow,
	setSelectedChildren,
	multiselect = false,
	currentChildId = 0,
}) {
	const [childrenList, setChildrenList] = useState([]);
	const { showToolTip, setShowToolTip } = useContext(Context);
	const [openSelector, setOpenSelector] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [currentSelectionArr, setCurrentSelectionArr] = useState([]);

	const feedChildrenList = () => {
		GetChildrenAll().then((response) => {
			setChildrenList(response.result);
		});
	};
	const AddSelectedChild = (childId) => {
		var currselection = currentSelectionArr;
		var itemFound = false;
		currselection.filter((val) => {
			if (val == childId) {
				itemFound = true;
				var index = currselection.indexOf(childId);
				currselection.splice(index, 1);
			}
		});

		if (!itemFound) {
			if (multiselect == false) {
				currselection = [];
				setSelectedChildren(childId);
			}
			currselection.push(childId);
		}

		if (multiselect == false) {
			if (currselection.length > 0) {
				setSelectedChildren(childId);
			} else {
				setSelectedChildren(0);
			}
		} else {
			setSelectedChildren(currselection);
		}
		setCurrentSelectionArr(currselection);
		setRefresh(!refresh);

		setTimeout(() => setOpenSelector(false), 300);
	};

	const SelectedChild = (childId) => {
		AddSelectedChild(childId);
	};
	useEffect(() => {
		if (openSelector) {
			feedChildrenList();
		}
	}, [openSelector]);

	return (
		<>
			<div data-tip data-for={iconClasssname}>
				{showToolTip ? (
					<ReactTooltip id={iconClasssname}>{tooltipMsg}</ReactTooltip>
				) : (
					""
				)}

				<div>
					{" "}
					<FontAwesomeIcon
						onMouseEnter={() => setShowToolTip(true)}
						onMouseLeave={() => setShowToolTip(false)}
						onClick={() => setOpenSelector(true)}
						icon={iconToShow}
						className={iconClasssname}
					/>
				</div>
			</div>

			{openSelector ? (
				<div>
					<div
						style={OVERLAY_STYLES}
						onClick={() => setOpenSelector(false)}
					></div>
					<div
						style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
						className="children-selector-container"
					>
						<div
							className="children-selector-close-btn"
							onClick={() => setOpenSelector(false)}
						>
							X
						</div>

						{childrenList.map((child) => {
							var index = currentSelectionArr.indexOf(child.planChildId);
							if (currentChildId === child.planChildId) {
								return;
							}

							if (index == -1) {
								return ChildrenSelectorCard({
									child,
									SelectedChild,
									showAsSelected: false,
									iconToShow,
									iconClasssname,
								});
							} else {
								return ChildrenSelectorCard({
									child,
									SelectedChild,
									showAsSelected: true,
									iconToShow,
									iconClasssname,
								});
							}
						})}
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
}
