import React from "react";
import { PlannerHome } from "../../Components/Planner/PlannerHome/PlannerHome";
import "./HomePageCalendar.css";

export default function HomePageCalendar() {
  return (
    <>
      <div className="home-page-calendar-container">
        <PlannerHome />
      </div>
    </>
  );
}
