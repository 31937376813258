import React from 'react'
import './MentorshipAppointmentCard.css'
import Moment from 'moment'
import { GetPrompts } from '../../Components/Prompts/PromptsComponent'
export default function MentorshipAppointmentCard({ appointment }) {
  var currentDate = new Date()
  const confirmedStatus = 'Confirmed'


  if (new Date(appointment.fromDateTime) < new Date(currentDate)) {
    return
  }

  return (
    <>
      <div className="mentorship-appointment-card-container">
        <div className="mentorship-appointment-card-description">
          Tienes una mentoria programada para la fecha{' '}
          {Moment(appointment.fromDateTime).format('DD/MMM/YYYY HH:mm a')}
        </div>
        <div className="mentorship-appointment-card-status">
          Estado:{' '}
          {GetPrompts(
            `AppointmentStatus${appointment.mentorAppointmentStatusType.description}`
          )}
        </div>

        <div className="mentorship-appointment-card-link">
          {appointment.mentorAppointmentStatusType.description ==
          confirmedStatus ? (
            <a href={`${appointment.meetingLink}`}>Entrar a la cita ahora</a>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}
