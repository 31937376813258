import Moment from "moment";
import { GetPrompts } from "../Prompts/PromptsComponent";
export default function ChildCard({ child, setChildInEdition, setIsOpen }) {
  const firstName = child.firstName;
  const lastName = child.lastName;
  const identification = child.identification;
  const dateOfBirth = Moment(child.dateOfBirth).format("DD/MM/YYYY");

  const EditionClickHandler = () => {
    setChildInEdition(child);
    setIsOpen(true);
  };

  return (
    <article key={child.planChildId}>
      <div className="child-card">
        <div className="child-title">{`${firstName} ${lastName}`}</div>

        <div className="child-label child-id-label">
          {GetPrompts("Id Text")}:
        </div>
        <div className="child-id-content">{identification}</div>

        <div className="child-label child-dateofbirth-label">
          {GetPrompts("Date Of Birth Text")}:
        </div>
        <div className="child-dateofbirth-content">{dateOfBirth}</div>

        <div className="child-edit-btn" onClick={EditionClickHandler}>
          {GetPrompts("Edit text")}
        </div>
      </div>
    </article>
  );
}
