import { css } from "aphrodite";
import React, { useContext, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import Spinner from "react-spinkit";
import { Context } from "../../../Context/Contexts";
import { animationStyles } from "../../Animations/Styles";
import { GetStyleToAdjustHeight_Alt } from "../../Helpers/GetStyleToAdjustHeight";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import { GetHomeCalendarActivities } from "./GetHomeCalendarActivities";
import "./PlannerHome.css";
import { PlannerHome_Card } from "./PlannerHome_Card";

export function PlannerHome() {
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])

  var loadCount = 0
  const [isloading, setIsloading] = useState(false)
  const { setShowIsloading } = useContext(Context)
  const { setHomeNavBarIsOpen } = useContext(Context)
  const [weeklyScheduleDate, setWeeklyScheduleDate] = useState(new Date())
  const [plannedActivities, setPlannedActivities] = useState([])
  const [plannedDateConsult, setPlannedDateConsult] = useState(new Date())
  const { setUpdateHeight } = useContext(Context)
  const navigate = useNavigate()

  const DateChangeHandler = (e) => {
    GetActivities(e)
  }
  const GetActivities = (e) => {
    setShowIsloading(true)
    GetHomeCalendarActivities({ weeklyScheduleDate: e }).then(function (
      response
    ) {
      setPlannedActivities(response)
      setShowIsloading(false)
      setUpdateHeight(true)
    })
  }

  useEffect(() => {
    if (loadCount > 0) {
      return
    }
    InitialLoad()
  }, [loadCount])

  const InitialLoad = () => {
    GetActivities(new Date())
    loadCount = loadCount + 1
  }

  return (
    <>
      <div>
        <div
          className={
            css(isMounted ? animationStyles.bounceInDown : null) +
            " planner-home-calendar"
          }
        >
          <Calendar
            defaultView={"month"}
            onChange={(e) => DateChangeHandler(e)}
            value={plannedDateConsult}
          />
        </div>
        <div className="planner-home-middle-container">
          <div className="planner-home-middle-left base-sub-title">
            {GetPrompts("Schedule")}
          </div>
          <div
            className="planner-home-middle-right base-sub-title"
            onClick={() => {
              navigate("/WeeklyScheduleOrdered")
            }}
          >
            {GetPrompts("View plan")}{" "}
          </div>
        </div>
      </div>

      <div className="planner-home-container">
        <div className="planner-home-planned-activities">
          {plannedActivities
            ? plannedActivities.map((activity) => {
                return <PlannerHome_Card activityItem={activity} /> 
              })
            : ""}
          <div>
            {isloading ? (
              <div className="planner-home-planned-activities-embeded-spinner-container">
                <Spinner />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  )
}
