import React, { useEffect, useState } from "react";
import "./WeekdaysSelector.css";
export default function WeekdaysSelector({ setWeekdaysSelection }) {
  const [mondaySelected, setMondaySelected] = useState(false);
  const [tuesdaySelected, setTuesdaySelected] = useState(false);
  const [wednesdaySelected, setWednesdaySelected] = useState(false);
  const [thursdaySelected, setThursdaySelected] = useState(false);
  const [fridaySelected, setFridaySelected] = useState(false);
  const [saturdaySelected, setSaturdaySelected] = useState(false);
  const [sundaySelected, setSundaySelected] = useState(false);

  const FeedWeekdaysSelection = () => {
    var WeekdaysSelection = {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    };

    WeekdaysSelection.Monday = mondaySelected;
    WeekdaysSelection.Tuesday = tuesdaySelected;
    WeekdaysSelection.Wednesday = wednesdaySelected;
    WeekdaysSelection.Thursday = thursdaySelected;
    WeekdaysSelection.Friday = fridaySelected;
    WeekdaysSelection.Saturday = saturdaySelected;
    WeekdaysSelection.Sunday = sundaySelected;

    setWeekdaysSelection(WeekdaysSelection);
  };

  useEffect(() => {
    FeedWeekdaysSelection();
  }, [
    mondaySelected,
    tuesdaySelected,
    wednesdaySelected,
    thursdaySelected,
    fridaySelected,
    saturdaySelected,
    sundaySelected,
  ]);

  return (
    <>
      <div className="weekday-selector-container">
        <div
          className={
            !mondaySelected
              ? "weekday-selector-day"
              : "weekday-selector-selected"
          }
          onClick={() => {
            setMondaySelected(!mondaySelected);
          }}
        >
          Lun
        </div>

        <div
          className={
            !tuesdaySelected
              ? "weekday-selector-day"
              : "weekday-selector-selected"
          }
          onClick={() => {
            setTuesdaySelected(!tuesdaySelected);
          }}
        >
          Mar
        </div>

        <div
          className={
            !wednesdaySelected
              ? "weekday-selector-day"
              : "weekday-selector-selected"
          }
          onClick={() => {
            setWednesdaySelected(!wednesdaySelected);
          }}
        >
          Mie
        </div>

        <div
          className={
            !thursdaySelected
              ? "weekday-selector-day"
              : "weekday-selector-selected"
          }
          onClick={() => {
            setThursdaySelected(!thursdaySelected);
          }}
        >
          Jue
        </div>

        <div
          className={
            !fridaySelected
              ? "weekday-selector-day"
              : "weekday-selector-selected"
          }
          onClick={() => {
            setFridaySelected(!fridaySelected);
          }}
        >
          Vie
        </div>

        <div
          className={
            !saturdaySelected
              ? "weekday-selector-day"
              : "weekday-selector-selected"
          }
          onClick={() => {
            setSaturdaySelected(!saturdaySelected);
          }}
        >
          Sab
        </div>

        <div
          className={
            !sundaySelected
              ? "weekday-selector-day"
              : "weekday-selector-selected"
          }
          onClick={() => {
            setSundaySelected(!sundaySelected);
          }}
        >
          Dom
        </div>
      </div>
    </>
  );
}
