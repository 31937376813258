import { useContext, useState } from "react";
import { Context } from "../../Context/Contexts";
import "./WeeklySchedule.css";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";

export function CalendarPrintHeader({
	periodTitle,
	periodNumber,
	weekNumber,
	skipWeekTitle,
}) {
	const { selectedChildGlobal } = useContext(Context);
	const lang = sessionStorage.getItem("_lang");
	var skipweekTitleToShow =
		skipWeekTitle !== undefined && skipWeekTitle !== null
			? skipWeekTitle
			: "Semana de receso";

	return (
		<>
			<div className="weekly-schedule-print-header  print-only base-parafraph">
				{selectedChildGlobal !== undefined
					? `${selectedChildGlobal.firstName} ${
							selectedChildGlobal.lastName
					  }, ${selectedChildGlobal.planYear[`description${lang}`]}, 				
					  
					  ${
							weekNumber === 0
								? skipweekTitleToShow
								: `${periodTitle} ${periodNumber}, ${GetPrompts(
										"Week"
								  )} ${weekNumber}`
						}`
					: ""}
			</div>
		</>
	);
}
