import axios from "../../axios";
export const DeleteActivity = async (userId, activityId) => {
  try {
    const url = `Activity/api/v1/DeleteActivity`

    const response = await axios.post(url, {
      UserId: userId,
      ActivityId: activityId,
    });
  } catch (error) {}
};
