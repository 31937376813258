import React, { useContext, useEffect, useState } from "react"
import * as IoIcons from "react-icons/io"
import "./ChildrenSelectorPopUp.css"
import {
  MODAL_STYLES,
  OVERLAY_STYLES,
} from "./ChildrenSelectorPopUp_ModalStyles"
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle"
import { GetChildren } from "../GetChildren"
import { GetPrompts } from "../../Prompts/PromptsComponent"
import { Avatar } from "@mui/material"
import { Context } from "../../../Context/Contexts"
import { green } from "@mui/material/colors"

export default function ChildrenSelectorPopUp() {
	var loadCount = 0;

	const { showSelectAllChildrenOption, setShowSelectAllChildrenOption } =
		useContext(Context);

	const { showChildrenSelectorPopUp, setShowChildrenSelectorPopUp } =
		useContext(Context);

	const { selectedChildGlobal, setSelectedChildGlobal } = useContext(Context);

	const [childrenList, setChildrenList] = useState([]);
	const CloseClickHandler = () => {
		setShowChildrenSelectorPopUp(false);
	};

	const FeedChildrenList = () => {
		GetChildren({ currentPage: 0, itemsPerPage: 100 }).then(function (
			response
		) {
			setChildrenList(response.result);
		});
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount, showChildrenSelectorPopUp]);

	const InitialLoad = () => {
		if (showChildrenSelectorPopUp) {
			FeedChildrenList();
		}
		loadCount = loadCount + 1;
	};

	if (!showChildrenSelectorPopUp) return null;

	return (
		<>
			<div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>
			<div
				className="children-selector-pop-up-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className="children-selector-pop-up-close-btn"
					onClick={CloseClickHandler}
				>
					X
				</div>

				<div className="base-title">{GetPrompts("My Students Text")}</div>
				<div>
					<table className="children-selector-pop-up-table">
						<tr className="children-selector-pop-up-header table-row-header">
							<th className="children-selector-pop-up-header-name">
								{GetPrompts("Name text")}/{GetPrompts("Last Name text")}
							</th>
							<th>{GetPrompts("Current year")}</th>
							<th></th>
						</tr>
						{showSelectAllChildrenOption === true ? (
							<tr
								key={`children-selector-pop-up-card-container-header-key`}
								className={
									"children-selector-pop-up-card-container children-selector-pop-up-card-selection-enabled"
								}
								onClick={() => {
									setSelectedChildGlobal(undefined);
									setShowChildrenSelectorPopUp(false);
								}}
							>
								<td className="children-selector-pop-up-card-name table-row-item">
									<div className="children-selector-pop-up-card-name-avatar">
										<Avatar sx={{ bgcolor: green[200] }}>
											<span className="children-selector-pop-up-card-name-avatar-text">
												<IoIcons.IoMdPeople />
											</span>
										</Avatar>
									</div>
									<div className="children-selector-pop-up-card-name-text table-row-item">
										{GetPrompts("All students")}
									</div>
								</td>
								<td></td>
								<td className=" table-row-item ">
									{selectedChildGlobal == undefined ? (
										<div className="children-selector-pop-up-card-selected-check">
											<IoIcons.IoMdCheckmark />
										</div>
									) : (
										""
									)}
								</td>
							</tr>
						) : (
							""
						)}
						{childrenList.map((child) => {
							var selectedchildId =
								selectedChildGlobal == undefined
									? 0
									: selectedChildGlobal.planChildId;
							return (
								<>
									<tr
										className={
											"children-selector-pop-up-card-container children-selector-pop-up-card-selection-enabled"
										}
										key={`children-selector-pop-up-card-container-key${child.planChildId}`}
										onClick={() => {
											setSelectedChildGlobal(child);
											setShowChildrenSelectorPopUp(false);
										}}
									>
										<td className="children-selector-pop-up-card-name table-row-item">
											<div className="children-selector-pop-up-card-name-avatar">
												<Avatar sx={{ bgcolor: green[200] }}>
													<span className="children-selector-pop-up-card-name-avatar-text">
														{child.firstName.charAt(0)}
													</span>
												</Avatar>
											</div>
											<div className="children-selector-pop-up-card-name-text table-row-item">
												{child.firstName} {child.lastName}
											</div>
										</td>
										<td className="children-selector-pop-up-card-year-text table-row-item">
											{child.planYear.yearId}
										</td>
										<td className="table-row-item">
											{child.planChildId == selectedchildId ? (
												<div className="children-selector-pop-up-card-selected-check">
													<IoIcons.IoMdCheckmark />
												</div>
											) : (
												""
											)}
										</td>
									</tr>
								</>
							);
						})}
					</table>
				</div>
			</div>
		</>
	);
}
