import axios from "../../axios";
export async function ConfirmPayment({ paymentIntentId }) {
  const url = "Checkout/api/v1/ConfirmPayment"
  try {
    const response = await axios.post(url, {
      PaymentIntentId: paymentIntentId,
    });
    return response.data.result;
  } catch (error) {}
}
