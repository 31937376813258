import Moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DateTimePicker from "react-datetime-picker";
import Pagination from "../../Pagination/Pagination";
import { GetOverallPlannedReport } from "./GetOverallPlannedReport";
import "./PlannerReportTable.css";

export default function PlannerReportTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, SetTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [reportTableItems, setReportTableItems] = useState([]);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const FeedReportTable = () => {
    GetOverallPlannedReport({
      currentPage,
      itemsPerPage,
      fromDate,
      toDate,
    }).then(function (response) {
      setReportTableItems(response.result);
      setCurrentPage(response.currentPage);
      SetTotalPages(response.pages);
    });
  };
  const FromDateEditionHandler = (e) => {
    setFromDate(new Date(e));
  };
  const ToDateEditionHandler = (e) => {
    setToDate(new Date(e));
  };

  useEffect(() => {
    FeedReportTable();
  }, [currentPage]);

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.planerChild.firstName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Apellido",
      selector: (row) => row.planerChild.lastName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Fecha",
      selector: (row) => Moment(row.date).format("DD/MM/YYYY"),
      sortable: true,
      width: "150px",
    },
    {
      name: "FromTime",
      selector: (row) => Moment(row.fromTime).format("h:mm a"),
      sortable: true,
      width: "170px",
    },
    {
      name: "ToTime",
      selector: (row) => Moment(row.toTime).format("h:mm a"),
      sortable: true,
      width: "150px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
        borderRadius: "20px",
      },
    },
    headCells: {
      style: {
        fontSize: "18px",
        backgroundColor: "#ffc04d",
      },
    },
    cells: {
      style: {
        fontSize: "15px",
      },
    },
  };

  return (
    <>
      <div className="container report-param-container">
        <div className="report-from-date-label">Desde</div>
        <div className="report-from-date">
          <DateTimePicker
            className={"picker-report-from-date"}
            onChange={(e) => FromDateEditionHandler(e)}
            format={"dd/MM/yyyy"}
            value={fromDate}
            clearIcon={null}
            calendarIcon={null}
          />
        </div>

        <div className="picker-report-to-date-label">Hasta</div>
        <div className="report-to-date">
          <DateTimePicker
            className={"picker-report-to-date"}
            onChange={(e) => ToDateEditionHandler(e)}
            format={"dd/MM/yyyy"}
            value={toDate}
            clearIcon={null}
            calendarIcon={null}
          />
        </div>

        <div
          className="basic-edition-btn report-getreport-btn"
          onClick={FeedReportTable}
        >
          get report
        </div>
      </div>

      {reportTableItems.length > 0 ? (
        <>
          <div className="container datatable-container">
            <div className="container datatable">
              <DataTable
                columns={columns}
                data={reportTableItems}
                fixedHeader
                fixedHeaderScrollHeight="500px"
                customStyles={customStyles}
                responsive={true}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="no-results-found">No encontramos resultados</div>
        </>
      )}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
