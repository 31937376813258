import React, { useState } from "react";
import * as IoIcons from "react-icons/io";
import Basics_Planning from "../../Components/Documentation/Basics_Planning";
import Basics_SignUp from "../../Components/Documentation/Basics_SignUp";
import "./DocumentationMain.css";
import HelpItemCard from "./HelpItemCard";

export default function DocumentationMain() {
  const [showBasicSignUp, setShowBasicSignUp] = useState(false);

  return (
    <>
      <div className="container help-container">
        <div className="help-container-cards-container">
          <HelpItemCard
            icon={<IoIcons.IoMdHelpCircle />}
            cardText={"Como registrarme en la plataforma?"}
            helpComponent={<Basics_SignUp />}
          />

          <HelpItemCard
            icon={<IoIcons.IoMdHelpCircle />}
            cardText={"Conceptos basicos de planeacion"}
            helpComponent={<Basics_Planning />}
          />

          <HelpItemCard
            icon={<IoIcons.IoMdHelpCircle />}
            cardText={"Como crear una actividad?"}
            helpComponent={<Basics_SignUp />}
          />

          <HelpItemCard
            icon={<IoIcons.IoMdHelpCircle />}
            cardText={"Como adicionar un estudiante?"}
            helpComponent={<Basics_SignUp />}
          />
        </div>
      </div>
    </>
  );
}
